import { FC, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useLazyGetDisclaimerQuery } from 'api/redux/services/organizations.service';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { styled } from '@mui/system';

const DisclaimerBox = styled(Box)(() => ({
	width: 'min(100%, 1200px)',
}));

const DisclaimerPaper = styled(Paper)(() => ({
	height: '100%',
	display: 'flex',
	textAlign: 'center',
	justifyContent: 'center',
	alignItems: 'center',
	borderTopStyle: 'none',
	border: '1px solid #D0D7DE',
	borderRadius: '0px 0px 5px 5px',
}));

export const CapitalAccountStatementDisclaimer: FC = () => {
	const grants = useSelector(selectDropdown);
	const [getDisclaimer, { data: disclaimer }] = useLazyGetDisclaimerQuery();

	useEffect(() => {
		getDisclaimer(grants.grants.currentSponsor.id);
	}, [grants.grants.currentSponsor.id, getDisclaimer]);

	return (
		<DisclaimerBox>
			<DisclaimerPaper elevation={0}>
				<Typography
					variant="h6"
					style={{ color: 'black', fontStyle: 'italic', fontSize: '1rem' }}
				>
					{disclaimer?.disclaimer}
				</Typography>
			</DisclaimerPaper>
		</DisclaimerBox>
	);
};
