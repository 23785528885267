import { FC } from 'react';
import { DialogActions } from '@mui/material';
import { IActionButtonsProps } from './types';
import { BrandedButton } from 'common/components/BrandedButton';

export const ActionButtons: FC<IActionButtonsProps> = ({
	inviting,
	handleClose,
	handleSubmit,
}) => {
	return (
		<DialogActions>
			<BrandedButton onClick={handleClose} variant="outlined">
				Cancel
			</BrandedButton>
			<BrandedButton
				variant="contained"
				onClick={handleSubmit}
				disabled={inviting}
			>
				{inviting ? 'Please wait...' : 'Invite'}
			</BrandedButton>
		</DialogActions>
	);
};
