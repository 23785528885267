import { InputLabel, MenuItem, Select } from '@mui/material';
import {
	docsUploadState,
	onSetDocumentType,
} from 'api/redux/DocsUploadReducer';
import { useGetDocumentTypesQuery } from 'api/redux/services/documentTypes.service';
import { DocumentType } from 'app/types/documentTypes.types';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const DocumentTypeSelector: FC = () => {
	const { data: documentTypes = [] } = useGetDocumentTypesQuery();
	const { selectedDocType } = useSelector(docsUploadState);
	const dispatch = useDispatch();

	if (!documentTypes) return <></>;

	return (
		<>
			<InputLabel id="doc-type-select-label">Document Type</InputLabel>
			<Select
				labelId="doc-type-select-label"
				id="doc-type-select-control"
				value={selectedDocType?.id || ''}
				onChange={(e) => {
					const docType = documentTypes?.find((dt) => dt.id === e.target.value);
					if (docType) dispatch(onSetDocumentType(docType));
				}}
				sx={{
					width: '100%',
				}}
			>
				{documentTypes.map((type: DocumentType) => (
					<MenuItem key={type.id} value={type.id}>
						{type.name}
					</MenuItem>
				))}
			</Select>
		</>
	);
};
