import { useAuth0 } from '@auth0/auth0-react';
import { ROLES } from 'common/helpers/permissions/Roles';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import {
	Auth0AppMetadata,
	Auth0UserAccessors,
	IUseGrantsReturnType,
} from './types';
import { getUserRoles } from './helpers';

export const useGrants = (): IUseGrantsReturnType => {
	const { user } = useAuth0();
	const appMetadata: Auth0AppMetadata =
		user?.[`${Auth0UserAccessors.APP_METADATA}`];
	const { grants } = useSelector(selectDropdown);
	const { availableSponsors: userGrants } = grants;
	const currentRoles = getUserRoles(appMetadata);
	const isAdmin: boolean = !user
		? true
		: [ROLES.SUPER_ADMIN, ROLES.SPONSOR_USER, ROLES.SPONSOR_ADMIN].some(
				(role) => currentRoles.includes(role),
		  );
	const allowedSponsor = (sponsorId: number): boolean => {
		if (!userGrants) return false;

		return userGrants.some((grant) => grant.id === sponsorId);
	};

	return {
		user,
		allowedSponsor,
		currentRoles,
		isAdmin,
	};
};
