import { Typography } from '@mui/material';
import { Box, ToggleButton } from '@mui/material';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { FC } from 'react';
import { Check } from '@mui/icons-material';
import { styled } from '@mui/system';

const ToggleBox = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	marginLeft: '20px',
	marginTop: '5px',
});

interface INotifyUsersProps {
	checked: boolean;
	onClick: () => void;
}

export const NotifyCheckbox: FC<INotifyUsersProps> = ({ checked, onClick }) => {
	return (
		<PermissionsWrapper
			scopes={[SCOPES.canNotifyInvestorUsersOnUploadedDocuments]}
		>
			<ToggleBox>
				<ToggleButton
					value="check"
					selected={checked}
					color={'primary'}
					onChange={onClick}
				>
					{checked ? (
						<Check sx={{ height: 10, width: 10 }} />
					) : (
						<Box style={{ height: 10, width: 10 }} />
					)}
				</ToggleButton>
				<Typography style={{ margin: 10 }}>Notify Investors</Typography>
			</ToggleBox>
		</PermissionsWrapper>
	);
};
