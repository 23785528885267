import { useGetCurrentUserQuery } from 'api/redux/services/userApi';
import { User } from 'app/types/user.types';

interface IReturnType {
	currentUser: User | undefined;
	refetch: () => void;
}
export const useLocalUser = (): IReturnType => {
	const { data: currentUser, refetch } = useGetCurrentUserQuery();

	return { currentUser, refetch };
};
