import { FC, useEffect } from 'react';
import { useFilters, useGlobalFilter, useTable } from 'react-table';
import { Sort } from './SortToggle';
import { ESortSource, ICardsTableProps } from './types';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	styled,
} from '@mui/material';

const TableContainer = styled(Table)(() => ({
	borderCollapse: 'separate',
	paddingRight: '5px',
}));

const CardRow = styled(TableRow)(() => ({
	backgroundColor: '#ffffff',
	border: '1px solid #D0D7DE',
	cursor: 'pointer',
}));

const Headers = styled(TableHead)(() => ({
	backgroundColor: 'unset',
}));

const HeaderCell = styled(TableCell)(() => ({
	fontWeight: 'bold',
}));

const CardsTable: FC<ICardsTableProps> = ({
	columns,
	data,
	onRowClick = () => {},
	globalSearchTerm,
	filters,
	onSort,
	sortSource = ESortSource.DOCUMENTS,
}) => {
	const {
		getTableProps,
		headers,
		rows,
		prepareRow,
		setFilter,
		setGlobalFilter,
		setHiddenColumns,
	} = useTable(
		{
			columns,
			data,
		},
		useFilters,
		useGlobalFilter,
	);

	useEffect(() => {
		const hiddenColumns = columns.filter((f) => f.isVisible === false);
		if (hiddenColumns.length > 0) {
			const columnNames = hiddenColumns.map((col) => col.accessor) as string[];
			if (columnNames) setHiddenColumns(columnNames);
		}
	}, [columns, setHiddenColumns]);

	useEffect(() => {
		if (filters) {
			Object.entries(filters).forEach(([filterName, values]) => {
				if (columns.some((f) => f.accessor === filterName)) {
					setFilter(filterName, values);
				}
			});
		}
	}, [columns, filters, setFilter]);

	useEffect(() => {
		if (globalSearchTerm) {
			setGlobalFilter(globalSearchTerm);
		}
	}, [globalSearchTerm, setGlobalFilter]);

	return (
		<TableContainer {...getTableProps()}>
			<Headers>
				<TableRow>
					{headers.map((column, i) => {
						const hiddenColumns = columns.filter((f) => f.isVisible === false);

						if (hiddenColumns.map((c) => c.Header).includes(column.Header))
							return null;

						return (
							<HeaderCell
								{...column.getHeaderProps()}
								width={column.width}
								style={{ minWidth: column.minWidth }}
								key={i}
							>
								{column.render('Header')}
								<Sort column={column} onSort={onSort} sortSource={sortSource} />
							</HeaderCell>
						);
					})}
				</TableRow>
			</Headers>
			<TableBody>
				{rows.map((row, j) => {
					prepareRow(row);
					return (
						<CardRow
							{...row.getRowProps()}
							hover
							key={j}
							onClick={() => onRowClick(row)}
						>
							{row.cells.map((cell, k) => (
								<TableCell {...cell.getCellProps()} key={k}>
									{cell.render('Cell')}
								</TableCell>
							))}
						</CardRow>
					);
				})}
			</TableBody>
		</TableContainer>
	);
};

export default CardsTable;
