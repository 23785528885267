import { Box, Grid } from '@mui/material';
import { FC } from 'react';
import { IconBtn } from '../IconBtn';
import { hasPermission } from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { Delete, Edit, VisibilityOutlined } from '@mui/icons-material';

interface IEmailTemplateActionsProps {
	onEdit: () => void;
	onDelete: () => void;
	onPreview: () => void;
	isReadOnly: boolean;
}

interface IEditEmailTemplateActionProps {
	onEdit: () => void;
}

interface IEditIconProps {
	canEdit: boolean;
}

interface IDeleteEmailTemplateActionProps {
	onDelete: () => void;
}

interface IPreviewEmailTemplateModalProps {
	onPreview: () => void;
}

const EditIcon: FC<IEditIconProps> = ({ canEdit }) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	if (canEdit) return <Edit sx={{ color: `${sponsorColor}` }} />;

	return <VisibilityOutlined sx={{ color: `${sponsorColor}` }} />;
};

const EditEmailTemplateAction: FC<IEditEmailTemplateActionProps> = ({
	onEdit,
}) => {
	const canEditEmailTemplates = hasPermission({
		scopes: [SCOPES.canEditEmailTemplates],
	});

	if (!canEditEmailTemplates) return <></>;

	return (
		<Box style={{ display: 'flex', justifyContent: 'center' }}>
			<IconBtn onClick={onEdit} tooltip={'Edit Template'}>
				<EditIcon canEdit={canEditEmailTemplates} />
			</IconBtn>
		</Box>
	);
};

const DeleteEmailTemplateAction: FC<IDeleteEmailTemplateActionProps> = ({
	onDelete,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	if (
		!hasPermission({
			scopes: [SCOPES.canDeleteEmailTemplates],
		})
	)
		return <></>;

	return (
		<Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
			<IconBtn onClick={onDelete} tooltip={`Delete Template`}>
				<Delete sx={{ color: `${sponsorColor}` }} />
			</IconBtn>
		</Box>
	);
};

const PreviewEmailTemplateAction: FC<IPreviewEmailTemplateModalProps> = ({
	onPreview,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	return (
		<Box style={{ display: 'flex', justifyContent: 'center' }}>
			<IconBtn onClick={onPreview} tooltip={'Preview Template'}>
				<VisibilityOutlined sx={{ color: `${sponsorColor}` }} />
			</IconBtn>
		</Box>
	);
};

export const EmailTemplateActions: FC<IEmailTemplateActionsProps> = ({
	onEdit,
	onDelete,
	onPreview,
	isReadOnly,
}) => {
	return (
		<Grid container direction="row" spacing={2} sx={{ maxWidth: '150px' }}>
			<Grid item xs={4} md={4}>
				{isReadOnly ? <></> : <EditEmailTemplateAction onEdit={onEdit} />}
			</Grid>
			<Grid item xs={4} md={4}>
				{isReadOnly ? <></> : <DeleteEmailTemplateAction onDelete={onDelete} />}
			</Grid>
			<Grid item xs={4} md={4}>
				<PreviewEmailTemplateAction onPreview={onPreview} />
			</Grid>
		</Grid>
	);
};
