import { FC } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';

interface ISelectAllOptionProps {
	availableIds: number[] | string[];
	selectedIds: number[] | string[];
	setSelectedIds: any;
	onChange?: () => void;
}

export const SelectAllOption: FC<ISelectAllOptionProps> = ({
	selectedIds,
	setSelectedIds,
	availableIds,
	onChange,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const handleChange = () => {
		if (onChange) onChange();
		if (typeof selectedIds[0] === 'string') {
			if (
				(availableIds as Array<string>).every((ar: string) =>
					(selectedIds as Array<string>).includes(ar),
				)
			) {
				setSelectedIds([]);
				return;
			}
			setSelectedIds(availableIds);
		}

		if (
			(availableIds as Array<number>).every((ar: number) =>
				(selectedIds as Array<number>).includes(ar),
			)
		) {
			setSelectedIds([]);
			return;
		}
		setSelectedIds(availableIds);
	};

	const isChecked = (): boolean => {
		if (typeof selectedIds[0] === 'string') {
			return (availableIds as Array<string>).every((ar: string) =>
				(selectedIds as Array<string>).includes(ar),
			);
		}

		return (availableIds as Array<number>).every((ar: number) =>
			(selectedIds as Array<number>).includes(ar),
		);
	};

	return (
		<FormControlLabel
			style={{ width: 250 }}
			control={
				<Checkbox
					size="small"
					onChange={handleChange}
					checked={isChecked()}
					style={{
						color: sponsorColor,
						padding: 3,
						marginLeft: 5,
					}}
				/>
			}
			label={
				<Typography
					style={{
						fontSize: 14,
						color: 'black',
					}}
				>
					Select All
				</Typography>
			}
		></FormControlLabel>
	);
};
