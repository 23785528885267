import { FC, useState } from 'react';
import { Dialog, Paper, Typography, Box, TextField } from '@mui/material';
import { Announcement } from 'app/types/Announcements.types';
import { styled } from '@mui/system';
import { BrandedButton } from 'common/components/BrandedButton';

const TextContainer = styled(Box)({
	paddingBottom: '10px',
});

const ButtonContainer = styled(Box)({
	paddingTop: '10px',
	display: 'flex',
	justifyContent: 'space-evenly',
});

const DialogContainer = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	padding: theme.spacing(2, 4, 3),
}));

export interface IEditAnnouncementModal {
	open: boolean;
	announcement: Announcement;
	handleEditModalSave: (announcement: string) => void;
	handleEditModalClose: () => void;
}

export const EditAnnouncementModal: FC<IEditAnnouncementModal> = ({
	open,
	announcement,
	handleEditModalSave,
	handleEditModalClose,
}) => {
	const [announcementText, setAnnouncementText] = useState<string>(
		announcement.text,
	);

	return (
		<Dialog open={open} onClose={handleEditModalClose}>
			<DialogContainer>
				<TextContainer>
					<Typography variant="h5">Edit Announcement</Typography>
				</TextContainer>
				<Paper>
					<TextField
						multiline
						fullWidth
						variant="outlined"
						value={announcementText}
						onChange={(e) => setAnnouncementText(e.target.value)}
					/>
				</Paper>
				<ButtonContainer>
					<BrandedButton
						variant="contained"
						onClick={() => handleEditModalSave(announcementText)}
					>
						<Typography>Save</Typography>
					</BrandedButton>
					<BrandedButton variant="outlined" onClick={handleEditModalClose}>
						<Typography>Cancel</Typography>
					</BrandedButton>
				</ButtonContainer>
			</DialogContainer>
		</Dialog>
	);
};

export default EditAnnouncementModal;
