import { Button } from '@mui/material';
import {
	defaultColor,
	selectSponsorPrimaryColor,
} from 'api/redux/siteColorReducer';
import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

interface IBrandedButtonProps {
	children: ReactElement | string;
	onClick?: any;
	color?: string;
	variant?: 'text' | 'outlined' | 'contained' | undefined;
	disabled?: boolean;
	width?: string;
	className?: any;
	fontSize?: string;
	type?: 'button' | 'submit' | 'reset' | undefined;
}

export const BrandedButton: FC<IBrandedButtonProps> = (
	props: IBrandedButtonProps,
) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const color =
		props.variant && props.variant === 'contained'
			? 'white'
			: props.color || sponsorColor || defaultColor;
	const backgroundColor =
		props.variant && props.variant === 'contained'
			? props.color || sponsorColor || defaultColor
			: 'white';

	return (
		<Button
			onClick={props.onClick}
			className={props.className}
			variant={props.variant || 'text'}
			style={{
				color: color,
				backgroundColor: backgroundColor,
				width: props.width,
				fontSize: props.fontSize || '0.875rem',
				opacity: props.disabled ? 0.5 : 1,
			}}
			disabled={props.disabled || false}
			type={props.type}
		>
			{props.children}
		</Button>
	);
};
