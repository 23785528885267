import { FC } from 'react';
import { IUsersTableListProps, IUsersTableProps } from './types';
import { UsersTableLoader } from './UsersTableLoader';
import { UsersTableList } from './users-table-list/UsersTableList';

export const UsersTable: FC<IUsersTableProps> = ({
	refetchUsers,
	setPage,
	pages,
	loading,
	searchString,
	setSearchString,
}) => {
	return (
		<UsersTableLoader
			refetchUsers={refetchUsers}
			setPage={setPage}
			pages={pages}
			loading={loading}
			searchString={searchString}
			setSearchString={setSearchString}
		>
			{(injectedProps: IUsersTableListProps) => (
				<UsersTableList {...injectedProps} />
			)}
		</UsersTableLoader>
	);
};
