import React, { useState } from 'react';
import { IEditableUserRowProps } from '../types';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	TextField,
	Tooltip,
} from '@mui/material';
import { Engineering, Loop, Mail, Person } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useUpdateUserMutation } from 'api/redux/services/userApi';
import { isEmail } from 'common/helpers/Validator';
import CloseIcon from '@mui/icons-material/Close';
import { useUpdateUserRoleMutation } from 'api/redux/services/auth.service';
import { ROLES } from 'common/helpers/permissions/Roles';
import { ConfirmationModal } from '../../ConfirmationModal';
import { PopUpAlert } from 'common/components/PopUpAlert';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { BrandedButton } from 'common/components/BrandedButton';
import { InputWrapper } from '../styles';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';

export const EditableUserRow: React.FC<IEditableUserRowProps> = ({
	user,
	onCancelClick,
	handleReload,
	afterSave,
	onClose,
	selectedInvestorId,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const { currentSponsor } = useSelector(selectDropdown).grants;
	const { currentRoles } = useGrants();
	const [name, setName] = useState<string>(user.name);
	const [email, setEmail] = useState<string>(user.email);
	const [userRole, setUserRole] = useState<string>(user.role);
	const [emailError, setEmailError] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);
	const [updateUser] = useUpdateUserMutation();
	const [changeUserRole] = useUpdateUserRoleMutation();
	const availableRoles = {
		[ROLES.INVESTOR_ADMIN]: ROLES.INVESTOR_USER,
		[ROLES.INVESTOR_USER]: ROLES.INVESTOR_ADMIN,
	};
	const roleToAssign: ROLES = availableRoles[user.role as ROLES];
	const [needTocancel, setNeedToCancel] = useState<boolean>(false);
	const [confirmationMessage, setConfirmationMessage] = useState<string | null>(
		null,
	);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [nameTouched, setNameTouched] = useState<boolean>(false);
	const [emailTouched, setEmailTouched] = useState<boolean>(false);
	const [changeRoleTouched, setChangeRoleTouched] = useState<boolean>(false);
	const previousUser = user;

	const validateEmail = (e): void => {
		const { value: emailValue } = e.target;
		if (emailValue === '') {
			setEmailError(false);
			return;
		}
		setEmailError(!isEmail(e.target.value));
	};

	const handleSave = async () => {
		const investorId = selectedInvestorId;
		setSaving(true);
		if (
			(currentRoles.includes(ROLES.SUPER_ADMIN) ||
				currentRoles.includes(ROLES.SPONSOR_ADMIN) ||
				currentRoles.includes(ROLES.INVESTOR_ADMIN)) &&
			changeRoleTouched
		) {
			await changeUserRole({
				userId: user.id,
				roleName: roleToAssign,
				sponsorId: currentSponsor.id,
				investorId: investorId,
			});
		}
		await updateUser({
			userId: user.id,
			name,
			email,
			investorId,
		})
			.unwrap()
			.finally(() => {
				setSaving(false);
				handleReload();
				afterSave();
				onClose(false);
			});
		onClose(false);
	};

	const changeUserRoleBoolean = () => {
		setUserRole(roleToAssign);
		setChangeRoleTouched(!changeRoleTouched);
	};

	const onCancel = () => {
		if (checkTextFiledTouched()) {
			setNeedToCancel(true);
			setConfirmationMessage(
				'Changes will not be saved, are you sure you want to cancel?',
			);
			return;
		} else {
			onCancelClick();
		}
	};

	const checkTextFiledTouched = () => {
		return (
			(nameTouched && previousUser.name !== name) ||
			(emailTouched && previousUser.email !== email) ||
			changeRoleTouched
		);
	};

	onClose(checkTextFiledTouched());

	return (
		<>
			<InputWrapper elevation={0}>
				<Grid container direction="row">
					<Grid item xs={10}>
						<List dense={true}>
							<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
								<ListItemIcon>
									<Tooltip title={'User Name'}>
										<Person sx={{ color: sponsorColor }} />
									</Tooltip>
								</ListItemIcon>
								<TextField
									id="edit-user-name-control"
									label=""
									placeholder="User name"
									multiline
									autoFocus
									variant="standard"
									value={name || ''}
									onChange={(e) => {
										setName(e.target.value);
										setNameTouched(true);
									}}
									sx={{ resize: 'both', width: '100%' }}
								/>
							</ListItem>
							<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
								<ListItemIcon>
									<Tooltip title={'User Email'}>
										<Mail sx={{ color: sponsorColor }} />
									</Tooltip>
								</ListItemIcon>
								<TextField
									id="edit-user-email-control"
									label=""
									placeholder="User email"
									multiline
									error={emailError}
									helperText={emailError ? 'Incorrect email format' : ''}
									autoFocus
									variant="standard"
									onBlur={validateEmail}
									value={email || ''}
									onChange={(e) => {
										setEmail(e.target.value);
										setEmailTouched(true);
									}}
									sx={{ resize: 'both', width: '100%' }}
								/>
							</ListItem>
							<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
								<ListItemIcon>
									<Tooltip title={'Current User Role'}>
										<Engineering sx={{ color: sponsorColor }} />
									</Tooltip>
								</ListItemIcon>
								<ListItemText
									primary={userRole}
									sx={{ flex: '0.2 1 auto', wordWrap: 'break-word' }}
								/>
								{saving ? (
									<CircularProgress />
								) : (
									!userRole.includes('Sponsor') && (
										<ListItemIcon onClick={() => changeUserRoleBoolean()}>
											<Tooltip title={'Change User Role'}>
												<Loop sx={{ color: sponsorColor, cursor: 'pointer' }} />
											</Tooltip>
										</ListItemIcon>
									)
								)}
							</ListItem>
						</List>
					</Grid>
					<Grid item xs={2}>
						<Box
							display={'flex'}
							flexDirection={'row'}
							justifyContent={'flex-end'}
						>
							<Button
								variant="text"
								onClick={onCancel}
								sx={{
									color: `${sponsorColor}`,
								}}
							>
								<CloseIcon />
							</Button>
						</Box>
						<Box
							display="flex"
							justifyContent="flex-end"
							flexDirection={'column'}
							sx={{ padding: '1rem' }}
							position={'relative'}
							top={'35px'}
						>
							<BrandedButton
								disabled={saving}
								onClick={handleSave}
								variant="contained"
							>
								{saving ? <CircularProgress size={20} /> : 'Save'}
							</BrandedButton>
						</Box>
					</Grid>
				</Grid>
			</InputWrapper>
			<ConfirmationModal
				isOpen={needTocancel}
				onAccept={() => handleSave()}
				onCancel={() => setNeedToCancel(false)}
				cancelText="Cancel"
				saveText={`Save information`}
				title={``}
				message={confirmationMessage || ''}
			/>
			<PopUpAlert
				show={errorMessage !== null}
				onClose={() => setErrorMessage(null)}
				severity={'error'}
				message={errorMessage || ''}
			/>
		</>
	);
};
