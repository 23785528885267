export enum ROLES {
	INVESTOR_ADMIN = 'Investor Admin',
	INVESTOR_USER = 'Investor User',
	SPONSOR_ADMIN = 'Sponsor Admin',
	SPONSOR_USER = 'Sponsor User',
	SUPER_ADMIN = 'Super Admin',
}

export enum RoleLevels {
	SPONSOR = 'Sponsor',
	INVESTOR = 'Investor',
}
