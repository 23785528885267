import { FormControl, Select } from '@mui/material';
import { styled } from '@mui/system';

export const DropdownFormControl = styled(FormControl)(() => ({
	'& .Mui-disabled': {
		color: 'white !important',
		WebkitTextFillColor: 'white !important',
	},
	width: '200px',
	margin: 5,
}));

export const SelectControl = styled(Select)(() => ({
	textAlign: 'left',
	color: '#F1F2F9 !important',
	'& .MuiOutlinedInput-notchedOutline': {
		border: '1px solid #FFFFFF !important',
	},
	'& .MuiSelect-icon': {
		color: '#FFFFFF !important',
	},
	'&:hover': {
		fontWeight: 'bold',
	},
}));
