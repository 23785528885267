import { useAuth0 } from '@auth0/auth0-react';
import { Alert } from '@mui/material';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';
import { Navigate } from 'react-router-dom';

interface IProtectedRouteParams {
	children: JSX.Element;
	roles: string[];
	errorMessage?: string;
}

const ProtectedRoute = ({
	children,
	roles,
	errorMessage,
}: IProtectedRouteParams) => {
	const { isAuthenticated } = useAuth0();
	const { currentRoles: userRoles } = useGrants();

	if (!isAuthenticated) {
		return <Navigate to="/" />;
	}

	if (userRoles.some((role) => roles.includes(role))) {
		return children;
	} else if (errorMessage) {
		return (
			<MainSidebarContainer>
				<Alert severity="error" variant="filled">
					{errorMessage}
				</Alert>
			</MainSidebarContainer>
		);
	} else {
		return <Navigate to="/" />;
	}
};

export default ProtectedRoute;
