import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { FC } from 'react';
import { IColumnsListProps } from './types';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { OptionsColumnBox } from './styles';
import { SelectAllOption } from 'common/components/SelectAllOption';

export const ColumnsList: FC<IColumnsListProps> = ({
	selectedColumns,
	onColumnsChange,
	availableColumns,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	return (
		<OptionsColumnBox>
			<FormGroup>
				<SelectAllOption
					selectedIds={selectedColumns}
					setSelectedIds={onColumnsChange}
					availableIds={availableColumns}
				/>
				{availableColumns.map((column, i) => {
					return (
						<FormControlLabel
							key={i}
							style={{ fontSize: '12px' }}
							control={
								<Checkbox
									size="small"
									onChange={() => {
										if (selectedColumns.includes(column)) {
											onColumnsChange(
												selectedColumns.filter((r) => r !== column),
											);
											return;
										}

										onColumnsChange([...selectedColumns, column]);
									}}
									checked={selectedColumns.includes(column) || false}
									style={{
										color: sponsorColor,
										padding: 3,
										marginLeft: 5,
									}}
								/>
							}
							label={<Typography>{column}</Typography>}
						></FormControlLabel>
					);
				})}
			</FormGroup>
		</OptionsColumnBox>
	);
};
