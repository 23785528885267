import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { DocsState, IAscDesc, ISortStrategy } from './types';
import { IDocumentResponse } from './services/documentApi';
import { DocumentType } from 'app/types/documentTypes.types';

const initialSortStrategy: ISortStrategy = {
	column: 'createdAt',
	ascdesc: IAscDesc.DESC,
};

const initialState: DocsState = {
	allDocs: [],
	availableDocTypes: [],
	selectedDocTypeIds: [],
	userAllowedDocTypes: [],
	sortStrategy: [initialSortStrategy],
};

export const docsSlice: Slice = createSlice({
	name: 'documentsState',
	initialState,
	reducers: {
		onSetAvailableDocTypes: (state, action: PayloadAction<DocumentType[]>) => {
			state.availableDocTypes = action.payload;
		},
		onSetAllDocs: (state, action: PayloadAction<IDocumentResponse[]>) => {
			state.allDocs = action.payload;
		},
		onSetSelectedDocTypeIds: (state, action: PayloadAction<number[]>) => {
			state.selectedDocTypeIds = action.payload;
		},
		onSetUserAllowedDocTypes: (
			state,
			action: PayloadAction<DocumentType[]>,
		) => {
			state.userAllowedDocTypes = action.payload;
		},
		onSetSortStrategy: (state, action: PayloadAction<ISortStrategy>) => {
			state.sortStrategy = [action.payload];
		},
	},
});
export const {
	onSetAvailableDocTypes,
	onSetAllDocs,
	onSetSelectedDocTypeIds,
	onSetUserAllowedDocTypes,
	onSetSortStrategy,
} = docsSlice.actions;

export const documentsState = ({ docs }: RootState): DocsState => docs;

export default docsSlice.reducer;
