import React from 'react';
import { Box, Container, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ILinkItem {
	text: string;
	link?: string;
}
interface IAdminBreadcrumbs {
	list: ILinkItem[];
}

const RootContainer = styled(Container)(() => ({
	margin: 0,
	display: 'flex',
	flexDirection: 'row',
}));

const Separator = styled('span')(() => ({
	marginLeft: 10,
	marginRight: 20,
	fontSize: '22px',
}));

const LinkText = styled('span')(() => ({
	textDecoration: 'underline',
	cursor: 'pointer',
	position: 'relative',
	fontSize: '22px',
}));

const LastText = styled('span')(() => ({
	cursor: 'pointer',
	position: 'relative',
	fontSize: '22px',
}));

const AdminBreadcrumbs: React.FC<IAdminBreadcrumbs> = ({ list }) => {
	const navigate = useNavigate();
	return (
		<RootContainer>
			{list.map((item, i) => {
				const isLast = i + 1 === list.length;
				const TextComponent = isLast ? LastText : LinkText;

				return (
					<Box key={i} onClick={() => item.link && navigate(item.link)}>
						<TextComponent>{item.text}</TextComponent>
						{!isLast && <Separator>/</Separator>}
					</Box>
				);
			})}
		</RootContainer>
	);
};

export default AdminBreadcrumbs;
