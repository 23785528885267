import { Typography, TextField, Box } from '@mui/material';
import { useCreateAnnouncementMutation } from 'api/redux/services/announcementsApi';
import { Paper } from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { BrandedButton } from 'common/components/BrandedButton';

const Wrapper = styled(Box)(() => ({
	width: '75%',
	marginLeft: 'auto',
	marginRight: 'auto',
	marginTop: 15,
	marginBottom: 15,
	padding: 15,
	justifyContent: 'center',
	textAlign: 'center',
}));

export const CreateAnnouncement: React.FC = () => {
	const { grants } = useSelector(selectDropdown);
	const [createAnnouncement] = useCreateAnnouncementMutation();
	const { currentSponsor } = grants;
	const [text, setText] = useState<string>('');

	const handleSubmit = async () => {
		await createAnnouncement({ text, sponsorId: currentSponsor.id });
		setText('');
	};

	return (
		<Paper>
			<Wrapper>
				<Typography variant={'h3'}>Announcements</Typography>
				<TextField
					multiline
					fullWidth
					variant="outlined"
					value={text}
					onChange={(e) => setText(e.target.value)}
				/>
				<BrandedButton variant="contained" onClick={handleSubmit}>
					Post
				</BrandedButton>
			</Wrapper>
		</Paper>
	);
};

export default CreateAnnouncement;
