import React from 'react';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const Root = styled('div')({
	position: 'absolute',
	width: '100%',
	height: '100%',
	backgroundColor: '#FFFFFF',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	zIndex: 5,
});

const Spinner = styled(CircularProgress)({
	color: '#0092D8',
});

export const LoadingSpinner: React.FC = () => {
	return (
		<Root>
			<Spinner />
		</Root>
	);
};

export default LoadingSpinner;
