import {
	FetchArgs,
	FetchBaseQueryError,
	fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/query';
import { RootState } from '../api/redux/store';
import { configApi } from '../api/redux/services/config';

const rawBaseQuery = fetchBaseQuery({
	baseUrl: '',
	prepareHeaders: (headers: Headers) => {
		const token = sessionStorage.getItem('token');
		if (token) headers.set('Authorization', `Bearer ${token}`);
		return headers;
	},
});

const dynamicBaseQuery =
	(
		stem: string,
	): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
	async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
		const state: RootState = api.getState();
		const { data: config } = configApi.endpoints.getConfig.select()(state);

		const urlEnd = typeof args === 'string' ? args : args.url;
		const adjustedUrl = `${config?.apiBaseUrl}/${stem}/${urlEnd}`;

		const adjustedArgs =
			typeof args === 'string'
				? { url: adjustedUrl }
				: { ...args, url: adjustedUrl };

		return rawBaseQuery(adjustedArgs, api, extraOptions);
	};

export default dynamicBaseQuery;
