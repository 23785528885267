/**
 * Just set cookie:
 * setCookie('name', 'value');
 * Set cookie for 1 hour:
 * date = new Date();
 * date.setHours(date.getHours() + 1);
 * setCookie('name', 'value', date.toUTCString());
 */
export const setCookie = (
	cname: string,
	cvalue: string,
	exdays: number,
): void => {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	const expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

/**
 * Example:
 * getCookie('name');
 */
export const getCookie = (cname: string): string => {
	const name = cname + '=';
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
};

/**
 * Example:
 * delCookie('name');
 */
export const delCookie = (name: string): void => {
	document.cookie = name + '=' + '; expires=Thu, 01 Jan 1970 00:00:01 GMT';
};
