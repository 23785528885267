import {
	IFundGrant,
	IInvestorGrant,
	ISponsorGrant,
	IUserWithInvestors,
} from './services/userApi';
import { DocumentType } from 'app/types/documentTypes.types';
import { IDocumentResponse } from './services/documentApi';
import { ROLES } from 'common/helpers/permissions/Roles';

export const emptyInvestor: IInvestorGrant = {
	id: 0,
	name: '',
	role: ROLES.INVESTOR_USER,
};

export const emptySponsor: ISponsorGrant = {
	id: 0,
	name: '',
	funds: [],
	role: ROLES.SPONSOR_USER,
};

export const emptyFund: IFundGrant = {
	id: 0,
	name: '',
	investors: [],
};

export interface GrantState {
	grants: {
		currentSponsor: ISponsorGrant;
		currentFund: IFundGrant;
		currentInvestor: IInvestorGrant;
		availableSponsors: ISponsorGrant[];
		availableFunds: IFundGrant[];
		availableInvestors: IInvestorGrant[];
		allAvailableInvestors: IInvestorGrant[];
	};
	showAllUsers: boolean;
}

export interface IObjectWithName {
	name: string;
}

export interface DocsState {
	allDocs: IDocumentResponse[];
	availableDocTypes: DocumentType[];
	userAllowedDocTypes: DocumentType[];
	selectedDocTypeIds: number[];
	sortStrategy: ISortStrategy[];
}

export interface UsersState {
	users: IUserWithInvestors[];
	sortStrategy: ISortStrategy[];
}

export interface ISimpleGrant {
	sponsor?: ISponsorGrant;
	fund?: IFundGrant;
	investor?: IInvestorGrant;
}

export enum IAscDesc {
	ASC = 'ASC',
	DESC = 'DESC',
}

export interface ISortStrategy {
	column: string;
	ascdesc: IAscDesc;
}
