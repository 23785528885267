import { FormControlLabel, FormGroup, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const InputWrapper = styled(Paper)(() => ({
	flex: '1 0 auto',
	height: '20rem',
	overflow: 'auto',
	padding: 5,
	margin: 10,
	border: '1px solid #e3e3e3',
}));

export const LabelBox = styled(Typography)(() => ({
	fontSize: 14,
	color: 'black',
}));

export const LabelWrapper = styled(FormControlLabel)(() => ({
	width: 250,
}));

export const FormWrapper = styled(FormGroup)(() => ({
	height: '100%',
}));
