import { Checkbox } from '@mui/material';
import {
	IPermissionsMutationResponse,
	useSaveInvestorUserOtherPermissionsMutation,
} from 'api/redux/services/investors.service';
import { UserInvestor } from 'app/types/user-investor.types';
import { hasPermission } from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { Dispatch, SetStateAction, useState } from 'react';
import { ConfirmationModal } from './ConfirmationModal';
import { SaveTabButton } from './SaveTabButton';
import { FormWrapper, InputWrapper, LabelBox, LabelWrapper } from './styles';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';

interface IOthersTabProps {
	userId: number;
	investorId: number;
	userInvestorPermissions: UserInvestor;
	setPermissions: Dispatch<SetStateAction<UserInvestor | undefined>>;
	changesMade: boolean;
	setChangesMade: Dispatch<SetStateAction<boolean>>;
	showConfirmation: boolean;
	handleClose: () => void;
	handleAfterSave: () => void;
}

export const OthersTab: React.FC<IOthersTabProps> = ({
	investorId,
	userId,
	userInvestorPermissions,
	setPermissions,
	changesMade,
	setChangesMade,
	showConfirmation,
	handleClose,
	handleAfterSave,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const [canDownloadState, setCanDownloadState] = useState<boolean>(
		userInvestorPermissions.canDownload,
	);
	const [canPrintState, setCanPrintState] = useState<boolean>(
		userInvestorPermissions.canPrint,
	);
	const [saveUserInvestorOtherPermissions, response] =
		useSaveInvestorUserOtherPermissionsMutation();

	const handleSave = async () => {
		const newPermissions: IPermissionsMutationResponse =
			await saveUserInvestorOtherPermissions({
				userId,
				investorId,
				canDownload: canDownloadState,
				canPrint: canPrintState,
			});

		if ('data' in newPermissions) {
			setPermissions(newPermissions.data);
		}

		handleAfterSave();
	};

	const canEditInvestorPermissions = hasPermission({
		scopes: [SCOPES.canEditInvestorPermissions],
	});

	return (
		<>
			<InputWrapper>
				<FormWrapper>
					<LabelWrapper
						style={{ fontSize: '12px' }}
						control={
							<Checkbox
								size="small"
								disabled={!canEditInvestorPermissions}
								onChange={() => {
									setChangesMade(true);
									setCanDownloadState(!canDownloadState);
								}}
								checked={canDownloadState}
								style={{
									color: sponsorColor,
								}}
							/>
						}
						label={<LabelBox>Download Documents</LabelBox>}
					></LabelWrapper>

					<LabelWrapper
						style={{ fontSize: '12px' }}
						control={
							<Checkbox
								size="small"
								disabled={!canEditInvestorPermissions}
								onChange={() => {
									setChangesMade(true);
									setCanPrintState(!canPrintState);
								}}
								checked={canPrintState}
								style={{
									color: sponsorColor,
								}}
							/>
						}
						label={<LabelBox>Print Documents</LabelBox>}
					></LabelWrapper>
				</FormWrapper>

				<ConfirmationModal
					isOpen={showConfirmation}
					onAccept={handleSave}
					onCancel={handleClose}
					cancelText="Discard"
				/>
			</InputWrapper>

			<SaveTabButton
				changesMade={changesMade}
				handleSave={handleSave}
				saving={response.isLoading}
			/>
		</>
	);
};
