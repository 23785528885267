import { FC, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button } from '@mui/material';
import { useCookies } from 'react-cookie';
import { HomeLoader } from './HomeLoader';
import { THomeProps, URLS_TO_SAVE } from './types';
import { ISponsor } from 'app/types/sponsor.types';
import { defaultColor } from 'api/redux/siteColorReducer';
import { styled } from '@mui/system';

const LoginBox = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: '60vh',
}));

const LoginNotMobile = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100vw',
	height: '60vh',
}));

const SponsorLogo = styled('img')(() => ({
	maxWidth: '250px',
	maxHeight: '250px',
	height: 'auto',
	paddingBottom: '1rem',
}));

export const Home: FC = () => {
	const buildBrandedUrl = (
		org: ISponsor | undefined,
		subdomain: string,
		darkLogoUrl: string,
		baseUrl: string,
	) => {
		const url = new URL(baseUrl);

		const redirectUrl = getRedirectUrl();
		if (redirectUrl) {
			url.searchParams.append('redirectUrl', redirectUrl);
		}

		if (!org) return url.toString();

		url.searchParams.append('orgName', org.name);
		url.searchParams.append('logo', darkLogoUrl || '');
		url.searchParams.append('auth0OrgId', org.auth0Id || '');
		url.searchParams.append('orgId', org.id.toString() || '');
		url.searchParams.append('sub', subdomain);
		url.searchParams.append('color', org.headerColor || defaultColor);
		return url.toString();
	};

	const getRedirectUrl = () => {
		let redirectUrl = '';
		const { pathname, search } = window.location;

		if (URLS_TO_SAVE.some((url) => pathname.includes(url))) {
			redirectUrl = `${pathname}${search}`;
		}
		return redirectUrl;
	};

	return (
		<HomeLoader>
			{(injectedProps: THomeProps) => (
				<>
					{injectedProps.isShowLoading ? (
						<>Loading...</>
					) : injectedProps.isRedirectToBrandedUrl &&
					  (window.location.href = buildBrandedUrl(
							injectedProps.org,
							injectedProps.subdomain,
							injectedProps.logoUrl,
							injectedProps.baseUrl,
					  )) ? (
						<></>
					) : (
						<Login
							orgName={injectedProps.orgName}
							logo={injectedProps.logo}
							subdomain={injectedProps.subdomainParam}
							redirectUrl={injectedProps.redirectUrl}
							color={injectedProps.color}
						/>
					)}
				</>
			)}
		</HomeLoader>
	);
};

interface ILoginProps {
	logo?: string;
	orgName?: string;
	subdomain?: string;
	redirectUrl?: string;
	color?: string;
}

export const Login: FC<ILoginProps> = (props) => {
	const clearUrlParams = () => {
		const url = new URL(window.location.href);
		url.searchParams.delete('orgName');
		url.searchParams.delete('logo');
		url.searchParams.delete('auth0OrgId');
		url.searchParams.delete('orgId');
		url.searchParams.delete('sub');
		url.searchParams.delete('subdomain');
		url.searchParams.delete('redirectUrl');
		url.searchParams.delete('color');
		return url.toString();
	};

	history.replaceState(null, '', clearUrlParams());
	const { loginWithRedirect } = useAuth0();
	const [logo] = useState<string | undefined>(props.logo || undefined);
	const [color] = useState<string>(props.color || defaultColor);
	const [, setCookie] = useCookies(['subdomain']);

	const LoginButton = styled(Button)(() => ({
		backgroundColor: `${color}`,
		color: '#FFFFFF',
		textTransform: 'none',
		fontSize: '1.0rem',
		width: 135,
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: `${color} !important`,
			boxShadow: 'none',
		},
		'&:focus': {
			backgroundColor: `${color} !important`,
		},
	}));

	if (props.subdomain) {
		setCookie('subdomain', props.subdomain, { path: '/' });
	}

	const saveURL = () => {
		const { pathname, search } = window.location;

		if (URLS_TO_SAVE.some((url) => pathname.includes(url))) {
			sessionStorage.setItem('redirectUrl', `${pathname}${search}`);
		}
	};

	const handleLogin = () => {
		saveURL();
		if (props.redirectUrl) {
			sessionStorage.setItem('redirectUrl', props.redirectUrl);
		}
		loginWithRedirect();
	};

	return (
		<LoginBox>
			<LoginNotMobile>
				{logo && <SponsorLogo src={logo} />}
				<LoginButton
					variant="contained"
					size="large"
					disableRipple
					onClick={handleLogin}
				>
					Log In
				</LoginButton>
			</LoginNotMobile>
		</LoginBox>
	);
};

export default Home;
