import { FC, useEffect, useState } from 'react';
import { ReportsTab } from './ReportsTab';
import { DocumentsTab } from './DocumentsTab';
import { FundsTab } from './FundsTab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'common/components/tabs/TabPanel';
import { useLazyGetInvestorPermissionsQuery } from 'api/redux/services/investors.service';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useSelector } from 'react-redux';
import { UserInvestor } from 'app/types/user-investor.types';
import { IUserWithInvestors } from 'api/redux/services/userApi';
import { OthersTab } from './OthersTab';
import { Box } from '@mui/material';
import { PopUpAlert } from 'common/components/PopUpAlert';
import { styled } from '@mui/system';

interface IUserPermissionsBodyProps {
	handleClose: () => void;
	user: IUserWithInvestors;
	selectedInvestorId: number;
}

const ShadowTabs = styled(Tabs)(() => ({
	boxShadow: '-5px 15px 10px -15px',
}));

export const UserPermissionsBody: FC<IUserPermissionsBodyProps> = ({
	handleClose,
	user,
	selectedInvestorId,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const [tabNum, setTabNum] = useState<number>(0);
	const [pendingSwitchToTab, setPendingSwitchToTab] = useState<
		number | undefined
	>(undefined);
	const [changesMade, setChangesMade] = useState<boolean>(false);
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [fetchInvestorPermissions, userInvestorPermissions] =
		useLazyGetInvestorPermissionsQuery();
	const [permissions, setPermissions] = useState<UserInvestor | undefined>(
		undefined,
	);
	const [changesSaved, setChangesSaved] = useState<boolean>(false);

	const handleTabChange = (_event: React.SyntheticEvent, tabNum: number) => {
		if (changesMade) {
			setShowConfirmation(true);
			setChangesMade(false);
			setPendingSwitchToTab(tabNum);
			return;
		}

		setPendingSwitchToTab(undefined);
		setTabNum(tabNum);
	};

	const handleAfterSave = () => {
		if (pendingSwitchToTab !== undefined) {
			setShowConfirmation(false);
			setTabNum(pendingSwitchToTab);
			setPendingSwitchToTab(undefined);
			return;
		}

		setChangesSaved(true);
		setChangesMade(false);
	};

	useEffect(() => {
		if (selectedInvestorId) {
			fetchInvestorPermissions({
				userId: user.id,
				investorId: selectedInvestorId,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedInvestorId]);

	useEffect(() => {
		if (userInvestorPermissions.data)
			setPermissions(userInvestorPermissions.data);
	}, [userInvestorPermissions.data]);

	return (
		<>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<ShadowTabs
					value={tabNum}
					onChange={handleTabChange}
					aria-label="user permissions tab"
					sx={{
						boxShadow: 'none',
						'& .MuiTabs-indicator': {
							backgroundColor: `${sponsorColor}`,
						},
						'& .Mui-selected': {
							color: `${sponsorColor} !important`,
						},
					}}
				>
					<Tab disableRipple label="Funds" style={{ flex: 'auto' }} />
					<Tab disableRipple label="Documents" style={{ flex: 'auto' }} />
					<Tab disableRipple label="Reports" style={{ flex: 'auto' }} />
					<Tab disableRipple label="Others" style={{ flex: 'auto' }} />
				</ShadowTabs>
			</Box>
			<TabPanel value={tabNum} index={0}>
				{permissions && (
					<FundsTab
						userId={user.id}
						investorId={selectedInvestorId}
						userInvestorPermissions={permissions}
						setPermissions={setPermissions}
						changesMade={changesMade}
						setChangesMade={setChangesMade}
						showConfirmation={showConfirmation}
						handleClose={handleClose}
						handleAfterSave={handleAfterSave}
					/>
				)}
			</TabPanel>
			<TabPanel value={tabNum} index={1}>
				{permissions && (
					<DocumentsTab
						userId={user.id}
						investorId={selectedInvestorId}
						userInvestorPermissions={permissions}
						setPermissions={setPermissions}
						changesMade={changesMade}
						setChangesMade={setChangesMade}
						showConfirmation={showConfirmation}
						handleClose={handleClose}
						handleAfterSave={handleAfterSave}
					/>
				)}
			</TabPanel>
			<TabPanel value={tabNum} index={2}>
				{permissions && (
					<ReportsTab
						userId={user.id}
						investorId={selectedInvestorId}
						userInvestorPermissions={permissions}
						setPermissions={setPermissions}
						changesMade={changesMade}
						setChangesMade={setChangesMade}
						showConfirmation={showConfirmation}
						handleClose={handleClose}
						handleAfterSave={handleAfterSave}
					/>
				)}
			</TabPanel>
			<TabPanel value={tabNum} index={3}>
				{permissions && (
					<OthersTab
						userId={user.id}
						investorId={selectedInvestorId}
						userInvestorPermissions={permissions}
						setPermissions={setPermissions}
						changesMade={changesMade}
						setChangesMade={setChangesMade}
						showConfirmation={showConfirmation}
						handleClose={handleClose}
						handleAfterSave={handleAfterSave}
					/>
				)}
			</TabPanel>
			<PopUpAlert
				show={changesSaved}
				severity="success"
				onClose={() => setChangesSaved(false)}
				message="Changes Saved!"
			/>
		</>
	);
};
