import {
	IDocumentResponse,
	useLazyGetAvailableDocumentsTypeQuery,
	useLazyGetDocumentsQuery,
} from 'api/redux/services/documentApi';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { DocumentType } from 'app/types/documentTypes.types';
import { useLazyGetInvestorPermissionsQuery } from 'api/redux/services/investors.service';
import { IDocumentLoaderProps } from './types';
import {
	documentsState,
	onSetAllDocs,
	onSetAvailableDocTypes,
	onSetSelectedDocTypeIds,
	onSetUserAllowedDocTypes,
} from 'api/redux/DocumentsReducer';
import _ from 'lodash';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';

export const DocumentsLoader: React.FC<IDocumentLoaderProps> = ({
	children,
}) => {
	const grants = useSelector(selectDropdown);
	const { currentInvestor, currentFund } = grants.grants;
	const { isAdmin } = useGrants();
	const [getDocuments, documents] = useLazyGetDocumentsQuery();
	const [fetchAvailableDocTypes, availableDocumentTypes] =
		useLazyGetAvailableDocumentsTypeQuery();
	const [fetchInvestorPermissions, userInvestorPermissions] =
		useLazyGetInvestorPermissionsQuery();
	const [page, setPage] = useState<number>(0);
	const [pages, setPages] = useState<number>(1);
	const dispatch = useDispatch();
	const docs = useSelector(documentsState);
	const {
		userAllowedDocTypes,
		availableDocTypes,
		selectedDocTypeIds,
		sortStrategy,
	} = docs;

	const onRefresh = () =>
		getDocuments({
			fundId: currentFund.id,
			investorId: currentInvestor.id,
			page,
			documentTypeIds: selectedDocTypeIds,
			sortStrategy: sortStrategy,
		});

	/**
	 * Get all available document types for a given combination of fundId and investorId
	 */
	useEffect(() => {
		const investorId = currentInvestor.id;
		const fundId = currentFund.id;

		if (fundId === 0 || investorId === 0) return;

		fetchAvailableDocTypes({ fundId, investorId });
		setPage(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFund.id, currentInvestor.id]);

	/**
	 * When available document types query is fulfilled, set it in redux
	 */
	useEffect(() => {
		if (!availableDocumentTypes.data) return;

		dispatch(onSetAvailableDocTypes(availableDocumentTypes.data));
		if (isAdmin)
			dispatch(onSetUserAllowedDocTypes(availableDocumentTypes.data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availableDocumentTypes.data]);

	/**
	 * If the current user is not an admin, go fetch the allowed document type ids
	 * This set of allowed document type ids would be different per investor, so this hook will run
	 * when switching investors
	 */
	useEffect(() => {
		if (!isAdmin && currentInvestor.id !== 0)
			fetchInvestorPermissions({ investorId: currentInvestor.id });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchInvestorPermissions, currentInvestor.id]);

	/**
	 * When the user permissions query returns, set the result in the redux store
	 */
	useEffect(() => {
		if (!userInvestorPermissions.data || !availableDocumentTypes.data) return;

		const docsToShow: DocumentType[] = availableDocumentTypes.data?.filter(
			(dt: DocumentType) =>
				userInvestorPermissions.data?.documentTypes
					.map((t) => t.id)
					.includes(dt.id),
		);
		dispatch(onSetUserAllowedDocTypes(docsToShow));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, userInvestorPermissions.data, availableDocumentTypes.data]);

	/**
	 * Everytime the availableDocTypes or the userAllowedDocTypes changes in the store,
	 * set the selected document types to all allowed (all for an admin, just the allowed ones for the rest)
	 * */
	useEffect(() => {
		if (
			_.isEmpty(availableDocTypes) ||
			(!isAdmin && _.isEmpty(userAllowedDocTypes))
		)
			return;

		let optionsToShow: DocumentType[] = availableDocTypes;

		if (!isAdmin) {
			optionsToShow = availableDocTypes.filter((dt: DocumentType) =>
				userAllowedDocTypes.map((d) => d.id).includes(dt.id),
			);
		}

		if (selectedDocTypeIds.length === 0)
			dispatch(
				onSetSelectedDocTypeIds(
					optionsToShow.map((option: DocumentType) => option.id),
				),
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAllowedDocTypes, availableDocTypes]);

	/**
	 * When switching fund or investor, go fetch the documents again
	 */
	useEffect(() => {
		const investorId = currentInvestor.id;
		const fundId = currentFund.id;

		if (investorId === 0 || fundId === 0) return;

		dispatch(onSetAllDocs([]));
		setPages(0);
		documents.data = { pages: 0, data: [] };
		onRefresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentInvestor.id,
		currentFund.id,
		page,
		selectedDocTypeIds,
		sortStrategy,
	]);

	/**
	 * When the getDocuments query gets responded, filter the documents to show only those that are allowed
	 * and set the amount of pages to show in the pagination component
	 */
	useEffect(() => {
		if (
			!documents.data ||
			_.isEmpty(availableDocTypes) ||
			(!isAdmin && _.isEmpty(userAllowedDocTypes))
		)
			return;

		const { data: docs, pages } = documents.data;

		if (!docs) return;

		const allowedDocumentTypeIds = isAdmin
			? availableDocTypes.map((d) => d.id)
			: userInvestorPermissions?.data?.documentTypes?.map((dt) => dt.id);
		const documentsToShow: IDocumentResponse[] = docs.filter(
			(doc: IDocumentResponse) =>
				allowedDocumentTypeIds?.includes(doc.documentTypeId),
		);

		dispatch(onSetAllDocs(documentsToShow));
		setPages(pages);
	}, [
		availableDocTypes,
		dispatch,
		documents.data,
		isAdmin,
		userAllowedDocTypes,
		userInvestorPermissions?.data?.documentTypes,
	]);

	return (
		<>
			{children({
				onRefresh,
				pages,
				page,
				setPage,
			})}
		</>
	);
};
