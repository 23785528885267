import { FC } from 'react';
import { Typography, IconButton, Box, styled } from '@mui/material';
import { ILabelBoxProps } from './UpdateDisclaimerTypes';
import { Edit } from '@mui/icons-material';

const Wrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-end',
	gap: '1em',
});

const Container = styled(Box)({
	marginLeft: 'auto',
	marginRight: 'auto',
	marginTop: 15,
	marginBottom: 15,
	padding: 15,
});

const Uneditable = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-end',
	justifyContent: 'space-between',
});

export const LabelBox: FC<ILabelBoxProps> = ({
	show,
	sponsor,
	isEditing,
	setIsEditing,
}) => {
	if (!show) return null;

	return (
		<Container>
			<Uneditable>
				<Wrapper>
					<Typography variant="h4">Disclaimer</Typography>
					<span>{sponsor.disclaimer}</span>
				</Wrapper>
				<IconButton size="small" onClick={() => setIsEditing(!isEditing)}>
					<Edit />
				</IconButton>
			</Uneditable>
		</Container>
	);
};
