import { Typography, Container, Grid, Paper } from '@mui/material';
import {
	useUploadDarkLogoMutation,
	useUploadLightLogoMutation,
} from 'api/redux/services/organizations.service';
import { FC } from 'react';
import { ELogoTypes, IUploadLogoProps } from './types';
import { UploadLogoCard } from './UploadLogoCard';
import { styled } from '@mui/system';

const Root = styled(Container)(() => ({
	display: 'flex',
	paddingRight: 0,
	paddingLeft: 0,
	marginRight: 0,
	marginTop: 0,
	maxWidth: '100vw',
	overflow: 'hidden',
}));

const PaperBox = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(1),
	borderRadius: 5,
}));

export const UploadLogo: FC<IUploadLogoProps> = ({ sponsor }) => {
	const [uploadLightLogo, { error: uploadLightLogoError }] =
		useUploadLightLogoMutation();
	const [uploadDarkLogo, { error: uploadDarkLogoError }] =
		useUploadDarkLogoMutation();

	const Header: FC = () => {
		return (
			<>
				<Typography align="center" variant={'h3'}>
					Upload logo
				</Typography>
				<Typography align="center" variant={'subtitle1'}>
					JPG, PNG, and GIF files are allowed
				</Typography>
			</>
		);
	};

	return (
		<PaperBox>
			<Header />
			<Root maxWidth={false}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<UploadLogoCard
							sponsor={sponsor}
							logoType={ELogoTypes.LIGHT}
							uploadMethod={uploadLightLogo}
							uploadError={uploadLightLogoError}
						/>
					</Grid>

					<Grid item xs={6}>
						<PaperBox>
							<UploadLogoCard
								sponsor={sponsor}
								logoType={ELogoTypes.DARK}
								uploadMethod={uploadDarkLogo}
								uploadError={uploadDarkLogoError}
							/>
						</PaperBox>
					</Grid>
				</Grid>
			</Root>
		</PaperBox>
	);
};
