import { IFundCapitalActivity } from '../fund-capital-activity.types';
import { findInAttributeData, isZero } from './cellValue';
import {
	EHiddenBehavior,
	ETimePeriodSuffixes,
	ICapitalActivityData,
	IRow,
	TemplateHelper,
} from './types';

export class ITDTemplateHelper implements TemplateHelper {
	shouldRenderHiddableRow(
		row: IRow,
		CASData: ICapitalActivityData[],
		fundData: ICapitalActivityData[],
	): boolean {
		const cellITD = findInAttributeData(
			row.label.text,
			CASData,
			ETimePeriodSuffixes.ITD,
		);
		const cellYTD = findInAttributeData(
			row.label.text,
			CASData,
			ETimePeriodSuffixes.YTD,
		);

		if (!isZero(cellITD?.value) || !isZero(cellYTD?.value)) return true;

		if (
			isZero(cellITD?.value) &&
			isZero(cellYTD?.value) &&
			row.hiddenBehavior?.includes(
				EHiddenBehavior.SHOW_AS_ZERO_WHEN_IN_FUND_LEVEL,
			)
		) {
			const fundCellITD = findInAttributeData(
				row.label.text,
				fundData,
				ETimePeriodSuffixes.ITD,
			);
			const fundCellYTD = findInAttributeData(
				row.label.text,
				fundData,
				ETimePeriodSuffixes.YTD,
			);

			if (!isZero(fundCellITD?.value) || !isZero(fundCellYTD?.value))
				return true;
		}

		return false;
	}
}
