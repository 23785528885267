import { User, useAuth0 } from '@auth0/auth0-react';
import { FC, useEffect, useState } from 'react';
import LogRocket from 'logrocket';
import { useConfig } from 'api/redux/services/config';
import {
	IFlag,
	useGetAllFlagsQuery,
} from 'api/redux/services/featureFlags.service';
import { EFlags } from 'common/helpers/Constants';

export const ScreenLoggerWrapper: FC = () => {
	const { data: config } = useConfig();
	const { isAuthenticated, user } = useAuth0();
	const { data: flags } = useGetAllFlagsQuery();
	const [flagWasRead, setFlagWasRead] = useState(false);
	const identifyUser = async (user: User): Promise<void> => {
		LogRocket.identify(user?.email || 'unknown', {
			name: user?.name || 'unknown',
			email: user?.email || 'unknown',
		});
	};

	const findFlag = (flagName: EFlags): IFlag | undefined => {
		if (!flags) return;

		return flags.find((flag: IFlag) => flag.name === flagName);
	};

	useEffect(() => {
		if (!config || flags === undefined) return;

		const flag = findFlag(EFlags.SESSIONS_USE_RECORDING_TOOL);

		if (flag?.enabled) {
			LogRocket.init(config.logRocket.appId);
			setFlagWasRead(true);
		}
	}, [config, flags]);

	useEffect(() => {
		if (!flagWasRead || !isAuthenticated || !user) return;

		if (user?.email) {
			identifyUser(user);
		}
	}, [flagWasRead, user, isAuthenticated]);

	return <></>;
};
