import React, { useState } from 'react';
import {
	Box,
	Typography,
	Button,
	Snackbar,
	Alert,
	FormControl,
	OutlinedInput,
	styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { defaultColor } from 'api/redux/siteColorReducer';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { usePostQuestionMutation } from 'api/redux/services/helpApi';
import LoadingSpinner from 'common/components/LoadingSpinner';

const Root = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	padding: '25px',
	marginLeft: '55px',
});

const Header = styled(Box)({
	width: '100%',
});

const Text = styled(Typography)({
	margin: '25px 0 10px 0',
});

const Container = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	width: '60%',
});

const InputStyle = {
	borderBottom: 'none !important',
	boxShadow: 'none !important',
	margin: '0 0 0 10px !important',
};

const ButtonContainer = styled(Box)({
	display: 'flex',
	width: '100%',
	justifyContent: 'flex-end',
	alignItems: 'flex-end',
	alignContent: 'flex-end',
});

const SubmitButton = styled(Button)({
	marginTop: '25px',
	fontSize: '12px',
	backgroundColor: `${defaultColor}`,
	boxShadow: 'none',
	textTransform: 'none',
});

export const HelpPage: React.FC = () => {
	const [postQuestion] = usePostQuestionMutation();
	const [subject, setSubject] = useState('');
	const [content, setContent] = useState('');
	const [loading, setLoading] = useState(false);
	const [successMessage, setSuccessMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);

	const submit = async () => {
		setLoading(true);
		await postQuestion({
			subject: subject,
			content: content,
			topic: '',
		});
		setSubject('');
		setContent('');
		setSuccessMessage(true);
		setLoading(false);
	};

	return (
		<MainSidebarContainer>
			{loading && <LoadingSpinner />}
			<Snackbar
				open={successMessage}
				autoHideDuration={6000}
				onClose={() => setSuccessMessage(false)}
			>
				<Alert
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => setSuccessMessage(false)}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					severity="success"
				>
					Your question was successfully submitted
				</Alert>
			</Snackbar>
			<Snackbar
				open={errorMessage}
				autoHideDuration={6000}
				onClose={() => setErrorMessage(false)}
			>
				<Alert
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => setErrorMessage(false)}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					severity="error"
				>
					There was a problem submitting your question. Please try again later.
				</Alert>
			</Snackbar>
			<Root>
				<Header>
					<Typography variant="h3">Contact Us</Typography>
				</Header>
				<Container>
					<FormControl variant="outlined">
						<Text variant="h4">Subject</Text>
						<OutlinedInput
							value={subject}
							sx={{ width: '40%' }}
							inputProps={{ style: InputStyle, autoComplete: 'off' }}
							onChange={(e) => setSubject(e.target.value)}
						/>
					</FormControl>
					<FormControl variant="outlined">
						<Text variant="h4">Question or Comment</Text>
						<OutlinedInput
							value={content}
							minRows={3}
							maxRows={8}
							multiline
							inputProps={{ style: InputStyle, autoComplete: 'off' }}
							onChange={(e) => setContent(e.target.value)}
						/>
					</FormControl>
					<ButtonContainer>
						<SubmitButton
							onClick={submit}
							size="large"
							variant="contained"
							color="secondary"
						>
							Submit
						</SubmitButton>
					</ButtonContainer>
				</Container>
			</Root>
		</MainSidebarContainer>
	);
};

export default HelpPage;
