import { FC } from 'react';
import { MenuItem, InputLabel } from '@mui/material';
import { onChangeInvestor, selectDropdown } from 'api/redux/DropdownReducer';
import { DropdownFormControl, SelectControl } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { ERoutes } from 'App';
import { IInvestorGrant } from 'api/redux/services/userApi';
import { onSetSelectedDocTypeIds } from 'api/redux/DocumentsReducer';
import { SelectInvestorButton } from './SelectInvestorButton';
import { ROLES } from 'common/helpers/permissions/Roles';
import { emptyInvestor } from 'api/redux/types';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';

interface IDynamicInvestors {
	allowedInvestors: IInvestorGrant[];
}

const DynamicInvestors: FC<IDynamicInvestors> = ({ allowedInvestors }) => {
	const grants = useSelector(selectDropdown);
	const dispatch = useDispatch();
	const { currentInvestor } = grants.grants;
	const { pathname } = useLocation();
	const isInvestorViewPage = matchPath(pathname, ERoutes.InvestorsView);

	return (
		<DropdownFormControl variant="outlined" size="small">
			<InputLabel id="investor-selector" style={{ color: '#F1F2F9' }}>
				Investor
			</InputLabel>
			<SelectControl
				labelId="investor-selector"
				id="investor-selector"
				value={currentInvestor.id}
				label="Investor"
				sx={
					isInvestorViewPage
						? {
								'& .MuiOutlinedInput-notchedOutline': {
									backgroundColor: '#ffff0030',
									border: '2px solid #FFFFFF !important',
								},
						  }
						: {}
				}
			>
				{allowedInvestors.map((investor, index) => (
					<MenuItem
						onClick={() => {
							dispatch(onChangeInvestor(investor));
							dispatch(onSetSelectedDocTypeIds([]));
						}}
						key={index}
						value={investor.id}
					>
						{investor.name}
					</MenuItem>
				))}
			</SelectControl>
		</DropdownFormControl>
	);
};

export const InvestorDropdown: FC = () => {
	const { pathname } = useLocation();
	const isInvestorViewPage = matchPath(pathname, ERoutes.InvestorsView);
	const dispatch = useDispatch();
	const { isAdmin, currentRoles } = useGrants();
	const { grants } = useSelector(selectDropdown);
	const { showAllUsers } = useSelector(selectDropdown);
	const { availableInvestors, allAvailableInvestors, currentInvestor } = grants;
	let allowedInvestors: IInvestorGrant[] = [];

	allowedInvestors = availableInvestors;

	if (allowedInvestors.length < 1) return <></>;

	if (isInvestorViewPage && showAllUsers) return <SelectInvestorButton />;

	if (isInvestorViewPage && !showAllUsers) {
		allowedInvestors = allAvailableInvestors.filter(
			(investor) => investor.role === ROLES.INVESTOR_ADMIN || isAdmin,
		);

		if (currentInvestor.role === ROLES.INVESTOR_USER) {
			const firstAdminInvestor = allowedInvestors[0] || emptyInvestor;
			dispatch(onChangeInvestor(firstAdminInvestor));
		}
	}

	return <DynamicInvestors allowedInvestors={allowedInvestors} />;
};
