import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Chip, CircularProgress, Grid } from '@mui/material';
import { Button, TextField } from '@mui/material';
import { Check, Delete, Replay } from '@mui/icons-material';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { ROLES } from 'common/helpers/permissions/Roles';
import { IActionsProps, IUserRowProps, IInviteButtonProps } from './types';
import { IconBtn } from 'common/components/IconBtn';
import { isEmail } from 'common/helpers/Validator';
import { ErrorMessages } from './ErrorMessages';
import { DropDown } from './Dropdown';
import {
	ICreateUserDto,
	useInviteUserMutation,
} from 'api/redux/services/userApi';
import { constants } from 'common/helpers/Constants';
import { styled } from '@mui/system';

const UserRowBox = styled(Box)(() => ({
	cursor: 'pointer',
}));

const DropDownBox = styled(Box)(() => ({
	height: '100%',
	width: '100%',
	display: 'flex',
	alignItems: 'bottom',
	paddingBottom: '4px',
}));

const DeleteButtonBox = styled(Box)(() => ({
	height: '100%',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

const InviteButton = styled(Button)(() => ({
	width: 'max-content',
	textTransform: 'none',
	color: 'white !important',
	fontSize: '1rem',
	'&.Mui-disabled': { backgroundColor: 'grey !important' },
	'&:hover, &.Mui-focusVisible': {
		color: '#0092D8 !important',
	},
}));

const TextInput = styled(TextField)(() => ({
	border: '0px 0px 1px 0px solid #D0D7DE',
	'& .MuiOutlinedInput': {
		margin: '0px 0px 0px 0px !important',
	},
}));

const InviteButtonBox = styled(Box)(() => ({
	height: '100%',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

const ChipBox = styled(Box)(() => ({
	padding: '5px',
	textAlign: 'end',
}));

export const UserRow: FC<IUserRowProps> = ({
	sponsorInvestors,
	sponsorId,
	user,
	onUpdate,
	onDelete,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const [name, setName] = useState<string>(user?.name || '');
	const [email, setEmail] = useState<string>(user?.email || '');
	const [role, setRole] = useState<ROLES>(user?.role as ROLES);
	const [investorId, setInvestorId] = useState<number | undefined>(
		user?.investorId,
	);
	const [inviteUser, invite] = useInviteUserMutation();
	const [error, setError] = useState<string>('');
	const [nameError, setNameError] = useState<string>('');
	const [emailError, setEmailError] = useState<string>('');
	const [investorError, setInvestorError] = useState<string>('');
	const errored =
		invite.isError ||
		error !== '' ||
		nameError !== '' ||
		emailError !== '' ||
		investorError !== '';

	const handleInvite = async () => {
		setError('');
		const data: ICreateUserDto = {
			fullName: name,
			email: email,
			organizationId: sponsorId,
			role: role as ROLES,
			investorId: investorId,
		};

		inviteUser({ data })
			.unwrap()
			.then(() => {})
			.catch(() => {
				setError(
					`An error occurred. Please try again or contact support (${constants.SUPPORT_EMAIL})`,
				);
			});
	};

	const validateEmail = (email: string): void => {
		setEmailError('');
		if (email === '') {
			setEmailError('Please fill in the email');
			return;
		}

		if (!isEmail(email)) setEmailError('Invalid email format');
	};

	const updateUser = () => {
		onUpdate({
			id: user?.id,
			name,
			email,
			role,
			investorId,
		});
	};

	const handleEmailBlur = (e) => {
		validateEmail(e.target.value);
		updateUser();
	};

	const handleNameBlur = () => {
		if (name === '') {
			setNameError('Please fill in the name');
			return;
		}

		setNameError('');
		updateUser();
	};

	const handleInvestorBlur = () => {
		if (!investorId) {
			setInvestorError('Please select an investor form the list');
			return;
		}

		setInvestorError('');
		updateUser();
	};

	const InviteBtn: FC<IInviteButtonProps> = ({ disabled, loading }) => {
		return (
			<InviteButtonBox>
				<InviteButton
					variant="outlined"
					onClick={handleInvite}
					disabled={loading || disabled}
					sx={{
						backgroundColor: `${sponsorColor}`,
					}}
				>
					{loading ? <CircularProgress color="inherit" size={15} /> : 'Invite'}
				</InviteButton>
			</InviteButtonBox>
		);
	};

	const Actions: FC<IActionsProps> = ({
		invited,
		loading,
		errored,
		requestError,
	}) => {
		return (
			<>
				{!invited && <InviteBtn disabled={errored} loading={loading} />}
				{invited && (
					<ChipBox>
						<Chip icon={<Check />} label="Invited" />
					</ChipBox>
				)}
				{requestError && (
					<ChipBox>
						<Chip
							icon={<Replay />}
							label="Retry invite"
							onClick={handleInvite}
						/>
					</ChipBox>
				)}
			</>
		);
	};

	return (
		<UserRowBox>
			<Grid container columns={24} spacing={1}>
				<Grid item xs={1}>
					<DeleteButtonBox>
						<IconBtn
							onClick={() => onDelete(user.id)}
							customColor={true}
							tooltip={'Remove this user'}
						>
							<Delete />
						</IconBtn>
					</DeleteButtonBox>
				</Grid>
				<Grid item xs={6}>
					<TextInput
						fullWidth
						inputProps={{
							style: {
								paddingLeft: '5px',
								border: 'none',
								margin: '0px',
							},
						}}
						value={name}
						onChange={(e) => setName(e.target.value)}
						onBlur={handleNameBlur}
					/>
				</Grid>
				<Grid item xs={7}>
					<TextInput
						fullWidth
						inputProps={{
							style: {
								paddingLeft: '5px',
								border: 'none',
								margin: '0px',
							},
						}}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						onBlur={handleEmailBlur}
					/>
				</Grid>
				<Grid item xs={4}>
					<DropDownBox>
						<DropDown
							value={role}
							onChange={(e) => setRole(e.target.value as ROLES)}
							objects={Object.values(ROLES)
								.filter(
									(r) =>
										![
											ROLES.SUPER_ADMIN,
											ROLES.SPONSOR_ADMIN,
											ROLES.SPONSOR_USER,
										].includes(r),
								)
								.map((role) => {
									return { id: role, name: role };
								})}
							onBlur={updateUser}
						/>
					</DropDownBox>
				</Grid>
				<Grid item xs={4}>
					<DropDownBox>
						<DropDown
							value={investorId}
							onChange={(e) => {
								setInvestorError('');
								setInvestorId(e.target.value);
							}}
							objects={sponsorInvestors.map((investor) => {
								return { id: investor.id, name: investor.name };
							})}
							onBlur={handleInvestorBlur}
						/>
					</DropDownBox>
				</Grid>
				<Grid item xs={2}>
					<Actions
						invited={invite.isSuccess}
						loading={invite.isLoading}
						errored={errored}
						requestError={invite.isError}
					/>
				</Grid>
			</Grid>
			<ErrorMessages
				error={error}
				nameError={nameError}
				emailError={emailError}
				investorError={investorError}
			/>
		</UserRowBox>
	);
};
