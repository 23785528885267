import { Paper } from '@mui/material';
import {
	useGetOrganizationQuery,
	useUpdateDisclaimerMutation,
} from 'api/redux/services/organizations.service';
import { FC, useEffect, useState } from 'react';
import { IUpdateDisclaimerProps } from './UpdateDisclaimerTypes';
import { EditBox } from './EditBox';
import { LabelBox } from './LabelBox';

export const UpdateDisclaimer: FC<IUpdateDisclaimerProps> = ({ sponsor }) => {
	const [isEditing, setIsEditing] = useState(false);
	const [disclaimer, setDisclaimer] = useState(sponsor.disclaimer);
	const [updateDisclaimer, { data: updatedDisclaimer, isLoading }] =
		useUpdateDisclaimerMutation();
	const { refetch } = useGetOrganizationQuery(sponsor.id);

	useEffect(() => {
		refetch();
	}, [updatedDisclaimer, refetch]);

	async function handleSubmit() {
		await updateDisclaimer({ data: disclaimer, sponsor: sponsor });
		setIsEditing(false);
	}

	useEffect(() => {
		if (!isEditing) setDisclaimer(sponsor.disclaimer);
	}, [isEditing, sponsor.disclaimer]);

	return (
		<Paper>
			<EditBox
				show={isEditing}
				disclaimer={disclaimer}
				setDisclaimer={setDisclaimer}
				isEditing={isEditing}
				setIsEditing={setIsEditing}
				isLoading={isLoading}
				handleSubmit={handleSubmit}
			/>
			<LabelBox
				show={!isEditing}
				sponsor={sponsor}
				isEditing={isEditing}
				setIsEditing={setIsEditing}
			/>
		</Paper>
	);
};

export default UpdateDisclaimer;
