import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IViewInvestorInfoButtonProps } from './types';
import {
	onChangeInvestor,
	onShowAllUsers,
	selectDropdown,
} from 'api/redux/DropdownReducer';
import { IInvestorGrant } from 'api/redux/services/userApi';
import { IconBtn } from 'common/components/IconBtn';
import { VisibilityOutlined } from '@mui/icons-material';

export const ViewInvestorInfoButton: FC<IViewInvestorInfoButtonProps> = ({
	show,
	investorId,
	onClick,
}) => {
	const dispatch = useDispatch();
	const grants = useSelector(selectDropdown);
	const { allAvailableInvestors } = grants.grants;

	if (!show || !investorId) return <></>;

	const findInvestorGrant = (
		investorId: number,
	): IInvestorGrant | undefined => {
		return allAvailableInvestors.find((grant) => grant.id === investorId);
	};

	const handleOnClick = () => {
		const investor = findInvestorGrant(investorId);

		dispatch(onChangeInvestor(investor));
		dispatch(onShowAllUsers(false));
		onClick();
	};

	return (
		<IconBtn
			customColor={true}
			onClick={handleOnClick}
			tooltip="View Investor Info"
		>
			<VisibilityOutlined />
		</IconBtn>
	);
};
