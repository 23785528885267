import { FC } from 'react';
import { ITitleBarProps } from './types';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Typography } from '@mui/material';
import { BrandedButton } from 'common/components/BrandedButton';

export const TitleBar: FC<ITitleBarProps> = ({ onClose }) => {
	return (
		<Grid container direction="row">
			<Grid item xs={10} md={10}>
				<Box>
					<Typography variant="h4" sx={{ fontSize: '1.6rem' }}>
						Permissions
					</Typography>
				</Box>
			</Grid>

			<Grid item xs={2} md={2}>
				<Box display="flex" justifyContent="flex-end">
					<BrandedButton variant="text" onClick={onClose}>
						<CloseIcon />
					</BrandedButton>
				</Box>
			</Grid>
		</Grid>
	);
};
