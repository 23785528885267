import { FC } from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const ByUserInfo: FC = () => {
	return (
		<Box sx={{ padding: '2rem' }}>
			<Typography
				variant={'h3'}
				align="center"
				style={{ paddingBottom: '2rem' }}
			>
				Export By User
			</Typography>
			<Typography variant="h6">
				This export mode will create a row for every user. Certain fields will
				not available in this export mode, like Address, Document access, etc.
				because a user may have differing values across investors.
			</Typography>
		</Box>
	);
};
