import React from 'react';
import { NavbarLoader } from './NavbarLoader';
import { NavbarElements } from './NavbarElements';
import { CookiesManager } from './CookiesManager';

export const Navbar: React.FC = () => {
	return (
		<CookiesManager>
			<NavbarLoader>
				<NavbarElements />
			</NavbarLoader>
		</CookiesManager>
	);
};
