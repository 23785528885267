import { FC } from 'react';
import { Button, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { onShowAllUsers, selectDropdown } from 'api/redux/DropdownReducer';
import { useDispatch } from 'react-redux';

const ButtonBox = styled(Button)({
	color: 'white',
	border: '1px solid white',
	margin: 5,
	width: '200px',
	fontSize: '12px',
	'&:hover': {
		border: '1px solid white',
		fontWeight: 'bold',
	},
	textTransform: 'none',
});

export const SelectInvestorButton: FC = () => {
	const dispatch = useDispatch();
	const { showAllUsers } = useSelector(selectDropdown);

	return (
		<ButtonBox
			variant="outlined"
			size="small"
			sx={{
				backgroundColor: showAllUsers ? 'transparent' : '#FFFF0025',
			}}
			onClick={() => dispatch(onShowAllUsers(false))}
		>
			Select Investor
		</ButtonBox>
	);
};
