import { FC } from 'react';
import { DocumentsLoader } from './DocumentsLoader';
import { DocumentsList } from './DocumentsList';
import { TDocumentsListProps } from './types';

export const Documents: FC = () => {
	return (
		<DocumentsLoader>
			{(injectedProps: TDocumentsListProps) => (
				<DocumentsList {...injectedProps} />
			)}
		</DocumentsLoader>
	);
};

export default Documents;
