import { ISponsor } from 'app/types/sponsor.types';
import { Dispatch, SetStateAction } from 'react';

export interface IUpdateDisclaimerProps {
	sponsor: ISponsor;
}

export interface IEditBoxProps {
	show: boolean;
	disclaimer: string;
	setDisclaimer: Dispatch<SetStateAction<string>>;
	isEditing: boolean;
	setIsEditing: Dispatch<SetStateAction<boolean>>;
	isLoading: boolean;
	handleSubmit: () => void;
}

export interface ILabelBoxProps {
	show: boolean;
	sponsor: ISponsor;
	isEditing: boolean;
	setIsEditing: Dispatch<SetStateAction<boolean>>;
}

export const MAX_DISCLAIMER_CHARS = 150;
