import axios from 'axios';

interface IPDFResponse {
	data: Blob;
}

export const generatePDF = async (
	capitalActivityId: number,
	baseUrl?: string,
): Promise<IPDFResponse> => {
	return await axios(`${baseUrl}/capital-activity/generate-pdf`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem('token')}`,
		},
		data: { capitalActivityId },
		responseType: 'blob',
	});
};
