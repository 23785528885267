import { FC } from 'react';
import { useSelector } from 'react-redux';
import { PersonAddAlt1 } from '@mui/icons-material';
import { IconBtn } from 'common/components/IconBtn';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { IAddUserButtonProps } from '../types';
import { matchPath, useLocation } from 'react-router-dom';
import { ERoutes } from 'App';

export const AddUserButton: FC<IAddUserButtonProps> = ({
	setAddSponsor,
	canAddUser,
}) => {
	const { pathname } = useLocation();
	const isInvestorViewPage = matchPath(pathname, ERoutes.InvestorsView);
	const { grants, showAllUsers } = useSelector(selectDropdown);
	const { currentSponsor } = grants;

	if (
		!canAddUser() ||
		currentSponsor.id === 0 ||
		(isInvestorViewPage && showAllUsers)
	)
		return <></>;

	return (
		<IconBtn
			customColor={true}
			onClick={() => setAddSponsor(true)}
			tooltip="Add User"
		>
			<PersonAddAlt1 />
		</IconBtn>
	);
};
