import React from 'react';

import { Organizations } from 'modules/superadmin/Organizations';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';

export const AdminPage: React.FC = () => {
	return (
		<MainSidebarContainer>
			<Organizations />
		</MainSidebarContainer>
	);
};
