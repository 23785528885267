import { FC } from 'react';
import { IDropdownProps } from './types';
import { MenuItem, Select } from '@mui/material';
import { styled } from '@mui/system';

const DropDownInput = styled(Select)(() => ({
	border: 'none',
	width: '100%',
}));

export const DropDown: FC<IDropdownProps> = ({
	value,
	onChange,
	objects,
	onBlur,
}) => {
	return (
		<DropDownInput value={value} onChange={onChange} onBlur={onBlur}>
			{objects.map((object, index) => (
				<MenuItem key={index} value={object.id}>
					{object.name}
				</MenuItem>
			))}
		</DropDownInput>
	);
};
