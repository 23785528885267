import { FC } from 'react';
import { useSelector } from 'react-redux';
import { NoteAdd } from '@mui/icons-material';
import { IconBtn } from 'common/components/IconBtn';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { ICreateEmailTemplateButtonProps } from './types';
import { hasPermission } from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';

export const CreateEmailTemplateButton: FC<ICreateEmailTemplateButtonProps> = ({
	setCreateEmailTemplate,
}) => {
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;

	if (
		!hasPermission({ scopes: [SCOPES.canCreateEmailTemplates] }) ||
		currentSponsor.id === 0
	)
		return <></>;

	return (
		<IconBtn
			customColor={true}
			onClick={() => setCreateEmailTemplate(true)}
			tooltip="Create Template"
		>
			<NoteAdd />
		</IconBtn>
	);
};
