import { FC, useEffect, useState } from 'react';
import { IHomeLoaderProps } from './types';
import { useConfig } from 'api/redux/services/config';
import { useGetOrganizationBySubdomainQuery } from 'api/redux/services/organizations.service';
import {
	useLazyGetDarkLogoQuery,
	useLazyGetLightLogoQuery,
} from 'api/redux/services/organizations.service';
import { defaultColor } from 'api/redux/siteColorReducer';

export const HomeLoader: FC<IHomeLoaderProps> = ({ children }) => {
	const subdomain =
		new URLSearchParams(window.location.search).get('subdomain') ||
		window.location.hostname.split('.')[0];
	const { data: org, isLoading: isOrgLoading } =
		useGetOrganizationBySubdomainQuery(subdomain);
	const { data: config, isLoading: isConfigLoading } = useConfig();
	const [fetchDarkLogo, { data: darkLogo, isSuccess: isDarkLogoSuccess }] =
		useLazyGetDarkLogoQuery();
	const [fetchLightLogo, { data: lightLogo, isSuccess: isLightLogoSuccess }] =
		useLazyGetLightLogoQuery();

	const orgName =
		new URLSearchParams(window.location.search).get('orgName') || undefined;
	const logo =
		new URLSearchParams(window.location.search).get('logo') || '/lpx_logo.jpg';
	const subdomainParam =
		new URLSearchParams(window.location.search).get('sub') || '';
	const redirectUrl =
		new URLSearchParams(window.location.search).get('redirectUrl') || undefined;
	const color =
		new URLSearchParams(window.location.search).get('color') || defaultColor;

	const [isShowLoading, setIsShowLoading] = useState(true);
	const [isRedirectToBrandedUrl, setIsRedirectToBrandedUrl] = useState(false);

	const isNotNullOrUndefined = (value) =>
		value !== null && value !== undefined && value !== '';

	useEffect(() => {
		setIsShowLoading(
			isOrgLoading ||
				isConfigLoading ||
				(isNotNullOrUndefined(org) &&
					!isDarkLogoSuccess &&
					!isLightLogoSuccess),
		);
	}, [
		isOrgLoading,
		isConfigLoading,
		isDarkLogoSuccess,
		isLightLogoSuccess,
		org,
	]);

	useEffect(() => {
		setIsRedirectToBrandedUrl(
			isNotNullOrUndefined(org) &&
				isNotNullOrUndefined(config) &&
				isDarkLogoSuccess &&
				isLightLogoSuccess &&
				!isNotNullOrUndefined(orgName) &&
				isNotNullOrUndefined(subdomain),
		);
	}, [org, config, orgName, isDarkLogoSuccess, isLightLogoSuccess, subdomain]);

	useEffect(() => {
		if (org) {
			fetchDarkLogo(org.id);
			fetchLightLogo(org.id);
		}
	}, [org, fetchDarkLogo, fetchLightLogo]);

	return (
		<>
			{children({
				subdomain,
				org,
				logoUrl: darkLogo?.url || lightLogo?.url || '',
				config,
				orgName,
				logo,
				subdomainParam,
				isConfigLoading,
				isOrgLoading,
				isShowLoading,
				isRedirectToBrandedUrl,
				baseUrl: config?.auth0.redirectUri || '',
				redirectUrl,
				color,
			})}
		</>
	);
};
