import { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
	TFundsAndCommitments,
	ICapitalActivityDataProps,
	ISingleContributionProps,
	ISingleFundAndCommitment,
	ISingleCommitment,
} from './types';
import { styled } from '@mui/system';

const InvestorTitle = styled(Typography)(() => ({
	display: 'flex',
	flexDirection: 'row',
	fontWeight: 'bold',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: '10px',
}));

const HeadingsGrid = styled(Grid)({
	marginTop: '25px',
	marginBottom: '10px',
});

const TypographyData = styled(Typography)(() => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: '10px',
}));

export const CapitalActivityData: FC<ICapitalActivityDataProps> = ({
	commitementsToShow,
}) => {
	const fundsAndCommitments: TFundsAndCommitments = commitementsToShow.map(
		(c: ISingleCommitment) => {
			return {
				fundName: c.fundName,
				amount: c.amount || '-',
			};
		},
	);

	const Headings: FC = () => (
		<HeadingsGrid container>
			<Grid item xs={6}>
				<InvestorTitle>Investment</InvestorTitle>
			</Grid>
			<Grid item xs={6}>
				<InvestorTitle>Commitment</InvestorTitle>
			</Grid>
		</HeadingsGrid>
	);

	const SingleContribution: FC<ISingleContributionProps> = ({ commitment }) => (
		<Grid container>
			<Grid item xs={6}>
				<TypographyData>{commitment.fundName}</TypographyData>
			</Grid>
			<Grid item xs={6}>
				<TypographyData>{commitment.amount.toString()}</TypographyData>
			</Grid>
		</Grid>
	);

	const Contributions: FC = () => (
		<Box>
			{fundsAndCommitments?.map(
				(commitment: ISingleFundAndCommitment, i: number) => (
					<SingleContribution commitment={commitment} key={i} />
				),
			)}
		</Box>
	);

	return (
		<Box>
			<Typography variant="h4">Investment Summary</Typography>
			<Headings />
			<Contributions />
		</Box>
	);
};
