import { FC } from 'react';
import { MenuItem, InputLabel, Select } from '@mui/material';
import { onChangeSponsor, selectDropdown } from 'api/redux/DropdownReducer';
import { DropdownFormControl, SelectControl } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { onSetSelectedDocTypeIds } from 'api/redux/DocumentsReducer';
import { DROPDOWNS_TOTAL_LENGTH } from '../types';
import { selectScreenWidth } from 'api/redux/NavBarStateReducer';

export const SponsorDropdown: FC = () => {
	const dispatch = useDispatch();
	const grants = useSelector(selectDropdown);
	const { availableSponsors, currentSponsor } = grants.grants;
	const screenWidth = useSelector(selectScreenWidth);
	const isMobile = screenWidth < DROPDOWNS_TOTAL_LENGTH;

	if (isMobile) return <></>;

	return (
		<div>
			<DropdownFormControl
				variant="outlined"
				size="small"
				style={{ margin: 5, width: '200px' }}
			>
				<InputLabel id="sponsor-selector" style={{ color: '#F1F2F9' }}>
					Sponsor
				</InputLabel>
				<SelectControl
					labelId="sponsor-selector"
					id="sponsor-selector"
					value={currentSponsor.id}
					label="Sponsor"
				>
					{availableSponsors.map((org, index) => (
						<MenuItem
							onClick={() => {
								dispatch(onChangeSponsor(org));
								dispatch(onSetSelectedDocTypeIds([]));
							}}
							key={index}
							value={org.id}
						>
							{org.name}
						</MenuItem>
					))}
				</SelectControl>
			</DropdownFormControl>
		</div>
	);
};
