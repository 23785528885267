import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { ROLES } from 'common/helpers/permissions/Roles';
import { ERoutes } from 'App';
import { IAddUserModalProps, IAvailableRoutes } from './types';
import { AddUserForm } from './AddUserModalForm';
import { ErrorMessage } from './ErrorMessage';
import { ActionButtons } from './ActionButtons';
import { Dialog, DialogTitle } from '@mui/material';
import { useLocation, useResolvedPath } from 'react-router-dom';
import {
	ICreateUserDto,
	useInviteUserMutation,
} from 'api/redux/services/userApi';
import { constants } from 'common/helpers/Constants';

export const AddUserModal: FC<IAddUserModalProps> = ({
	isOpen,
	handleClose,
	afterAdd,
}) => {
	const [inviteUser] = useInviteUserMutation();
	const investorViewRoles = [ROLES.INVESTOR_USER, ROLES.INVESTOR_ADMIN];
	const organizationViewRoles = [ROLES.SPONSOR_ADMIN, ROLES.SPONSOR_USER];
	const availableRoles: IAvailableRoutes = {
		[ERoutes.InvestorsView]: investorViewRoles,
		[ERoutes.OrganizationView]: organizationViewRoles,
	};
	const location = useLocation();
	const resolvedPath = useResolvedPath(location.pathname);
	const path = resolvedPath.pathname;
	const firstAvailableRole: ROLES = availableRoles[path][0];
	const [fullName, setFullName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [role, setRole] = useState<ROLES>(firstAvailableRole);
	const [error, setError] = useState<string>('');
	const [inviting, setInviting] = useState<boolean>(false);
	const grants = useSelector(selectDropdown);

	const handleSubmit = async () => {
		if (!role) {
			setError('Please select a role');
			return;
		}
		const currentSelectedGrant = grants.grants;
		const organizationId = currentSelectedGrant.currentSponsor.id;
		const fundId = currentSelectedGrant.currentFund.id;
		const investorId = currentSelectedGrant.currentInvestor.id;
		const data: ICreateUserDto = {
			fullName,
			email,
			organizationId,
			fundId,
			role,
			investorId,
		};

		if (
			fullName == '' ||
			fullName == null ||
			email == '' ||
			email == null ||
			role == undefined ||
			email == null
		) {
			setError('One or more of the fields are missing!');
			return;
		}

		setInviting(true);

		inviteUser({ data })
			.unwrap()
			.then(async () => {
				setError('');
				setFullName('');
				setEmail('');
				setRole(firstAvailableRole);
				await afterAdd();
				handleClose();
			})
			.catch(() => {
				setError(
					`An error occurred. Please try again or contact support (${constants.SUPPORT_EMAIL})`,
				);
			})
			.finally(() => {
				setInviting(false);
			});
	};

	return (
		<Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
			<DialogTitle>Invite User</DialogTitle>
			<AddUserForm
				availableRoles={availableRoles}
				email={email}
				setEmail={setEmail}
				fullName={fullName}
				setFullName={setFullName}
				role={role}
				setRole={setRole}
			/>
			<ErrorMessage error={error} setError={setError} />
			<ActionButtons
				inviting={inviting}
				handleClose={handleClose}
				handleSubmit={handleSubmit}
			/>
		</Dialog>
	);
};
