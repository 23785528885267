import { FC, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	docsUploadState,
	onSetDateDialogOpen,
} from 'api/redux/DocsUploadReducer';
import { useDropzone } from 'react-dropzone';
import { PaperBox } from './styles';
import { useFileContext } from './FileContext';
import { findInvestorFromFileName } from './utils';
import { useLazyGetInvestorsQuery } from 'api/redux/services/investors.service';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { BrandedButton } from 'common/components/BrandedButton';
import { styled } from '@mui/system';

const DropZoneBox = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	padding: 10,
	width: '100%',
	backgroundColor: 'white',
}));

const IconBox = styled('p')(() => ({
	fontSize: '60px',
	margin: 0,
}));

const BrowseButton = styled(BrandedButton)(() => ({
	backgroundColor: 'white !important',
	textTransform: 'inherit',
	height: '30px !important',
	marginTop: '15px !important',
	marginBottom: '15px !important',
	marginLeft: '10px !important',
	width: '150px !important',
	fontWeight: 'bold',
	padding: '2px !important',
	fontSize: 14,
	color: '#6558f5 !important',
	border: '1px solid #cfcdf9 !important',
}));

export const DocumentsDropZone: FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentFund } = grants.grants;
	const [getInvestors, investors] = useLazyGetInvestorsQuery();
	const { selectedDocType, selectedDocDate, dateConfirmed } =
		useSelector(docsUploadState);
	const { acceptedFiles, getInputProps, getRootProps } = useDropzone({
		accept: { 'application/pdf': ['.pdf'] },
	});
	const { uploadedFiles, addUploadedFiles } = useFileContext();
	const dispatch = useDispatch();

	useEffect(() => {
		if (currentFund.id) getInvestors(currentFund.id);
	}, [currentFund.id]);

	useEffect(() => {
		if (!selectedDocDate) return;

		const selectedDate: Date = new Date(selectedDocDate);
		const today = new Date();

		if (
			selectedDate.getDate() == today.getDate() &&
			selectedDate.getMonth() == today.getMonth() &&
			selectedDate.getFullYear() == today.getFullYear()
		) {
			dispatch(onSetDateDialogOpen(true));
		}
	}, [selectedDocDate]);

	useEffect(() => {
		if (
			acceptedFiles.length > 0 &&
			selectedDocDate &&
			dateConfirmed &&
			investors.data
		) {
			buildFiles();
		}
	}, [acceptedFiles]);

	const buildFiles = () => {
		const fileList = acceptedFiles.map((file) => ({
			filename: file.name,
			date: selectedDocDate,
			investor: '',
			documentType: selectedDocType?.name || '',
			documentTypeId: selectedDocType?.id || 1,
			guessed: false,
			file: file,
		}));

		fileList.forEach(async (file) => {
			const foundInvestor = findInvestorFromFileName(
				investors.data || [],
				file.filename,
			);
			if (foundInvestor) {
				file.investor = foundInvestor.id.toString();
				file.guessed = true;
			}
		});

		addUploadedFiles(fileList);

		acceptedFiles.length = 0;
	};

	if (!selectedDocType) return <></>;

	return (
		<PaperBox>
			<DropZoneBox {...getRootProps()}>
				<input {...getInputProps({ disabled: !selectedDocType })} />
				<IconBox className="material-icons">cloud_upload</IconBox>
				<Typography style={{ fontWeight: 'bold' }} variant={'h4'}>
					{selectedDocType && `Upload ${selectedDocType.name}`}
					<br />
				</Typography>
				<Typography style={{ textAlign: 'center' }}>
					Drag and drop your .pdf files here to upload it to the selected
					folder.
					<p>(Maximum file size should not exceed 32MB.)</p>
				</Typography>
				<BrowseButton variant="outlined">Browse Files</BrowseButton>
			</DropZoneBox>
			<Box>
				<Typography align="center">Files: {uploadedFiles.length}</Typography>
			</Box>
		</PaperBox>
	);
};
