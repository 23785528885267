import { FC, useEffect, useState } from 'react';
import { IUserRowsProps } from './types';
import { Investor } from 'app/types/investor.types';
import { Box, Grid, Typography } from '@mui/material';
import { UserRow } from './UserRow';
import { useGetInvestorsBySponsorQuery } from 'api/redux/services/investors.service';

export const UserRows: FC<IUserRowsProps> = ({
	sponsorId,
	users,
	onDelete,
	onUpdate,
}) => {
	const [investors, setInvestors] = useState<Investor[]>([]);
	const { data: investorsList } = useGetInvestorsBySponsorQuery(sponsorId);

	useEffect(() => {
		if (!investorsList) return;
		setInvestors(investorsList);
	}, [investorsList]);

	const Headers: FC = () => {
		return (
			<Grid container columns={24} spacing={0.1}>
				<Grid item xs={1}></Grid>
				<Grid item xs={6}>
					<Typography>Name</Typography>
				</Grid>
				<Grid item xs={7}>
					<Typography>Email</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography>Role</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography>Investor</Typography>
				</Grid>
				<Grid item xs={2}>
					<Typography>Status</Typography>
				</Grid>
			</Grid>
		);
	};

	return (
		<Box>
			<Headers />
			{users.map((user, i) => {
				return (
					<UserRow
						key={i}
						user={user}
						sponsorInvestors={investors}
						sponsorId={sponsorId}
						onDelete={onDelete}
						onUpdate={onUpdate}
					/>
				);
			})}
		</Box>
	);
};
