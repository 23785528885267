import { FC } from 'react';
import { IErrorMessageProps } from './types';
import { Grid, Typography } from '@mui/material';

export const ErrorMessages: FC<IErrorMessageProps> = ({
	error,
	nameError,
	emailError,
	investorError,
}) => {
	if (
		error === '' &&
		nameError === '' &&
		emailError === '' &&
		investorError === ''
	)
		return <></>;

	return (
		<Grid container columns={24} spacing={1}>
			<Grid item xs={1}></Grid>
			<Grid item xs={6}>
				<Typography sx={{ color: 'red' }} align="center">
					{nameError}
				</Typography>
			</Grid>
			<Grid item xs={7}>
				<Typography sx={{ color: 'red' }} align="center">
					{emailError}
				</Typography>
			</Grid>
			<Grid item xs={4}>
				{error}
			</Grid>
			<Grid item xs={4}>
				<Typography sx={{ color: 'red' }} align="center">
					{investorError}
				</Typography>
			</Grid>
			<Grid item xs={2}></Grid>
		</Grid>
	);
};
