import { FC, MouseEventHandler, ReactElement } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { startCase, toLower } from 'lodash';

interface IIconBtn {
	onClick?: MouseEventHandler<HTMLButtonElement>;
	customColor?: boolean;
	height?: string;
	children: ReactElement;
	tooltip: string;
	disabled?: boolean;
}

export const IconBtn: FC<IIconBtn> = ({
	onClick,
	children,
	customColor,
	tooltip,
	height,
	disabled,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	const colorStyle = customColor ? { color: sponsorColor } : {};

	return (
		<Tooltip title={startCase(toLower(tooltip))}>
			<IconButton
				disabled={disabled}
				onClick={onClick}
				size="small"
				sx={{
					...colorStyle,
					boxShadow: 'none',
					backgroundColor: 'inherit',
					height: height || '48px',
					':hover': {
						backgroundColor: 'inherit',
						boxShadow: 'none',
					},
				}}
			>
				{children}
			</IconButton>
		</Tooltip>
	);
};
