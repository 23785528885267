import { FC, useState } from 'react';
import { Document, pdfjs, Page } from 'react-pdf';
import { useGetSignedUrlQuery } from 'api/redux/services/documentApi';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import { Box, Grid, Paper } from '@mui/material';
import { IDocumentPreviewProps } from './types';
import { ButtonsRow } from './ButtonsRow';

export const DocumentPreview: FC<IDocumentPreviewProps> = ({
	document,
	tableKey,
}) => {
	const { data: signedUrl } = useGetSignedUrlQuery(
		encodeURIComponent(document.gcloudPath),
	);
	const [numPages, setNumPages] = useState<number | undefined>(undefined);
	const [pageWidth, setPageWidth] = useState<number | undefined>(undefined);

	const onDocumentLoadSuccess = async (pdf) => {
		const page = await pdf.getPage(1);
		const width: number = page.view[2];
		/* 1st condition handles when pdf would overlap metadata or create horizontal scrollbars
		 * 2nd is when in tablet state
		 * 3rd is default */
		if (
			width > 800 &&
			window.innerWidth < 1300 &&
			width < window.innerWidth - 275
		) {
			setPageWidth(775);
		} else if (width > window.innerWidth - 275) {
			setPageWidth(window.innerWidth - 275);
		} else {
			setPageWidth(width);
		}
		setNumPages(pdf.numPages);
	};

	const FramedDocument = () => {
		return (
			<Document file={signedUrl} onLoadSuccess={onDocumentLoadSuccess}>
				{Array.from(new Array(numPages), (el, index) => (
					<Page
						className="pdf-page"
						error={'Failed to load PDF'}
						key={`page_${index + 1}`}
						width={pageWidth}
						pageNumber={index + 1}
					/>
				))}
			</Document>
		);
	};

	if (!signedUrl) return <></>;

	return (
		<Box
			sx={{
				display: 'flex',
				height: '100%',
				width: '100%',
				justifyContent: 'center',
			}}
		>
			<Paper elevation={0}>
				<Grid
					container
					direction="column"
					sx={{
						height: '100%',
						padding: '5px',
						borderRadius: '5px',
						border: '1px solid #D0D7DE',
					}}
				>
					<Grid
						item
						xs={11}
						sx={{
							overflowX: 'auto',
							overflowY: 'auto',
							maxWidth: '-webkit-fill-available !important',
						}}
					>
						<FramedDocument />
					</Grid>
					<Grid
						item
						xs={1}
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						<ButtonsRow
							document={document}
							tableKey={tableKey}
							signedUrl={signedUrl}
						/>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
};
