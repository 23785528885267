import { IDropAreaProps } from './types';
import { Button, Box, Typography, Card } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { FC } from 'react';
import { styled } from '@mui/system';

const ButtonBox = styled(Button)(() => ({
	backgroundColor: 'white !important',
	textTransform: 'inherit',
	height: '30px !important',
	marginTop: '15px !important',
	marginBottom: '15px !important',
	marginLeft: '10px !important',
	width: '150px !important',
	fontWeight: 'bold',
	padding: '2px !important',
	fontSize: 14,
	color: '#6558f5 !important',
	border: '1px solid #cfcdf9 !important',
}));

const FileCardContainer = styled(Box)(() => ({
	display: 'flex',
	flexWrap: 'wrap',
	width: '100%',
}));

const FileCard = styled(Card)(() => ({
	flexBasis: '45%',
	margin: 10,
	padding: 20,
	paddingBottom: 10,
}));

const DropZoneBox = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	height: 200,
	justifyContent: 'center',
	alignItems: 'center',
	padding: 20,
	width: '100%',
	backgroundColor: 'white',
	border: '1px dashed gray',
}));

const ButtonContainer = styled(Box)(() => ({
	padding: 2,
	flexDirection: 'row',
}));

const IconParagraph = styled('p')(() => ({
	fontSize: '60px',
	margin: 0,
}));

export const DropArea: FC<IDropAreaProps> = ({
	visible,
	sponsor,
	setIsUploading,
	uploadMethod,
	setUploadFinished,
	setIsEditing,
}) => {
	const {
		acceptedFiles: acceptedImages,
		getInputProps,
		getRootProps,
	} = useDropzone({
		accept: {
			'image/png': ['.png'],
			'image/gif': ['.gif'],
			'image/jpg': ['.jpg'],
			'image/jpeg': ['.jpeg'],
		},
	});

	const handleLogoUpload = async () => {
		const formData = new FormData();
		setIsUploading(true);

		formData.append('files', acceptedImages[0]);
		formData.append('sponsor', sponsor.name);

		await uploadMethod({ formData });

		setUploadFinished(true);
		setIsUploading(false);
		setIsEditing(false);
		acceptedImages.splice(0, acceptedImages.length + 1);
	};

	const AcceptedFiles: FC = () => {
		if (acceptedImages.length === 0) return <></>;

		return (
			<FileCardContainer>
				{acceptedImages.map((file, i) => (
					<FileCard key={i}>
						<Typography style={{ fontWeight: 'bold', fontSize: '.8em' }}>
							File Name
						</Typography>
						<Typography>{file.name}</Typography>
					</FileCard>
				))}
			</FileCardContainer>
		);
	};

	const UploadButton: FC = () => {
		if (acceptedImages.length === 0) return <></>;

		return (
			<ButtonBox onClick={handleLogoUpload} variant="outlined">
				Upload
			</ButtonBox>
		);
	};

	if (!visible) return <></>;

	return (
		<>
			<DropZoneBox {...getRootProps()}>
				<input {...getInputProps({ disabled: false })} />

				<Button>
					<ButtonContainer>
						<IconParagraph className={`material-icons`}>
							cloud_upload
						</IconParagraph>
						<Typography>Drag and drop or browse to choose a file</Typography>
					</ButtonContainer>
				</Button>
			</DropZoneBox>
			<AcceptedFiles />
			<UploadButton />
		</>
	);
};
