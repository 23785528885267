import { FC } from 'react';
import { Box, Paper } from '@mui/material';
import { Typography } from '@mui/material';
import { CSVTemplate } from 'modules/funds/csvTemplate';
import { CSVUpload } from 'modules/funds/csv-upload/csvUpload';

interface IUploadDataProps {}

export const UploadData: FC<IUploadDataProps> = () => {
	return (
		<Paper sx={{ width: '100%', padding: 2, borderRadius: '10px' }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography variant="h5">Upload Capital Activity Data</Typography>
			</Box>
			<Box>
				<CSVTemplate />
				<CSVUpload />
			</Box>
		</Paper>
	);
};
