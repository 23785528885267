import { ChangeEvent, FC, useState } from 'react';
import { Box, FormControl, Select, MenuItem, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useConfig } from 'api/redux/services/config';
import * as PdfService from './pdf.service';
import ICapitalActivity from './capital-activity.types';
import {
	IHeaderOptionsProps,
	IPeriodSelector,
	IPrintCasButtonProps,
} from './types';
import { ConfirmationModal } from 'common/components/UsersTable/user-permissions/ConfirmationModal';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { Delete, Print } from '@mui/icons-material';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { useDeleteCasByPeriodMutation } from 'api/redux/services/capitalActivityApi';
import { styled } from '@mui/system';

const HeaderOptionsBox = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	fontSize: '2rem',
}));

const PeriodDropDownFormControl = styled(FormControl)(() => ({
	minWidth: 350,
	fontSize: '1rem',
}));

const PeriodSelectorControl = styled(Select)(() => ({
	width: 350,
	color: '#000000 !important',
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: '#FFFFFF !important',
	},
	'& :focus': {
		backgroundColor: '#FFFFFF',
	},
	textAlign: 'right',
	'&.MuiSelect-icon': {
		color: 'red',
	},
}));

const ButtonsBox = styled(Box)(() => ({
	float: 'right',
	height: '100%',
}));

const PrintCasButton: FC<IPrintCasButtonProps> = ({ onClick, visible }) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	if (!visible) return <></>;

	return (
		<IconButton disableRipple={true} size="medium" onClick={onClick}>
			<Print
				sx={{
					height: '2rem',
					width: '2rem',
					color: `${sponsorColor}`,
				}}
			/>
		</IconButton>
	);
};

const PeriodSelector: FC<IPeriodSelector> = ({
	currentReportingPeriod,
	capitalActivityData,
	loadCapitalActivity,
	visible,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	if (!visible) return <></>;

	return (
		<PeriodDropDownFormControl size="small">
			<PeriodSelectorControl
				value={currentReportingPeriod}
				variant="outlined"
				onChange={(e) =>
					loadCapitalActivity(
						e as ChangeEvent<{ name?: string | undefined; value: unknown }>,
					)
				}
				sx={{
					'& .MuiSelect-icon': {
						fill: `${sponsorColor}`,
					},
				}}
			>
				{capitalActivityData.map((option, i) => (
					<MenuItem value={option.reportingPeriod} key={i}>
						{option.reportingPeriod}
					</MenuItem>
				))}
			</PeriodSelectorControl>
		</PeriodDropDownFormControl>
	);
};

export const HeaderOptions: FC<IHeaderOptionsProps> = ({
	loadCapitalActivity,
	capitalActivityData,
	currentReportingPeriod,
	setError,
	afterDelete,
	hasPermission,
}) => {
	const grants = useSelector(selectDropdown);
	const { currentInvestor } = grants.grants;
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const { data: config } = useConfig();
	const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
	const [deleteCas] = useDeleteCasByPeriodMutation();

	const handleDelete = async () => {
		const foundCas: ICapitalActivity | undefined = capitalActivityData.find(
			(cas) => cas.reportingPeriod === currentReportingPeriod,
		);

		if (!foundCas) {
			setError("Couldn't find a report for the corresponding reporting period");
			return;
		}

		await deleteCas({
			fundId: foundCas.fundId,
			investorId: foundCas.investorId,
			period: foundCas.reportingPeriod,
		});
		setShowConfirmDelete(false);
		afterDelete();
	};

	const createPDF = () => {
		if (capitalActivityData?.length > 0) {
			const foundCas: ICapitalActivity | undefined = capitalActivityData.find(
				(cas) => cas.reportingPeriod === currentReportingPeriod,
			);
			if (!foundCas) {
				setError(
					"Couldn't find a report for the corresponding reporting period",
				);
				return;
			}

			PdfService.generatePDF(foundCas.id, config?.apiBaseUrl)
				.then((response) => {
					const file = new Blob([response.data], { type: 'application/pdf' });
					const fileURL = URL.createObjectURL(file);
					window.open(fileURL);
				})
				.catch((error) => {
					setError(error);
				});
		}
	};

	if (capitalActivityData.length === 0 || currentInvestor.id === 0)
		return <></>;

	return (
		<HeaderOptionsBox>
			<PeriodSelector
				currentReportingPeriod={currentReportingPeriod}
				capitalActivityData={capitalActivityData}
				loadCapitalActivity={loadCapitalActivity}
				visible={hasPermission}
			/>
			<ButtonsBox>
				<PrintCasButton onClick={() => createPDF()} visible={hasPermission} />
				<PermissionsWrapper scopes={[SCOPES.canDeleteCas]}>
					<IconButton
						disableRipple={true}
						size="medium"
						onClick={() => setShowConfirmDelete(true)}
					>
						<Delete
							style={{
								height: '2rem',
								width: '2rem',
								color: `${sponsorColor}`,
							}}
						/>
					</IconButton>
				</PermissionsWrapper>
			</ButtonsBox>
			<ConfirmationModal
				isOpen={showConfirmDelete}
				onAccept={handleDelete}
				onCancel={() => setShowConfirmDelete(false)}
				title="Confirm to delete this Capital Account Statement"
				message="Are you sure to delete this Capital Account Statement?"
				saveText="Confirm"
			/>
		</HeaderOptionsBox>
	);
};

export default HeaderOptions;
