import { Typography } from '@mui/material';
import { Alert, Box } from '@mui/material';
import {
	onChangeFund,
	onChangeInvestor,
	onChangeSponsor,
	selectDropdown,
} from 'api/redux/DropdownReducer';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { CapitalAccountStatement } from '../CapitalAccountStatement/CapitalAccountStatement';

export const CasReports: FC = () => {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	let sponsorId = searchParams.get('sponsorId');
	let fundId = searchParams.get('fundId');
	let investorId = searchParams.get('investorId');
	let period = searchParams.get('period');
	const grants = useSelector(selectDropdown);
	const {
		availableSponsors,
		availableFunds,
		availableInvestors,
		currentInvestor,
		currentFund,
		currentSponsor,
	} = grants.grants;

	const dispatch = useDispatch();
	const [error, setError] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (!availableSponsors.length) return;

		if (
			!sponsorId ||
			!availableSponsors.map((s) => s.id).includes(parseInt(sponsorId))
		) {
			if (!currentSponsor) {
				setError('Invalid or missing sponsor ID');
				return;
			}
			sponsorId = currentSponsor.id.toString();
		}

		const selectedSponsor = availableSponsors.find(
			(s) => s.id === parseInt(sponsorId!),
		);

		if (selectedSponsor) {
			dispatch(onChangeSponsor(selectedSponsor));
			setError(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availableSponsors, sponsorId]);

	useEffect(() => {
		if (!availableFunds.length) return;

		if (
			!fundId ||
			!availableFunds.map((s) => s.id).includes(parseInt(fundId))
		) {
			if (!currentFund) {
				setError('Invalid or missing fund ID');
				return;
			}
			fundId = currentFund.id.toString();
		}

		const selectedFund = availableFunds.find((f) => f.id === parseInt(fundId!));

		if (selectedFund) {
			dispatch(onChangeFund(selectedFund));
			setError(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availableFunds, fundId]);

	useEffect(() => {
		if (!availableInvestors.length) return;

		if (
			!investorId ||
			!availableInvestors.map((i) => i.id).includes(parseInt(investorId))
		) {
			if (!currentInvestor) {
				setError('Invalid or missing investor ID');
				return;
			}
			investorId = currentInvestor.id.toString();
		}

		const selectedInvestor = availableInvestors.find(
			(i) => i.id === parseInt(investorId!),
		);

		if (selectedInvestor) {
			dispatch(onChangeInvestor(selectedInvestor));
			setError(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availableInvestors, investorId]);

	if (error)
		return (
			<MainSidebarContainer>
				<Alert variant="filled" severity="error">
					{error}
				</Alert>
			</MainSidebarContainer>
		);

	return (
		<MainSidebarContainer>
			<CapitalAccountStatement period={period || ''} />
		</MainSidebarContainer>
	);
};
