import { Paper } from '@mui/material';
import { styled } from '@mui/system';

export const InputWrapper = styled(Paper)(() => ({
	flex: '1 0 auto',
	overflow: 'auto',
	padding: 5,
	margin: 10,
	border: '1px solid #e3e3e3',
}));
