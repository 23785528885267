export enum EDocumentLevels {
	FUND_LEVEL = 'Fund Level',
	INVESTOR_LEVEL = 'Investor Level',
}

export interface IDocumentLevel {
	id: number;
	name: string;
}

export const documentLevels: IDocumentLevel[] = [
	{
		id: 1,
		name: EDocumentLevels.FUND_LEVEL,
	},
	{
		id: 2,
		name: EDocumentLevels.INVESTOR_LEVEL,
	},
];

export interface IFile {
	filename: string;
	date?: string;
	investor?: string;
	documentType: string;
	documentTypeId: number;
	guessed: boolean;
	file: File;
}

export interface INotificationDelay {
	id: number;
	name: string;
	minutes: number;
}

export const notificationDelays: INotificationDelay[] = [
	{
		id: 1,
		name: '15 Minutes',
		minutes: 15,
	},
	{
		id: 2,
		name: '30 Minutes',
		minutes: 30,
	},
	{
		id: 3,
		name: '1 Hour',
		minutes: 60,
	},
	{
		id: 4,
		name: '2 Hours',
		minutes: 120,
	},
	{
		id: 5,
		name: '3 Hours',
		minutes: 180,
	},
	{
		id: 6,
		name: '4 Hours',
		minutes: 240,
	},
	{
		id: 7,
		name: '5 Hours',
		minutes: 300,
	},
	{
		id: 8,
		name: 'Immediate',
		minutes: 0,
	},
];
