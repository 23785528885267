import { Checkbox } from '@mui/material';
import {
	IPermissionsMutationResponse,
	useSaveInvestorUserReportTypesPermissionsMutation,
} from 'api/redux/services/investors.service';
import { useLazyGetReportTypesQuery } from 'api/redux/services/reportTypes.service';
import { UserInvestor } from 'app/types/user-investor.types';
import { hasPermission } from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useSelector } from 'react-redux';
import { ConfirmationModal } from './ConfirmationModal';
import { SaveTabButton } from './SaveTabButton';
import { FormWrapper, InputWrapper, LabelBox, LabelWrapper } from './styles';

interface IReportsTabProps {
	userId: number;
	investorId: number;
	userInvestorPermissions: UserInvestor;
	setPermissions: Dispatch<SetStateAction<UserInvestor | undefined>>;
	changesMade: boolean;
	setChangesMade: Dispatch<SetStateAction<boolean>>;
	showConfirmation: boolean;
	handleClose: () => void;
	handleAfterSave: () => void;
}

export const ReportsTab: React.FC<IReportsTabProps> = ({
	investorId,
	userId,
	userInvestorPermissions,
	setPermissions,
	changesMade,
	setChangesMade,
	showConfirmation,
	handleClose,
	handleAfterSave,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const [fetchReportTypes, reportTypes] = useLazyGetReportTypesQuery();
	const [saveUserInvestorReportTypes, response] =
		useSaveInvestorUserReportTypesPermissionsMutation();
	const [allowedReportTypeIds, setAllowedReportTypeIds] = useState<number[]>(
		[],
	);

	useEffect(() => {
		fetchReportTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setAllowedReportTypeIds(
			userInvestorPermissions?.reportTypes?.map((t) => t.id) || [],
		);
	}, [reportTypes.data, userInvestorPermissions]);

	const canEditInvestorPermissions = hasPermission({
		scopes: [SCOPES.canEditInvestorPermissions],
	});

	const handleSave = async () => {
		const newPermissions: IPermissionsMutationResponse =
			await saveUserInvestorReportTypes({
				userId,
				investorId,
				reportTypeIds: allowedReportTypeIds.join(','),
			});

		if ('data' in newPermissions) {
			setPermissions(newPermissions.data);
		}

		handleAfterSave();
	};

	const handleChange = (reportTypeId: number) => {
		setChangesMade(true);
		if (allowedReportTypeIds.includes(reportTypeId)) {
			setAllowedReportTypeIds(
				allowedReportTypeIds.filter((id) => id !== reportTypeId),
			);
			return;
		}

		setAllowedReportTypeIds([...allowedReportTypeIds, reportTypeId]);
	};

	return (
		<>
			<InputWrapper elevation={0}>
				<FormWrapper>
					{reportTypes.data &&
						reportTypes.data.map((reportType, i) => {
							return (
								<LabelWrapper
									key={i}
									style={{ fontSize: '12px' }}
									control={
										<Checkbox
											size="small"
											disabled={!canEditInvestorPermissions}
											onChange={() => handleChange(reportType.id)}
											checked={
												allowedReportTypeIds.includes(reportType.id) || false
											}
											style={{
												color: sponsorColor,
											}}
										/>
									}
									label={<LabelBox>{reportType.name}</LabelBox>}
								></LabelWrapper>
							);
						})}
				</FormWrapper>

				<ConfirmationModal
					isOpen={showConfirmation}
					onAccept={handleSave}
					onCancel={handleClose}
					cancelText="Discard"
				/>
			</InputWrapper>

			<SaveTabButton
				changesMade={changesMade}
				handleSave={handleSave}
				saving={response.isLoading}
			/>
		</>
	);
};
