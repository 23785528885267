import { Config } from 'app/types/config.types';
import { ISponsor } from 'app/types/sponsor.types';

export interface THomeProps {
	subdomain: string;
	org: ISponsor | undefined;
	logoUrl: string;
	isOrgLoading: boolean;
	config: Config | undefined;
	isConfigLoading: boolean;
	orgName: string | undefined;
	logo: string;
	subdomainParam: string;
	isShowLoading: boolean;
	isRedirectToBrandedUrl: boolean;
	baseUrl: string;
	redirectUrl: string | undefined;
	color: string;
}

export interface IHomeLoaderProps {
	children: (props: THomeProps) => JSX.Element;
}

export const URLS_TO_SAVE = [
	'/documents/sponsor/',
	'/documents',
	'cas-reports',
];
