import { FC, useEffect } from 'react';
import { PaperBox } from './styles';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { DocumentLevelSelector } from './DocumentLevelSelector';
import { DocumentTypeSelector } from './DocumentTypeSelector';
import { DocumentDateSelector } from './DocumentDateSelector';
import { DocumentsDropZone } from './DocumentsDropZone';
import { FileProvider } from './FileContext';
import { DateDialog } from './DateDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
	docsUploadState,
	onResetUploadState,
	onSetDateConfirmed,
	onSetDateDialogOpen,
	onSetFundDialogOpen,
	onSetFundLevelConfirmed,
} from 'api/redux/DocsUploadReducer';
import { DocumentsTable } from './DocumentsTable';
import { NotificationOptions } from './NotificationOptions';
import { BulkUploadButton } from './BulkUploadButton';
import { DocumentUploadAlerts } from './DocumentUploadAlerts';
import { FundLevelDialog } from './FundLevelDialog';

export const UploadDocuments: FC = () => {
	const { dateDialogOpen, fundDialogOpen } = useSelector(docsUploadState);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(onResetUploadState({}));
	}, []);

	return (
		<FileProvider>
			<DocumentUploadAlerts />
			<PaperBox>
				<Typography variant="h5">Upload Documents</Typography>

				<Grid container direction="row" spacing={2} sx={{ padding: '2rem' }}>
					<Grid item xs={4} md={4}>
						<DocumentLevelSelector />
					</Grid>
					<Grid item xs={4} md={4}>
						<DocumentTypeSelector />
					</Grid>
					<Grid item xs={4} md={4}>
						<DocumentDateSelector />
					</Grid>
				</Grid>
			</PaperBox>
			<DocumentsDropZone />
			<DocumentsTable />
			<NotificationOptions />
			<DateDialog
				open={dateDialogOpen}
				handleClose={() => {
					dispatch(onSetDateConfirmed(true));
					dispatch(onSetDateDialogOpen(false));
				}}
				handleCloseDisagree={() => dispatch(onSetDateDialogOpen(false))}
			/>
			<FundLevelDialog
				open={fundDialogOpen}
				handleClose={() => {
					dispatch(onSetFundDialogOpen(false));
				}}
				handleAccept={() => {
					dispatch(onSetFundLevelConfirmed(true));
					dispatch(onSetFundDialogOpen(false));
				}}
			/>
			<BulkUploadButton />
		</FileProvider>
	);
};
