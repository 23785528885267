import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { IAscDesc, ISortStrategy, UsersState } from './types';
import { IUsersWithInvestors } from './services/userApi';

const initialSortStrategy: ISortStrategy = {
	column: 'role',
	ascdesc: IAscDesc.DESC,
};

const initialState: UsersState = {
	users: [],
	sortStrategy: [initialSortStrategy],
};

export const usersSlice: Slice = createSlice({
	name: 'usersState',
	initialState,
	reducers: {
		onSetUsers: (state, action: PayloadAction<IUsersWithInvestors[]>) => {
			state.users = action.payload;
		},
		onSetSortStrategy: (state, action: PayloadAction<ISortStrategy>) => {
			state.sortStrategy = [action.payload];
		},
	},
});
export const { onSetUsers, onSetSortStrategy } = usersSlice.actions;

export const usersState = ({ users }: RootState): UsersState => users;

export default usersSlice.reducer;
