import { Investor } from 'app/types/investor.types';
import { EDocumentLevels, IFile, INotificationDelay } from './types';

export const formatFileDate = (file: IFile) => {
	if (!file.date) return '';

	return `${new Date(file.date).getMonth() + 1}/${new Date(
		file.date,
	).getDate()}/${new Date(file.date).getFullYear()}`;
};

export const prepareFileData = (
	file: IFile,
	fundId: string,
	selectedDate: string,
	notifyInvestorUsers: boolean,
	level: EDocumentLevels,
	notificationDelay: INotificationDelay,
	emailTemplateId?: number,
) => {
	const formData = new FormData();
	formData.append(
		'sendInvestorUsersNotification',
		JSON.stringify(notifyInvestorUsers),
	);
	formData.append('docType', file.documentType);
	formData.append('documentTypeId', file.documentTypeId.toString());
	formData.append('file', file.file);
	formData.append('fundId', fundId);
	formData.append('period', selectedDate);
	formData.append('notificationDelayId', notificationDelay.id.toString());

	if (emailTemplateId) {
		formData.append('emailTemplateId', emailTemplateId.toString());
	}

	if (level == EDocumentLevels.INVESTOR_LEVEL && file.investor)
		formData.append('investorId', file.investor);

	return formData;
};

const stripString = (str) => str.replace(/[^a-zA-Z]/g, '');

export const findInvestorFromFileName = (
	investors: Investor[],
	fileName: string,
): Investor | undefined => {
	const strippedFileName = stripString(fileName);

	return investors?.find(
		({ investorId, name }) =>
			investorId === fileName.replace(/\.[^/.]+$/, '') ||
			strippedFileName.includes(stripString(name)),
	);
};
