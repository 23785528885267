import { Box } from '@mui/material';
import { FC, useState } from 'react';
import { ExportModes } from './types';
import { ExportMode } from './ExortMode';
import { ExportByRelationship } from './ExportByRelationship';
import { ExportByUser } from './ExportByUser';

export const ExportUsersCSV: FC = () => {
	const [mode, setMode] = useState<ExportModes>(ExportModes.BY_RELATIONSHIP);

	return (
		<Box>
			<ExportMode
				value={mode}
				handleChange={(mode: ExportModes) => setMode(mode)}
			/>

			<ExportByRelationship visible={mode === ExportModes.BY_RELATIONSHIP} />
			<ExportByUser visible={mode === ExportModes.BY_USER} />
		</Box>
	);
};
