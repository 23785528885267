import { Fragment, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Investor } from 'app/types/investor.types';

interface IInvestorAutoCompleteProps {
	handleChange: (value: string | Investor | null) => void;
	investors;
	error: boolean;
	helperText: string;
}

export const InvestorAutoComplete: React.FC<IInvestorAutoCompleteProps> = ({
	handleChange,
	investors,
	error = false,
	helperText = '',
}) => {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<readonly Investor[]>([]);
	const loading = open && options.length === 0;

	useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}

		if (active && investors) {
			const uniqueNames: string[] = [];
			const unique: Investor[] = [];
			investors.forEach((investor) => {
				if (!(investor.name in uniqueNames)) {
					uniqueNames.push(investor.name);
					unique.push(investor);
				}
			});
			setOptions(unique);
		}

		return () => {
			active = false;
		};
	}, [investors, loading]);

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	return (
		<Autocomplete
			id="asynchronous-demo"
			freeSolo
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			onChange={(event, newValue) => {
				handleChange(newValue);
			}}
			onInputChange={(event, newInputValue) => {
				handleChange(newInputValue);
			}}
			isOptionEqualToValue={(option, value) => option.name === value.name}
			getOptionLabel={(option) => (option as Investor).name}
			options={options}
			loading={loading}
			renderInput={(params) => (
				<TextField
					{...params}
					autoFocus
					label="Investor Name"
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<Fragment>
								{loading ? (
									<CircularProgress color="inherit" size={15} />
								) : null}
								{params.InputProps.endAdornment}
							</Fragment>
						),
					}}
					error={error}
					helperText={helperText}
				/>
			)}
		/>
	);
};
