import { Document } from '../../app/types/document.types';
import { Dispatch, SetStateAction } from 'react';

export enum DocumentTables {
	INVESTOR_DOCUMENTS = 'InvestorDocuments',
	FUND_DOCUMENTS = 'FundDocuments',
}

export enum DocumentTableKeys {
	IT = 'IT',
	FT = 'FT',
}

export const documentTableKeys = {
	[DocumentTables.INVESTOR_DOCUMENTS]: DocumentTableKeys.IT,
	[DocumentTables.FUND_DOCUMENTS]: DocumentTableKeys.FT,
};

export interface IDocumentToDelete {
	id: number;
	table: DocumentTables;
}

export interface TDocumentsListProps {
	onRefresh: () => void;
	pages: number;
	page: number;
	setPage: Dispatch<SetStateAction<number>>;
}

export interface IDocumentLoaderProps {
	children: (props: TDocumentsListProps) => JSX.Element;
}

export interface IDocumentMetadataProps {
	document: Document | undefined;
}

export interface IMetadataRowProps {
	iconComponent: JSX.Element;
	title?: string;
	tooltip: string;
}

export interface IDocumentPreviewProps {
	document: Document;
	tableKey: DocumentTableKeys;
}

export interface IspecificDocumentUrlParams {
	document: Document;
	withBase?: boolean;
	tableKey: DocumentTableKeys;
	investorId?: number;
}

export interface IDownloadFileParams {
	fileUrl: string;
	fileName: string;
}

export const errorMessages = {
	401: 'Oops, you are not authorized, to see that document type',
	404: "Oops, we couldn't find that document.",
};

export interface IPreviewProps {
	document: Document | undefined;
}

export interface IButtonsRowProps {
	document: Document;
	tableKey: DocumentTableKeys;
	signedUrl: string;
}

export interface IPDFWatermarkParams {
	url: string;
	userEmail?: string;
}
