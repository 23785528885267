import { createApi } from '@reduxjs/toolkit/query/react';
import dynamicBaseQuery from 'app/dynamic-base-query';
import { Announcement } from 'app/types/Announcements.types';

export interface IDeleteAnnouncementParams {
	announcementId: number;
}

interface IAnnouncementParam {
	text: string;
	sponsorId: number;
}

interface IUpdateAnnouncementParams {
	id: number;
	text: string;
}

export const announcementsApi = createApi({
	reducerPath: 'announcementsApi',
	baseQuery: dynamicBaseQuery('announcements'),
	endpoints: (builder) => ({
		getAnnouncements: builder.query<Announcement[], number>({
			query: (sponsorId) => `?sponsorId=${sponsorId}`,
		}),
		getAnnouncementsForMultipleSponsors: builder.query<
			Announcement[],
			number[]
		>({
			query: (sponsorIds) => ({
				url: `multiple-sponsors`,
				method: 'POST',
				body: { sponsorIds },
			}),
		}),
		createAnnouncement: builder.mutation<Announcement, IAnnouncementParam>({
			query: ({ text, sponsorId }) => ({
				url: ``,
				method: 'POST',
				body: {
					sponsorId,
					text: text,
				},
			}),
		}),
		updateAnnouncement: builder.mutation<
			Announcement,
			IUpdateAnnouncementParams
		>({
			query: ({ id, text }) => ({
				url: `${id}`,
				method: 'PATCH',
				body: { text },
			}),
		}),
		deleteAnnouncement: builder.mutation<
			Announcement,
			IDeleteAnnouncementParams
		>({
			query: ({ announcementId }) => ({
				url: `deleteAnnouncement?announcementId=${announcementId}`,
				method: 'DELETE',
			}),
		}),
	}),
});

export const {
	useLazyGetAnnouncementsQuery,
	useCreateAnnouncementMutation,
	useLazyGetAnnouncementsForMultipleSponsorsQuery,
	useUpdateAnnouncementMutation,
	useDeleteAnnouncementMutation,
} = announcementsApi;
