import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Navigate,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';
import { Alert, Grid } from '@mui/material';
import { Document } from 'app/types/document.types';
import {
	useLazyGetFundDocumentQuery,
	useLazyGetInvestorDocumentQuery,
} from 'api/redux/services/documentApi';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { DocumentPreview } from './DocumentPreview';
import {
	selectNavbarTablet,
	selectNavbarMobile,
} from 'api/redux/NavBarStateReducer';
import { DocumentMetadata } from './DocumentMetadata';
import { BackToDocuments } from './BackToDocuments';
import {
	onChangeFund,
	onChangeInvestor,
	onChangeSponsor,
	selectDropdown,
} from 'api/redux/DropdownReducer';
import { ISponsorGrant } from 'api/redux/services/userApi';
import { DocumentTableKeys, errorMessages } from './types';
import { ERoutes } from 'App';
import {
	onRemoveRedirectURL,
	onSetRedirectURL,
	redirectURLState,
} from 'api/redux/RedirectURLReducer';
import _ from 'lodash';
import { constants } from 'common/helpers/Constants';
import { useLocalUser } from 'common/helpers/permissions/useLocalUser';

interface IPreviewProps {
	document: Document | undefined;
}

export const SpecificDocument: FC = () => {
	const tablet = useSelector(selectNavbarTablet);
	const mobile = useSelector(selectNavbarMobile);
	const { documentId } = useParams<{ documentId: string }>();
	const { search } = useLocation();
	const { currentUser } = useLocalUser();
	const params = new URLSearchParams(search);
	const tableKey = params.get('t');
	const sponsorId = params.get('S');
	const fundId = params.get('F');
	const investorId = params.get('I');
	const dispatch = useDispatch();
	const [error, setError] = useState<string | undefined>();
	const [document, setDocument] = useState<Document | undefined>();
	const [notFound, setNotFound] = useState<boolean>(false);
	const [sponsorSet, setSponsorSet] = useState<boolean>(false);
	const [fundSet, setFundSet] = useState<boolean>(false);
	const [getInvestorDocument, investorDocument] =
		useLazyGetInvestorDocumentQuery();
	const [getFundDocument, fundDocument] = useLazyGetFundDocumentQuery();
	const grants = useSelector(selectDropdown);
	const { availableSponsors, availableFunds, availableInvestors } =
		grants.grants;
	const resolvedTableKey =
		(tableKey as DocumentTableKeys) || DocumentTableKeys.IT;
	const redirectURL = useSelector(redirectURLState);
	const navigate = useNavigate();

	useEffect(() => {
		if (_.isEmpty(availableSponsors)) return;

		const sponsor: ISponsorGrant | undefined = availableSponsors.find(
			(s) =>
				s.id ===
				(sponsorId ? parseInt(sponsorId) : document?.fund?.organizationId),
		);
		if (!sponsor) {
			navigate('/');
			return;
		}

		dispatch(onChangeSponsor(sponsor));
		setSponsorSet(true);
	}, [availableSponsors, dispatch, document, sponsorId]);

	useEffect(() => {
		if (!sponsorSet || _.isEmpty(availableFunds)) return;

		const fund = availableFunds.find(
			(f) => f.id === (fundId ? parseInt(fundId) : document?.fund?.id),
		);

		if (!fund) return;

		dispatch(onChangeFund(fund));
		setFundSet(true);
	}, [sponsorSet, availableFunds, document, dispatch, fundId]);

	useEffect(() => {
		if (!fundSet || !investorId || _.isEmpty(availableInvestors)) return;

		const investor = availableInvestors.find(
			(i) =>
				i.id === (investorId ? parseInt(investorId) : document?.investor?.id),
		);

		if (!investor) return;

		dispatch(onChangeInvestor(investor));
	}, [
		availableInvestors,
		dispatch,
		document?.investor?.id,
		investorId,
		tableKey,
		fundSet,
	]);

	useEffect(() => {
		if (!fundSet) return;

		if (!tableKey) {
			setError('An error has occurred');
			return;
		}

		if (!Object.keys(DocumentTableKeys).includes(tableKey))
			setError('An error has occurred');

		if (tableKey === DocumentTableKeys.FT) {
			getFundDocument(Number(documentId));
			return;
		}

		getInvestorDocument(Number(documentId));
	}, [documentId, getFundDocument, getInvestorDocument, tableKey, fundSet]);

	useEffect(() => {
		if (!fundDocument.data) return;

		setDocument(fundDocument.data);
	}, [fundDocument.data]);

	useEffect(() => {
		if (!investorDocument.data) return;

		setDocument(investorDocument.data);
	}, [investorDocument.data]);

	useEffect(() => {
		if ((!investorDocument.error && !fundDocument.error) || !fundSet) return;

		const error = investorDocument.error || fundDocument.error || {};
		if ('status' in error) {
			if (error.status === 404) {
				setNotFound(true);
				return;
			}

			const message = errorMessages[error.status];

			if (message) {
				setError(message);
				return;
			}

			setError(
				`Oops, an error has occurred. Please try again or contact support (${constants.SUPPORT_EMAIL})`,
			);
		}
	}, [investorDocument.error, fundDocument.error, documentId, fundSet]);

	const Preview: FC<IPreviewProps> = ({ document }) => {
		if (!document) return <></>;

		return <DocumentPreview document={document} tableKey={resolvedTableKey} />;
	};

	const saveURL = () => {
		const { pathname, search } = window.location;
		dispatch(onSetRedirectURL(`${pathname}${search}`));
	};

	if (notFound) {
		dispatch(onSetRedirectURL(ERoutes.Documents));
		return <Navigate to={ERoutes.Dashboard} />;
	}

	if (!currentUser?.initialLogin) {
		saveURL();
		return <Navigate to={ERoutes.Dashboard} />;
	}

	if (currentUser?.initialLogin && redirectURL?.includes('documents/')) {
		dispatch(onRemoveRedirectURL({}));
	}

	if (error) {
		return (
			<MainSidebarContainer>
				<Alert severity="error" variant="filled">
					{error}
				</Alert>
			</MainSidebarContainer>
		);
	}

	return (
		<MainSidebarContainer>
			<Grid
				container
				direction={tablet ? 'row-reverse' : 'row'}
				spacing={0}
				justifyContent="flex-end"
				wrap="nowrap"
				sx={{
					height: `${mobile ? 'calc(100vh - 85px)' : 'calc(100vh - 65px)'}`,
					padding: `${tablet ? '5px' : '0px 0px 0px 65px'}`,
				}}
			>
				<Grid item sm={2} sx={{ height: '100%', padding: '5px' }}>
					<DocumentMetadata document={document} />
				</Grid>
				<Grid item sm={tablet ? 10 : 8} sx={{ height: '100%', padding: '5px' }}>
					<Preview document={document} />
				</Grid>
				{tablet ? (
					<></>
				) : (
					<Grid item sm={2} sx={{ height: '100%', padding: '5px' }}>
						<BackToDocuments />
					</Grid>
				)}
			</Grid>
		</MainSidebarContainer>
	);
};

export default SpecificDocument;
