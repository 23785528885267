import {
	Box,
	Dialog,
	DialogTitle,
	DialogActions,
	TextField,
} from '@mui/material';
import { FC, useState } from 'react';
import { useUpdateSponsorMutation } from 'api/redux/services/organizations.service';
import { Sponsor } from 'app/types/ui/organization.types';
import { BrandedButton } from 'common/components/BrandedButton';

interface IEditSponsorProps {
	open: boolean;
	onClose: () => void;
	sponsor: Sponsor;
}

export const EditSponsor: FC<IEditSponsorProps> = ({
	open,
	onClose,
	sponsor,
}) => {
	const [updateSponsor] = useUpdateSponsorMutation();
	const [sponsorName, setSponsorName] = useState<string>(sponsor.name);

	const handleSubmit = async () => {
		await updateSponsor({
			name: sponsorName,
			sponsorId: sponsor.id,
		});
		onClose();
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Update Sponsor</DialogTitle>
			<Box sx={{ pl: 3, pr: 3, pb: 3 }}>
				<TextField
					label="Sponsor Name"
					variant="outlined"
					fullWidth
					inputProps={{
						style: {
							borderBottom: 'none',
							paddingLeft: 20,
						},
					}}
					value={sponsorName}
					onChange={(e) => setSponsorName(e.target.value)}
				/>
			</Box>
			<DialogActions>
				<BrandedButton onClick={onClose} variant="outlined">
					Cancel
				</BrandedButton>
				<BrandedButton variant="contained" onClick={handleSubmit}>
					Save
				</BrandedButton>
			</DialogActions>
		</Dialog>
	);
};
