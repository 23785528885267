import { ROLES } from './Roles';
import { SuperAdminPermissions } from './by-role/SuperAdminPermissions';
import { SponsorAdminPermissions } from './by-role/SponsorAdminPermissions';
import { InvestorAdminPermissions } from './by-role/InvestorAdminPermissions';
import { SponsorUserPermissions } from './by-role/SponsorUserPermissions';
import { SCOPES } from './Scopes';

interface IPermissionsType {
	[ROLES.SUPER_ADMIN]: SCOPES[];
	[ROLES.SPONSOR_ADMIN]: SCOPES[];
	[ROLES.INVESTOR_ADMIN]: SCOPES[];
	[ROLES.SPONSOR_USER]: SCOPES[];
	[ROLES.INVESTOR_USER]: SCOPES[];
}

export const PERMISSIONS: IPermissionsType = {
	[ROLES.SUPER_ADMIN]: SuperAdminPermissions,
	[ROLES.SPONSOR_ADMIN]: SponsorAdminPermissions,
	[ROLES.INVESTOR_ADMIN]: InvestorAdminPermissions,
	[ROLES.SPONSOR_USER]: SponsorUserPermissions,
	[ROLES.INVESTOR_USER]: [],
};
