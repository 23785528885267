import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { RootState } from './store';
import {
	documentLevels,
	EDocumentLevels,
	IDocumentLevel,
	INotificationDelay,
	notificationDelays,
} from 'modules/admin/UploadDocuments/types';
import { DocumentType } from 'app/types/documentTypes.types';

interface IDocsUploadState {
	selectedLevel: IDocumentLevel;
	selectedDocType?: DocumentType;
	selectedDocDate?: string;
	dateDialogOpen: boolean;
	fundDialogOpen: boolean;
	dateConfirmed: boolean;
	fundLevelConfirmed: boolean;
	notifyInvestors: boolean;
	errorMessage?: string;
	successMessage?: string;
	warningMessage?: string;
	notificationDelay: INotificationDelay;
	emailTemplateId?: number;
}

const initialState: IDocsUploadState = {
	selectedLevel: documentLevels[1],
	selectedDocType: undefined,
	selectedDocDate: undefined,
	dateDialogOpen: false,
	fundDialogOpen: false,
	dateConfirmed: false,
	fundLevelConfirmed: false,
	notifyInvestors: false,
	notificationDelay: notificationDelays[0],
	emailTemplateId: undefined,
};

export const docsUploadSlice: Slice = createSlice({
	name: 'docsUploadState',
	initialState,
	reducers: {
		onResetUploadState: (state) => {
			state.dateDialogOpen = false;
			state.fundDialogOpen = false;
			state.fundLevelConfirmed = false;
			state.notifyInvestors = false;
			state.notificationDelay = notificationDelays[0];
			state.errorMessage = undefined;
			state.warningMessage = undefined;
			state.selectedDocType = undefined;
			state.selectedDocDate = undefined;
			state.emailTemplateId = undefined;
		},
		onSetDocumentLevel: (state, action: PayloadAction<IDocumentLevel>) => {
			if (
				(action.payload.name as EDocumentLevels) === EDocumentLevels.FUND_LEVEL
			) {
				state.warningMessage = true;
			} else {
				state.warningMessage = false;
			}
			state.selectedLevel = action.payload;
		},
		onSetDocumentType: (state, action: PayloadAction<DocumentType>) => {
			state.selectedDocType = action.payload;
		},
		onSetDate: (state, action: PayloadAction<Date>) => {
			state.selectedDocDate = action.payload;
		},
		onSetDateDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.dateDialogOpen = action.payload;
		},
		onSetFundDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.fundDialogOpen = action.payload;
		},
		onSetDateConfirmed: (state, action: PayloadAction<boolean>) => {
			state.dateConfirmed = action.payload;
		},
		onSetFundLevelConfirmed: (state, action: PayloadAction<boolean>) => {
			state.fundLevelConfirmed = action.payload;
		},
		onSetNotifyInvestorUsers: (state, action: PayloadAction<boolean>) => {
			state.notifyInvestors = action.payload;
		},
		onSetErrorMessage: (state, action: PayloadAction<string>) => {
			state.errorMessage = action.payload;
		},
		onSetSuccessMessage: (state, action: PayloadAction<string>) => {
			state.successMessage = action.payload;
		},
		onSetWarningMessage: (state, action: PayloadAction<boolean>) => {
			state.warningMessage = action.payload;
		},
		onSetNotificationDelay: (
			state,
			action: PayloadAction<INotificationDelay>,
		) => {
			state.notificationDelay = action.payload;
		},
		onSetEmailTemplateId: (state, action: PayloadAction<number>) => {
			state.emailTemplateId = action.payload;
		},
	},
});
export const {
	onResetUploadState,
	onSetDocumentLevel,
	onSetDocumentType,
	onSetDate,
	onSetDateDialogOpen,
	onSetFundDialogOpen,
	onSetDateConfirmed,
	onSetFundLevelConfirmed,
	onSetNotifyInvestorUsers,
	onSetErrorMessage,
	onSetSuccessMessage,
	onSetWarningMessage,
	onSetNotificationDelay,
	onSetEmailTemplateId,
} = docsUploadSlice.actions;

export const docsUploadState = ({ docsUpload }: RootState): IDocsUploadState =>
	docsUpload;

export default docsUploadSlice.reducer;
