/* eslint-disable no-control-regex */
const EMAIL_REGEXP =
	/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const PHONE_REGEXP = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
export const VALID_PHONE_FORMATS = `
(123) 456-7890,
(123)456-7890,
123-456-7890,
123.456.7890,
1234567890,
+31636363634,
075-63546725
`;

export const isEmail = (email: string): boolean => {
	const evaluation = email.match(EMAIL_REGEXP);

	return evaluation !== null;
};

export const isValidUSPhone = (phone: string): boolean => {
	const evaluation = phone.match(PHONE_REGEXP);

	return evaluation !== null;
};
