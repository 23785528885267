import { Box } from '@mui/material';
import { FC } from 'react';

interface ITabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

export const TabPanel: FC<ITabPanelProps> = ({
	children,
	value,
	index,
	...other
}) => {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
};
