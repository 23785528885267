import { EmailTemplate } from 'app/types/email.template.types';
import { Dispatch, SetStateAction } from 'react';

export interface ICreateEmailTemplateButtonProps {
	setCreateEmailTemplate: Dispatch<SetStateAction<boolean>>;
}

export enum ColHeaders {
	Name = 'Name',
	LastEdited = 'Last Edited',
	LastEditedBy = 'Last Edited By',
	LastUsed = 'Last Used',
	LastUsedBy = 'Last Used By',
	Actions = '',
}

export interface IColumn {
	Header: ColHeaders;
	accessor: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Cell: ({ row }: { row: any }) => JSX.Element | null;
	isVisible?: boolean;
	width?: number;
	minWidth?: number;
}

export interface IEmailTemplatesListProps {
	refetchEmailTemplates: () => void;
	columns: IColumn[];
	createEmailTemplate: boolean;
	setCreateEmailTemplate: Dispatch<SetStateAction<boolean>>;
	onEmailTemplateRowClick: (emailTemplate: EmailTemplate) => void;
	editEmailTemplate: boolean;
	setEditEmailTemplate: Dispatch<SetStateAction<boolean>>;
	setSelectedEmailTemplateId: Dispatch<SetStateAction<number | undefined>>;
	selectedEmailTemplate: EmailTemplate | undefined;
	emailTemplates: EmailTemplate[];
	selectedEmailTemplateId: number | undefined;
	deleteEmailTemplate: boolean;
	setDeleteEmailTemplate: Dispatch<SetStateAction<boolean>>;
	setPage: Dispatch<SetStateAction<number>>;
	pages: number;
	loading: boolean;
	searchString: string;
	setSearchString: Dispatch<SetStateAction<string>>;
}

export enum DefaultTemplates {
	DOCUMENTS = 'Documents',
	STATEMENTS = 'Statements',
}
