import { createContext, useContext, useState, ReactNode, FC } from 'react';
import { IFile } from './types';

interface FileContextType {
	uploadedFiles: IFile[];
	addUploadedFiles: (file: IFile[]) => void;
	removeUploadedFile: (index: number) => void;
	removeAllFiles: () => void;
	setInvestorId: (index: number, investorId: string) => void;
	updateUploadedFileDate: (index: number, date: string) => void;
}

const FileContext = createContext<FileContextType | undefined>(undefined);

export const FileProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);

	const addUploadedFiles = (files: IFile[]) => {
		setUploadedFiles([...uploadedFiles, ...files]);
	};

	const removeUploadedFile = (index: number) => {
		setUploadedFiles(uploadedFiles.filter((_, i) => i !== index));
	};

	const removeAllFiles = () => {
		setUploadedFiles([]);
	};

	const setInvestorId = (index: number, investorId: string) => {
		setUploadedFiles(
			uploadedFiles.map((file, i) =>
				i === index ? { ...file, investor: investorId, guessed: false } : file,
			),
		);
	};

	const updateUploadedFileDate = (index: number, date: string) => {
		setUploadedFiles(
			uploadedFiles.map((file, i) =>
				i === index ? { ...file, date: date } : file,
			),
		);
	};

	return (
		<FileContext.Provider
			value={{
				uploadedFiles,
				addUploadedFiles,
				removeUploadedFile,
				removeAllFiles,
				setInvestorId,
				updateUploadedFileDate,
			}}
		>
			{children}
		</FileContext.Provider>
	);
};

export const useFileContext = () => {
	const context = useContext(FileContext);

	if (!context) {
		throw new Error('useFileContext must be used within a FileProvider');
	}

	return context;
};
