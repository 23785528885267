import { FC, useEffect, useState } from 'react';
import { IEditBoxProps } from './types';
import {
	Box,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Paper,
	Typography,
} from '@mui/material';
import {
	useLazyGetWatermarkDocTypesQuery,
	useUpdateWaterMarkCasPdfMutation,
	useUpdateWaterMarkPdfMutation,
	useUpdateWatermarkDocTypesMutation,
} from 'api/redux/services/organizations.service';
import { useLazyGetDocumentTypesQuery } from 'api/redux/services/documentTypes.service';
import { DocumentType } from 'app/types/documentTypes.types';
import { PopUpAlert } from 'common/components/PopUpAlert';
import { BrandedButton } from 'common/components/BrandedButton';
import { styled } from '@mui/system';

const Wrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-end',
	gap: '1em',
});

const Container = styled(Box)({
	marginLeft: 'auto',
	marginRight: 'auto',
	marginTop: 15,
	marginBottom: 15,
	padding: 15,
});

const ButtonContainer = styled(Box)({
	marginTop: 15,
	marginRight: 10,
	display: 'flex',
	justifyContent: 'flex-end',
});

const InputWrapper = styled(Paper)({
	flex: '1 0 auto',
	height: '20rem',
	overflow: 'auto',
	padding: 5,
	margin: 10,
	border: '1px solid #e3e3e3',
});

const FormWrapper = styled(FormGroup)({
	height: '100%',
});

export const EditBox: FC<IEditBoxProps> = ({
	show,
	sponsor,
	refetchOrg,
	onCancelClick,
}) => {
	const [waterMarkPdfAllowed, setWatermarkPdfAllowed] = useState<boolean>(
		sponsor.watermarkPdf,
	);
	const [waterMarkCasPdfAllowed, setWatermarkCasPdfAllowed] = useState<boolean>(
		sponsor.watermarkCasPdf,
	);
	const [allowedDocTypes, setAllowedDocTypes] = useState<DocumentType[]>([]);
	const [showSuccess, setShowSuccess] = useState<boolean>(false);
	const [getAllDoctypes, allDocTypes] = useLazyGetDocumentTypesQuery();
	const [getWaterMarkDocTypes, watermarkDocTypes] =
		useLazyGetWatermarkDocTypesQuery();
	const [updateWatermarkPdf, updateWaterMarkPdfResponse] =
		useUpdateWaterMarkPdfMutation();
	const [updateWatermarkCasPdf, updateWaterMarkCasPdfResponse] =
		useUpdateWaterMarkCasPdfMutation();
	const [updateWatemarkDocTypes, updateWatemarkDocTypesResponse] =
		useUpdateWatermarkDocTypesMutation();

	useEffect(() => {
		if (updateWatemarkDocTypesResponse.status === 'uninitialized') return;
		refetchOrg();
	}, [updateWatemarkDocTypesResponse]);

	useEffect(() => {
		if (updateWaterMarkCasPdfResponse.status === 'uninitialized') return;
		refetchOrg();
	}, [updateWaterMarkCasPdfResponse]);

	useEffect(() => {
		if (updateWaterMarkPdfResponse.status === 'uninitialized') return;
		refetchOrg();
	}, [updateWaterMarkPdfResponse]);

	useEffect(() => {
		getAllDoctypes();
		getWaterMarkDocTypes(sponsor.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!watermarkDocTypes.data) return;
		setAllowedDocTypes(watermarkDocTypes.data);
	}, [watermarkDocTypes.data]);

	useEffect(() => {
		if (
			updateWaterMarkPdfResponse.status === 'fulfilled' &&
			updateWaterMarkCasPdfResponse.status === 'fulfilled' &&
			updateWatemarkDocTypesResponse.status === 'fulfilled'
		) {
			setShowSuccess(true);
		}
	}, [
		updateWaterMarkPdfResponse,
		updateWaterMarkCasPdfResponse,
		updateWatemarkDocTypesResponse,
	]);

	const handleSave = () => {
		updateWatermarkPdf({
			sponsorId: sponsor.id,
			watermarkPdf: waterMarkPdfAllowed,
		});
		updateWatermarkCasPdf({
			sponsorId: sponsor.id,
			watermarkCasPdf: waterMarkCasPdfAllowed,
		});
		updateWatemarkDocTypes({
			sponsorId: sponsor.id,
			waterMarkDocTypes: allowedDocTypes.map((dt) => dt.id),
		});
	};

	const handleChange = (doctTypeId: number) => {
		if (allowedDocTypes.map((dt) => dt.id).includes(doctTypeId)) {
			setAllowedDocTypes(allowedDocTypes.filter((dt) => dt.id !== doctTypeId));
			return;
		}

		const newDocType = allDocTypes.data?.find((dt) => dt.id === doctTypeId);
		if (newDocType) setAllowedDocTypes([...allowedDocTypes, newDocType]);
	};

	if (!show) return <></>;

	return (
		<>
			<Container>
				<Wrapper>
					<Typography
						variant={'h4'}
						style={{ whiteSpace: 'nowrap', marginBottom: '9px' }}
					>
						{"Watermark PDF's"}
					</Typography>
				</Wrapper>
				<Wrapper>
					<FormControlLabel
						label={'Investor and Fund Documents'}
						id="investor-n-fund-docs-label"
						style={{
							padding: '10px 0px 0px 5px',
						}}
						control={
							<Checkbox
								checked={Boolean(waterMarkPdfAllowed)}
								aria-labelledby="investor-n-fund-docs-label"
								onChange={() => {
									setWatermarkPdfAllowed(!waterMarkPdfAllowed);
								}}
							/>
						}
					/>
				</Wrapper>
				<Wrapper>
					<FormControlLabel
						label={'Capital Account Statements'}
						id="cas-label"
						style={{
							padding: '10px 0px 0px 5px',
						}}
						control={
							<Checkbox
								checked={Boolean(waterMarkCasPdfAllowed)}
								aria-labelledby="cas-label"
								onChange={() => {
									setWatermarkCasPdfAllowed(!waterMarkCasPdfAllowed);
								}}
							/>
						}
					/>
				</Wrapper>
				<Box>
					<InputWrapper elevation={0}>
						<FormWrapper>
							{allDocTypes.data &&
								allDocTypes.data.map((docType, i) => {
									return (
										<FormControlLabel
											key={i}
											style={{ fontSize: '12px' }}
											control={
												<Checkbox
													size="small"
													disabled={!waterMarkPdfAllowed}
													onChange={() => handleChange(docType.id)}
													checked={
														allowedDocTypes
															?.map((dt) => dt.id)
															.includes(docType.id) || false
													}
												/>
											}
											label={<Typography>{docType.name}</Typography>}
										></FormControlLabel>
									);
								})}
						</FormWrapper>
					</InputWrapper>
				</Box>
				<ButtonContainer>
					<BrandedButton variant="outlined" onClick={onCancelClick}>
						Cancel
					</BrandedButton>
					<BrandedButton variant="contained" onClick={handleSave}>
						Save
					</BrandedButton>
				</ButtonContainer>
			</Container>
			<PopUpAlert
				show={showSuccess}
				onClose={() => setShowSuccess(false)}
				severity="success"
				message="Changes saved!"
			/>
		</>
	);
};

export default EditBox;
