import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Paper } from '@mui/material';
import { useGetOrganizationQuery } from 'api/redux/services/organizations.service';
import MUIDataTable, {
	MUIDataTableColumnDef,
	MUIDataTableOptions,
} from 'mui-datatables';
import { useNavigate } from 'react-router';
import { CreateFund } from './dialogs/CreateFund';
import AdminBreadcrumbs from 'common/components/AdminBreadCrumbs';
import CreateAnnouncement from 'modules/admin/CreateAnnouncement';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { EditButton } from 'common/components/EditButton';
import { DeleteButton } from 'common/components/DeleteButton';
import { EditFund } from './dialogs/EditFund';
import UpdateHeaderColor from 'modules/admin/UpdateHeaderColor';
import PermissionsWrapper, {
	hasPermission,
} from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { DeleteFundModal } from './dialogs/DeleteFundModal';
import { Fund } from 'app/types/ui/fund.types';
import { UploadLogo } from 'modules/admin/UploadLogo/UploadLogo';
import UpdateDisclaimer from 'modules/admin/UpdateDisclaimer/UpdateDisclaimer';
import { selectNavbarTablet } from 'api/redux/NavBarStateReducer';
import { UpdateWaterMarkPDF } from 'modules/admin/UpdateWaterMarkPdf/UpdateWaterMarkPdf';
import {
	onChangeFund,
	onChangeSponsor,
	selectDropdown,
} from 'api/redux/DropdownReducer';
import {
	useGetFundsQuery,
	useLazyDeleteFundQuery,
} from 'api/redux/services/fundApi';
import Subdomain from 'modules/admin/Subdomain';
import { BrandedButton } from 'common/components/BrandedButton';

interface IOrganizationPageProps {}

export const OrganizationPage: FC<IOrganizationPageProps> = () => {
	const tablet = useSelector(selectNavbarTablet);
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;
	const { data: selectedOrg, refetch: refetchOrg } = useGetOrganizationQuery(
		currentSponsor.id,
	);
	const { data: funds = [], refetch } = useGetFundsQuery(currentSponsor.id);
	const [deleteFund, data] = useLazyDeleteFundQuery();
	const navigate = useNavigate();
	const [selectedFund, setSelectedFund] = useState<Fund>({ id: 0, name: '' });
	const [createOpen, setCreateOpen] = useState<boolean>(false);
	const [editOpen, setEditOpen] = useState<boolean>(false);
	const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { availableFunds } = grants.grants;
	const onRowClick: MUIDataTableOptions['onRowClick'] = (rowData) => {
		navigate('/superadmin/sponsors/funds');
		const fund = availableFunds.find((f) => f.id === parseInt(rowData[0]));
		dispatch(onChangeFund(fund));
	};

	useEffect(() => {
		refetch();
	}, [data, editOpen, refetch]);

	useEffect(() => {
		if (selectedOrg) dispatch(onChangeSponsor(selectedOrg));
	}, [dispatch, selectedOrg]);

	const columns: MUIDataTableColumnDef[] = [
		{ name: 'id', options: { display: false, filter: false } },
		{
			name: 'name',
			label: 'Fund Name',
			options: { setCellProps: () => ({ style: { minWidth: '80%' } }) },
		},
		{
			name: 'actions',
			options: {
				customBodyRender: (value, tableMeta) => (
					<div style={{ float: 'right' }}>
						<PermissionsWrapper scopes={[SCOPES.canEditFund]}>
							<EditButton onClick={(e) => onEdit(tableMeta, e)} />
						</PermissionsWrapper>
						<PermissionsWrapper scopes={[SCOPES.canDeleteFund]}>
							<DeleteButton onClick={(e) => onDelete(tableMeta, e)} />
						</PermissionsWrapper>
					</div>
				),
				customHeadRender: () => null,
			},
		},
	];

	const onEdit = (value, e) => {
		e.stopPropagation();
		setSelectedFund({
			id: value.rowData[0],
			name: value.rowData[1],
		});
		setEditOpen(true);
	};

	const onDelete = (value, e) => {
		e.stopPropagation();
		setSelectedFund({
			id: value.rowData[0],
			name: value.rowData[1],
		});
		setDeleteOpen(true);
	};

	const handleDeleteConfirm = async () => {
		await deleteFund(selectedFund.id);
		setDeleteOpen(false);
	};

	const handleDeleteClose = () => {
		setDeleteOpen(false);
	};

	const options: MUIDataTableOptions = {
		onRowClick,
		elevation: 0,
		filter: false,
		pagination: false,
		print: false,
		selectableRows: 'none',
		viewColumns: false,
		download: hasPermission({ scopes: [SCOPES.canDownloadFund] }),
		search: hasPermission({ scopes: [SCOPES.canSearchFund] }),
	};

	const FundsTable: FC = () => {
		return (
			<Paper sx={{ padding: 4 }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography variant="h5">Fund</Typography>
					<PermissionsWrapper scopes={[SCOPES.canCreateFund]}>
						<BrandedButton
							variant="contained"
							onClick={() => setCreateOpen(true)}
						>
							Create Fund
						</BrandedButton>
					</PermissionsWrapper>
				</Box>
				<MUIDataTable
					title=""
					columns={columns}
					data={funds}
					options={options}
				/>
				{createOpen && (
					<CreateFund open={createOpen} onClose={() => setCreateOpen(false)} />
				)}
				{editOpen && (
					<EditFund fund={selectedFund} onClose={() => setEditOpen(false)} />
				)}
				{deleteOpen && (
					<DeleteFundModal
						open={deleteOpen}
						fund={selectedFund}
						handleSubmit={handleDeleteConfirm}
						handleClose={handleDeleteClose}
					/>
				)}
			</Paper>
		);
	};

	return (
		<MainSidebarContainer>
			<Box
				sx={{
					height: '100%',
					width: 'min(1200px, 100%)',
					margin: '5px',
					paddingLeft: `${tablet ? '0px' : '55px'}`,
				}}
			>
				{selectedOrg && (
					<Box
						sx={{
							width: '100%',
							display: 'inline-block',
							marginBottom: '10px',
						}}
					>
						<AdminBreadcrumbs
							list={[
								{ text: 'Admin', link: '/admin' },
								{
									text: selectedOrg.name,
								},
							]}
						/>
						<FundsTable />

						<Subdomain sponsor={selectedOrg}></Subdomain>

						<PermissionsWrapper scopes={[SCOPES.canUploadLogo]}>
							<UploadLogo sponsor={selectedOrg} />
						</PermissionsWrapper>

						<PermissionsWrapper scopes={[SCOPES.canChangeHeader]}>
							<UpdateHeaderColor sponsor={selectedOrg}></UpdateHeaderColor>
						</PermissionsWrapper>

						<PermissionsWrapper scopes={[SCOPES.canEditDisclaimer]}>
							<UpdateDisclaimer sponsor={selectedOrg} />
						</PermissionsWrapper>

						<PermissionsWrapper scopes={[SCOPES.canUpdateWaterMarkPdf]}>
							<UpdateWaterMarkPDF
								sponsor={selectedOrg}
								refetchOrg={refetchOrg}
							/>
						</PermissionsWrapper>
					</Box>
				)}
			</Box>
		</MainSidebarContainer>
	);
};
