import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { BrandedButton } from 'common/components/BrandedButton';
import { FC } from 'react';

interface IDateDialog {
	open: boolean;
	handleClose: () => void;
	handleCloseDisagree: () => void;
}

export const DateDialog: FC<IDateDialog> = ({
	open,
	handleClose,
	handleCloseDisagree,
}) => {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{'Use todays date?'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					You have selected todays date, are you sure you don&apos;t want to
					select a different date?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<BrandedButton onClick={handleCloseDisagree}>Change date</BrandedButton>
				<BrandedButton onClick={handleClose} variant="contained">
					I&apos;m sure
				</BrandedButton>
			</DialogActions>
		</Dialog>
	);
};
