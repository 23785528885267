import { FC, useState } from 'react';
import { IOptionsProps } from '../types';
import { NonEditableUserRow } from './NonEditableUserRow';
import { EditableUserRow } from './EditableUserRow';

export const UserRow: FC<IOptionsProps> = ({
	onClose,
	user,
	handleReload,
	selectedInvestorId,
}) => {
	const [editing, setEditing] = useState<boolean>(false);

	if (!editing)
		return (
			<NonEditableUserRow user={user} onEditclick={() => setEditing(true)} />
		);

	return (
		<EditableUserRow
			user={user}
			onCancelClick={() => {
				setEditing(false);
			}}
			selectedInvestorId={selectedInvestorId}
			handleReload={handleReload}
			afterSave={() => setEditing(false)}
			onClose={(edit) => onClose(edit)}
		/>
	);
};
