import { IExportUsersByRelationShipColumns } from 'api/redux/services/organizations.service';
import { ROLES } from 'common/helpers/permissions/Roles';

export interface IExportModeProps {
	value: ExportModes;
	handleChange: (mode: ExportModes) => void;
}

export enum ExportModes {
	BY_RELATIONSHIP = 'Export By Relationship',
	BY_USER = 'Export By User',
}

export interface IExportByRelationshipProps {
	visible: boolean;
}

export interface IExportByUserProps {
	visible: boolean;
}

export interface IRolesListProps {
	selectedRoles: ROLES[];
	setSelectedRoles: (roles: ROLES[]) => void;
}

export interface IFundsListProps {
	selectedFundIds: number[];
	setSelectedFundIds: (fundIds: number[]) => void;
}

export interface IColumnsListProps {
	selectedColumns: string[];
	onColumnsChange: (columns: string[]) => void;
	availableColumns: string[];
}

export interface IExportButtonProps {
	exportDisabled: boolean;
	onExportClick: () => void;
}
