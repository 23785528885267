import { FC } from 'react';
import { Box, TableContainer, TableHead, TableBody } from '@mui/material';
import { Table, TableCell, TableRow } from '../styles';
import { Alert } from '@mui/material';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useSelector } from 'react-redux';
import {
	borderBottomSx,
	getInvestorColumns,
	getFundColumns,
	getCellData,
} from '../utils';
import {
	IRow,
	IColumn,
	ICapitalAccountStatementTableProps,
	TemplateTypesToColSpan,
} from '../types';
import {
	ColumnLabels,
	ColumnSubLabel,
	RowLabel,
	TablePaper,
} from '../CASTable/styles';

export const CapitalAccountStatementTable: FC<ICapitalAccountStatementTableProps> =
	({ data, fundData }) => {
		const sponsorColor = useSelector(selectSponsorPrimaryColor);
		const { capitalActivityTemplate, attributeData, reportingPeriod } =
			data || {};
		const getRowLabel = (formats, text) => {
			const labels = {
				['Contributions']: () =>
					formats.includes('INDENT') ? 'Total Contributions' : text,
				['Remaining Unfunded Commitment']: () => 'Remaining Commitment',
				['Contributions Receivable']: () =>
					'Contributions Receivable / (Prepaid Contributions)',
				default: () => text,
			};
			return (labels[text] || labels['default'])();
		};

		if (!capitalActivityTemplate)
			return (
				<Alert severity="error" variant="filled">
					Error loading Capital Account Statement template.
				</Alert>
			);

		if (!attributeData)
			return (
				<Alert severity="error" variant="filled">
					Error loading your Capital Account Statement.
				</Alert>
			);

		const columns = [
			...getInvestorColumns(capitalActivityTemplate),
			...getFundColumns(capitalActivityTemplate),
		];

		return (
			<TablePaper elevation={0}>
				<TableContainer style={{ height: '100%' }}>
					<Table size="small" style={{ height: '100%' }}>
						<TableHead>
							<TableRow
								style={{
									backgroundColor: `${sponsorColor}`,
								}}
							>
								<TableCell></TableCell>
								{columns.map(
									(column: IColumn, i) =>
										column.title && (
											<TableCell key={i}>
												<Box sx={{ padding: '10px' }}>
													<ColumnLabels key={`${column.title[1].text}-1`}>
														{column.title[1].text.replace(
															'{{REPORTING_PERIOD}}',
															'',
														)}
													</ColumnLabels>
													<ColumnLabels key={`${column.title[1].text}-2`}>
														{reportingPeriod?.replace('Period ending ', '')}
													</ColumnLabels>
												</Box>
											</TableCell>
										),
								)}
							</TableRow>
							<TableRow
								style={{
									backgroundColor: '#FFFFFF',
								}}
							>
								<TableCell
									style={{
										color: '#000000',
										borderBottom: `1px solid #D0D7DE`,
									}}
								></TableCell>
								<TableCell
									style={{
										color: '#000000',
										borderBottom: `1px solid #D0D7DE`,
									}}
									colSpan={
										TemplateTypesToColSpan[capitalActivityTemplate.templateId]
									}
								>
									<ColumnSubLabel style={{ color: '#000000' }}>
										Investor Level
									</ColumnSubLabel>
								</TableCell>
								<TableCell
									colSpan={
										TemplateTypesToColSpan[capitalActivityTemplate.templateId]
									}
									style={{
										color: '#000000',
										borderBottom: `1px solid #D0D7DE`,
									}}
								>
									<ColumnSubLabel style={{ color: '#000000' }}>
										Fund Level
									</ColumnSubLabel>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className="cas-table-wrapper">
							{capitalActivityTemplate.rows.map((row: IRow, i) => {
								return (
									<TableRow
										key={i}
										className={
											row.valueFormats.length
												? 'cas-row content-row'
												: 'cas-row blank-row'
										}
									>
										<TableCell className="cas-cell">
											<Box>
												<RowLabel
													style={{
														whiteSpace: 'nowrap',
														fontWeight: row.label.formats.includes('BOLD')
															? 'bold'
															: 'normal',
														paddingLeft: row.label.formats.includes('INDENT')
															? 10
															: 0,
													}}
												>
													{getRowLabel(row.label.formats, row.label.text)}
												</RowLabel>
											</Box>
										</TableCell>
										{columns.map((column: IColumn, j) => (
											<TableCell
												key={j}
												style={{
													textAlign: 'right',
													fontWeight: row.label.formats.includes('BOLD')
														? 'bold'
														: 'normal',
												}}
												className="cas-cell"
											>
												<Box
													sx={{
														py: 0.5,
														mx: 3,
														borderBottom: borderBottomSx(
															row,
															column,
															capitalActivityTemplate.templateId,
															sponsorColor,
														),
														fontSize: '14px',
													}}
												>
													{getCellData(
														row,
														column,
														capitalActivityTemplate.templateId,
														attributeData,
														fundData,
													)}
												</Box>
											</TableCell>
										))}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</TablePaper>
		);
	};
