import { Box, InputLabel, MenuItem, Select, styled } from '@mui/material';
import {
	docsUploadState,
	onSetEmailTemplateId,
} from 'api/redux/DocsUploadReducer';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useGetEmailTemplatesQuery } from 'api/redux/services/email.templates.service';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const StyledLabel = styled(InputLabel)(() => ({
	margin: 10,
	display: 'inline !important',
}));

interface IEmailTemplateSelectorProps {
	show?: boolean;
	defaultValue?: string;
}

export const EmailTemplateSelector: FC<IEmailTemplateSelectorProps> = ({
	show = true,
	defaultValue,
}) => {
	const grants = useSelector(selectDropdown);
	const currentSponsorId = grants.grants.currentSponsor.id;
	const { data: emailTemplates } = useGetEmailTemplatesQuery({
		sponsorId: currentSponsorId,
	});
	const { emailTemplateId } = useSelector(docsUploadState);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!emailTemplates || emailTemplates.length === 0) return;

		let defaultEmailTemplateId = emailTemplates[0].id;
		if (defaultValue) {
			const defaultEmailTemplate = emailTemplates.find(
				(emailTemplate) => emailTemplate.name === defaultValue,
			);
			if (defaultEmailTemplate) {
				defaultEmailTemplateId = defaultEmailTemplate.id;
			}
		}

		dispatch(onSetEmailTemplateId(defaultEmailTemplateId));
	}, [emailTemplates]);

	if (!emailTemplates || !show) return null;

	return (
		<Box>
			<StyledLabel>Use Email Template:</StyledLabel>
			<Select
				id="email-template-select"
				value={emailTemplateId}
				onChange={(e) => {
					dispatch(onSetEmailTemplateId(e.target.value));
				}}
				sx={{ minWidth: '170px' }}
			>
				{emailTemplates.map((emailTemplate) => (
					<MenuItem key={emailTemplate.id} value={emailTemplate.id.toString()}>
						{emailTemplate.name}
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};
