import { createSlice, Slice } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface ISiteColorState {
	navbarCollapsed: boolean;
	navBarMobile: boolean;
	navBarTablet: boolean;
	screenWidth: number;
}

const initialState: ISiteColorState = {
	navbarCollapsed: true,
	navBarMobile: false,
	navBarTablet: false,
	screenWidth: window.innerWidth,
};

export const navbarCollapsedSlice: Slice = createSlice({
	name: 'navbarState',
	initialState,
	reducers: {
		onNavbarCollapse: (state, action) => {
			state.navbarCollapsed = action.payload;
		},
		onNavbarMobile: (state, action) => {
			state.navBarMobile = action.payload;
		},
		onNavbarTablet: (state, action) => {
			state.navBarTablet = action.payload;
		},
		onScreenWidthChange: (state, action) => {
			state.screenWidth = action.payload;
		},
	},
});

export const { onNavbarCollapse } = navbarCollapsedSlice.actions;
export const { onNavbarMobile } = navbarCollapsedSlice.actions;
export const { onNavbarTablet } = navbarCollapsedSlice.actions;
export const { onScreenWidthChange } = navbarCollapsedSlice.actions;

export const selectNavbarCollapsed = ({ navbarState }: RootState): boolean =>
	navbarState.navbarCollapsed;

export const selectNavbarMobile = ({ navbarState }: RootState): boolean =>
	navbarState.navBarMobile;

export const selectNavbarTablet = ({ navbarState }: RootState): boolean =>
	navbarState.navBarTablet;

export const selectScreenWidth = ({ navbarState }: RootState): number =>
	navbarState.screenWidth;

export default navbarCollapsedSlice.reducer;
