import { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	TextField,
	Box,
} from '@mui/material';
import handleEnterPressForDialogs from 'common/helperFunctions/ui/handleEnterPressForDialogs';
import { EmailTemplate } from 'app/types/email.template.types';
import { useDeleteEmailTemplateMutation } from 'api/redux/services/email.templates.service';
import { BrandedButton } from '../BrandedButton';

interface IDeleteEmailTemplateModalProps {
	isOpen: boolean;
	emailTemplate: EmailTemplate;
	afterDelete: () => void;
	afterClose: () => void;
}

export const DeleteEmailTemplateModal: React.FC<IDeleteEmailTemplateModalProps> =
	({ isOpen, emailTemplate, afterDelete, afterClose }) => {
		const [name, setName] = useState<string>('');
		const [confirmed, setConfirmed] = useState<boolean>(false);
		const [incorrectEntry, setIncorrectEntry] = useState<boolean>(false);
		const [isLoading, setIsLoading] = useState<boolean>(false);
		const [deleteEmailTemplate] = useDeleteEmailTemplateMutation();

		const handleConfirmed = () => {
			setConfirmed(true);
		};

		const checkNameAndHandleClose = async () => {
			setIsLoading(true);

			if (name === emailTemplate.name) {
				await handleSubmit();
				afterDelete();
			} else {
				setIncorrectEntry(true);
			}
		};

		const handleSubmit = async () => {
			setIsLoading(true);
			await deleteEmailTemplate(emailTemplate.id);
			setIsLoading(false);
			handleClose();
		};

		const handleClose = () => {
			setName('');
			setConfirmed(false);
			setIncorrectEntry(false);
			setIsLoading(false);
			afterClose();
		};

		return (
			<Dialog
				open={isOpen}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<strong>{'Confirm Deletion'}</strong>
				</DialogTitle>
				{emailTemplate && (
					<>
						<DialogContent sx={{ pt: 0, pb: 2 }}>
							<DialogContentText id="alert-dialog-description">
								Are you sure you want to permanently delete this template
								<strong>{' ' + emailTemplate.name}</strong>?
								<br />
								<i>This action cannot be undone.</i>
							</DialogContentText>
						</DialogContent>
						{!confirmed && (
							<DialogActions>
								<BrandedButton onClick={handleClose}>Cancel</BrandedButton>
								<BrandedButton variant="contained" onClick={handleConfirmed}>
									Confirm
								</BrandedButton>
							</DialogActions>
						)}
						{confirmed && (
							<>
								<DialogContent sx={{ pt: 0, pb: 2 }}>
									<DialogContentText id="alert-dialog-description">
										Please type the name of this template to confirm this
										deletion
									</DialogContentText>
								</DialogContent>
								{confirmed && incorrectEntry && (
									<DialogContent sx={{ pt: 0, pb: 2 }}>
										<DialogContentText id="alert-dialog-description">
											Sorry, that entry was incorrect! Please try again
										</DialogContentText>
									</DialogContent>
								)}
								<Box sx={{ pl: 3, pr: 3 }}>
									<TextField
										InputProps={{
											disableUnderline: true,
										}}
										autoFocus
										error={incorrectEntry}
										onKeyPress={(e) =>
											handleEnterPressForDialogs(e, checkNameAndHandleClose)
										}
										id="confirmed-secret-field"
										label={`Enter the name of your template`}
										fullWidth
										variant="standard"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</Box>
								<DialogActions sx={{ pb: 2, pt: 2 }}>
									<BrandedButton onClick={handleClose}>Cancel</BrandedButton>
									<BrandedButton
										type="submit"
										variant="contained"
										onClick={checkNameAndHandleClose}
										disabled={isLoading}
									>
										{isLoading ? 'Please wait...' : 'Delete'}
									</BrandedButton>
								</DialogActions>
							</>
						)}
					</>
				)}
				{!emailTemplate && (
					<>
						<DialogContent sx={{ pt: 0, pb: 2 }}>
							<DialogContentText id="alert-dialog-description">
								There was an error getting your template and it can&apos;t be
								deleted at this time.
								<br />
								Please try again later
							</DialogContentText>
						</DialogContent>
					</>
				)}
			</Dialog>
		);
	};
