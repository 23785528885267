import {
	Table as MuiTable,
	TableCell as MuiTableCell,
	TableRow as MuiTableRow,
} from '@mui/material';
import { styled } from '@mui/system';

export const Table = styled(MuiTable)({
	root: {
		borderCollapse: 'separate',
		'& tr.content-row:hover': {
			backgroundColor: '#eee',
		},
		'& .MuiTableCell-sizeSmall': {
			padding: '0px 10px 0px 10px',
		},
	},
});

export const TableCell = styled(MuiTableCell)({
	root: {
		borderBottom: 'none',
		fontSize: '14px',
		borderRadius: '0px',
	},
});

export const TableRow = styled(MuiTableRow)({
	root: {
		borderBottom: 'none',
		fontSize: '14px',
	},
});
