import Mention from '@tiptap/extension-mention';
import { Content, EditorProvider, Extension, JSONContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { FC } from 'react';
import Suggestions from './Suggestions';

const PreventEnter = Extension.create({
	addKeyboardShortcuts(this) {
		return {
			Enter: () => true,
		};
	},
});

const subjectSuggestions = Suggestions(['FUND', 'INVESTOR']);

// These extensions must be configured the same way in the backend. See email-templates.service.ts
const extensions = [
	StarterKit.configure({
		blockquote: false,
		bulletList: false,
		codeBlock: false,
		hardBreak: false,
		heading: false,
		horizontalRule: false,
		listItem: false,
		orderedList: false,
		bold: false,
		code: false,
		italic: false,
		strike: false,
		dropcursor: false,
		gapcursor: false,
		history: false,
	}),
	Mention.configure({
		HTMLAttributes: {
			class: 'mention',
		},
		suggestion: subjectSuggestions,
		renderText({ options, node }) {
			return `${node.attrs.label ?? node.attrs.id}`;
		},
		renderHTML({ options, node }) {
			return [
				'span',
				{
					class: 'mention',
				},
				`[${node.attrs.label ?? node.attrs.id}]`,
			];
		},
	}),
	PreventEnter,
];

interface IEditorProps {
	initialContent: Content;
	style: string;
	onChange: (json: JSONContent) => void;
	isPreview?: boolean;
}

const EmailTemplateSubjectEditor: FC<IEditorProps> = ({
	initialContent,
	style,
	onChange,
	isPreview = false,
}) => {
	return (
		<>
			<EditorProvider
				extensions={extensions}
				content={initialContent}
				editorProps={{
					attributes: {
						style: `outline: none; margin: 2px; ${style}`,
					},
				}}
				onUpdate={({ editor }) => {
					onChange(editor.getJSON());
				}}
				editable={!isPreview}
			></EditorProvider>
		</>
	);
};

export default EmailTemplateSubjectEditor;
