import { FC } from 'react';
import { IFundsListProps } from './types';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Typography,
} from '@mui/material';
import { OptionsColumnBox } from './styles';
import _ from 'lodash';
import { SelectAllOption } from 'common/components/SelectAllOption';
import { useGetFundsQuery } from 'api/redux/services/fundApi';

export const FundsList: FC<IFundsListProps> = ({
	selectedFundIds,
	setSelectedFundIds,
}) => {
	const grants = useSelector(selectDropdown);
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const { currentSponsor } = grants.grants;
	const { data: fundsList } = useGetFundsQuery(currentSponsor.id);

	return (
		<OptionsColumnBox>
			<FormGroup>
				<SelectAllOption
					selectedIds={selectedFundIds}
					setSelectedIds={setSelectedFundIds}
					availableIds={fundsList?.map((f) => f.id) || []}
				/>
				{fundsList &&
					fundsList.map((fund) => {
						return (
							<FormControlLabel
								key={fund.id}
								style={{ fontSize: '12px' }}
								control={
									<Checkbox
										size="small"
										onChange={() => {
											if (selectedFundIds.includes(fund.id)) {
												setSelectedFundIds(
													selectedFundIds.filter((fId) => fId !== fund.id),
												);
												return;
											}

											setSelectedFundIds([...selectedFundIds, fund.id]);
										}}
										checked={selectedFundIds.includes(fund.id) || false}
										style={{
											color: sponsorColor,
											padding: 3,
											marginLeft: 5,
										}}
									/>
								}
								label={<Typography>{fund.name}</Typography>}
							></FormControlLabel>
						);
					})}
			</FormGroup>
		</OptionsColumnBox>
	);
};
