import { styled } from '@mui/material/styles';
import Mention from '@tiptap/extension-mention';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import {
	Content,
	EditorProvider,
	JSONContent,
	useCurrentEditor,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { FC } from 'react';
import Suggestions from './Suggestions';
import Link from '@tiptap/extension-link';
import FontFamily from '@tiptap/extension-font-family';
import Color from '@tiptap/extension-color';
import Image from '@tiptap/extension-image';
import { Style } from './extensions/style';
import { Div } from './extensions/div';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';

const StyledButton = styled('button')(() => ({
	padding: '10px',
	borderRadius: '10px',
	margin: '0 5px',
	cursor: 'pointer',
	fontSize: '0.85rem',
	border: 'none',
}));

const MenuBar = () => {
	const { editor } = useCurrentEditor();

	if (!editor) {
		return null;
	}

	return (
		<div className="control-group">
			<div className="button-group">
				<StyledButton
					onClick={() => editor.chain().focus().toggleBold().run()}
					disabled={!editor.can().chain().focus().toggleBold().run()}
					className={editor.isActive('bold') ? 'is-active' : ''}
				>
					Bold
				</StyledButton>
				<StyledButton
					onClick={() => editor.chain().focus().toggleItalic().run()}
					disabled={!editor.can().chain().focus().toggleItalic().run()}
					className={editor.isActive('italic') ? 'is-active' : ''}
				>
					Italic
				</StyledButton>
				<StyledButton
					onClick={() => editor.chain().focus().toggleUnderline().run()}
					disabled={!editor.can().chain().focus().toggleUnderline().run()}
					className={editor.isActive('underline') ? 'is-active' : ''}
				>
					Underline
				</StyledButton>
				<StyledButton
					onClick={() => editor.chain().focus().toggleStrike().run()}
					disabled={!editor.can().chain().focus().toggleStrike().run()}
					className={editor.isActive('strike') ? 'is-active' : ''}
				>
					Strike
				</StyledButton>
			</div>
		</div>
	);
};

const bodySuggestions = Suggestions([
	'FUND',
	'INVESTOR',
	'USER',
	'LINK',
	'LOGO',
]);

// These extensions must be configured in the same way in the backend. See email-templates.service.ts
const extensions = [
	TextStyle,
	FontFamily,
	Color,
	StarterKit.configure({
		history: false,
	}),
	Underline,
	Mention.configure({
		HTMLAttributes: {
			class: 'mention',
		},
		suggestion: bodySuggestions,
		renderText({ options, node }) {
			return `${node.attrs.label ?? node.attrs.id}`;
		},
		renderHTML({ options, node }) {
			return [
				'span',
				{
					class: 'mention',
				},
				`[${node.attrs.label ?? node.attrs.id}]`,
			];
		},
	}),
	Link.configure({
		HTMLAttributes: {
			class: 'link',
			style:
				'background-color: #113560; border-top: 10px solid #113560; border-right: 18px solid #113560; border-bottom: 10px solid #113560;border-left: 18px solid #113560; display: inline-block; color: #FFFFFF; text-decoration: none; border-radius: 3px; -webkit-text-size-adjust: none; box-sizing: border-box;',
		},
	}),
	Image.configure({
		HTMLAttributes: {
			style: 'width: 150px;',
		},
	}),
	Style.configure({
		types: ['paragraph', 'div'],
	}),
	Div,
	Table.configure({
		HTMLAttributes: {
			align: 'center',
		},
	}),
	TableRow,
	TableCell,
	TableHeader,
];

const StyledMenuBar = styled(MenuBar, { shouldForwardProp: (prop) => false })(
	() => ({
		borderBottom: '2px solid black',
		padding: '10px',
	}),
);

interface IEditorProps {
	initialContent: Content;
	style: string;
	onChange: (json: JSONContent) => void;
	isPreview?: boolean;
}

const UnstyledEditor: FC<IEditorProps> = ({
	initialContent,
	style,
	onChange,
	isPreview = false,
}) => {
	return (
		<>
			<EditorProvider
				slotBefore={isPreview ? <></> : <StyledMenuBar />}
				extensions={extensions}
				content={initialContent}
				editorProps={{
					attributes: {
						style: `outline: none; margin: 2px; ${style}`,
					},
				}}
				onUpdate={({ editor }) => {
					onChange(editor.getJSON());
				}}
				editable={!isPreview}
			></EditorProvider>
		</>
	);
};

const EmailTemplateBodyEditor = styled(UnstyledEditor)(() => ({}));

export default EmailTemplateBodyEditor;
