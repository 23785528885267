import { Alert, AlertColor, Snackbar } from '@mui/material';
import { FC } from 'react';

interface IPopUpAlertProps {
	show: boolean;
	onClose: () => void;
	severity: AlertColor;
	message: string
}

export const PopUpAlert: FC<IPopUpAlertProps> = ({
	show,
	onClose,
	severity,
	message
}) => {
	return (
		<Snackbar
			open={show}
			autoHideDuration={3000}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
				{message}
			</Alert>
		</Snackbar>
	);
};
