import { Typography, IconButton, styled, Paper } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ISponsor } from 'app/types/sponsor.types';
import { FileCopy } from '@mui/icons-material';

const Container = styled('div')({
	marginLeft: 'auto',
	marginRight: 'auto',
	marginTop: 15,
	marginBottom: 15,
	padding: 15,
});

const TextWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-end',
});

const Uneditable = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-end',
	justifyContent: 'space-between',
});

interface ISubdomainProps {
	sponsor: ISponsor;
}

export const Subdomain: FC<ISubdomainProps> = ({ sponsor }) => {
	const [subdomain, setSubdomain] = useState('');
	const [loginUrl, setLoginUrl] = useState('');

	useEffect(() => {
		if (sponsor.subdomain) {
			setSubdomain(sponsor.subdomain);
			updateLoginUrl(sponsor.subdomain);
		}
	}, [sponsor]);

	const updateLoginUrl = (subdomain: string) => {
		const origin = new URL(window.location.href).origin;
		const loginUrl = new URL(origin);
		let host = loginUrl.host;
		if (host.split('.').length > 2) {
			host = host.split('.').slice(1).join('.');
		}
		loginUrl.host = subdomain + '.' + host;
		setLoginUrl(loginUrl.toString());
	};

	return (
		<Paper>
			<Container>
				<Uneditable>
					<TextWrapper>
						<Typography variant={'h4'} style={{ marginRight: '3em' }}>
							Subdomain
						</Typography>
						{subdomain}
					</TextWrapper>
					<IconButton
						size={'small'}
						onClick={() => navigator.clipboard.writeText(loginUrl)}
					>
						<FileCopy />
					</IconButton>
				</Uneditable>
			</Container>
		</Paper>
	);
};

export default Subdomain;
