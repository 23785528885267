import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { FC } from 'react';
import { IRolesListProps } from './types';
import { ROLES } from 'common/helpers/permissions/Roles';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { OptionsColumnBox } from './styles';
import _ from 'lodash';
import { SelectAllOption } from 'common/components/SelectAllOption';

const availableRoles = Object.values(ROLES).filter(
	(r) => r !== ROLES.SUPER_ADMIN,
);

export const RolesList: FC<IRolesListProps> = ({
	selectedRoles,
	setSelectedRoles,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	return (
		<OptionsColumnBox>
			<FormGroup>
				<SelectAllOption
					availableIds={availableRoles}
					selectedIds={selectedRoles}
					setSelectedIds={setSelectedRoles}
				/>
				{Object.values(ROLES).map((role, i) => {
					if (role === ROLES.SUPER_ADMIN) return;

					return (
						<FormControlLabel
							key={i}
							style={{ fontSize: '12px' }}
							control={
								<Checkbox
									size="small"
									onChange={() => {
										if (selectedRoles.includes(role)) {
											setSelectedRoles(selectedRoles.filter((r) => r !== role));
											return;
										}

										setSelectedRoles([...selectedRoles, role]);
									}}
									checked={selectedRoles.includes(role) || false}
									style={{
										color: sponsorColor,
										padding: 3,
										marginLeft: 5,
									}}
								/>
							}
							label={<Typography>{role}</Typography>}
						></FormControlLabel>
					);
				})}
			</FormGroup>
		</OptionsColumnBox>
	);
};
