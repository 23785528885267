import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { FC, MouseEventHandler } from 'react';

interface IEditButtonProps {
	onClick: MouseEventHandler<HTMLButtonElement>;
}

export const EditButton: FC<IEditButtonProps> = ({ onClick }) => {
	return (
		<IconButton onClick={onClick}>
			<Edit />
		</IconButton>
	);
};
