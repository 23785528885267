import { User } from '@auth0/auth0-react';

export type IUseGrantsReturnType = {
	user?: User;
	allowedSponsor: (sponsorId: number) => boolean;
	currentRoles: string[];
	isAdmin: boolean;
};

export enum Auth0UserAccessors {
	APP_METADATA = 'appMetadata',
}

export interface Auth0AppMetadata {
	roles: string[];
	databaseConnection: string;
}
