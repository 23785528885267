/*
 * Any changes made to this file also need to be made in the backend
 */

import { mergeAttributes, Node } from '@tiptap/core';

export interface DivOptions {
	HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
	interface Commands<ReturnType> {
		div: {
			setDiv: () => ReturnType;
		};
	}
}

export const Div = Node.create<DivOptions>({
	name: 'div',

	priority: 1000,

	addOptions() {
		return {
			HTMLAttributes: {},
		};
	},

	content: 'block*',
	group: 'block',

	parseHTML() {
		return [{ tag: 'div' }];
	},

	renderHTML({ HTMLAttributes }) {
		return [
			'div',
			mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
			0,
		];
	},

	addCommands() {
		return {
			setDiv:
				() =>
				({ commands }) => {
					return commands.setNode(this.name);
				},
		};
	},
});
