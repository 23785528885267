import { FC } from 'react';
import { NotificationText, PaperBox } from './styles';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { Grid, ToggleButton } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
	docsUploadState,
	onSetNotifyInvestorUsers,
} from 'api/redux/DocsUploadReducer';
import { useFileContext } from './FileContext';
import { NotificationDelaySelector } from './NotificationDelaySelector';
import { EmailTemplateSelector } from 'common/components/EmailTemplates/EmailTemplateSelector';
import { DefaultTemplates } from 'common/components/EmailTemplates/types';

export const NotificationOptions: FC = () => {
	const { notifyInvestors } = useSelector(docsUploadState);
	const dispatch = useDispatch();
	const { uploadedFiles } = useFileContext();

	if (uploadedFiles.length === 0) return <></>;

	return (
		<PaperBox>
			<Grid container direction="row" spacing={2} sx={{ padding: '2rem' }}>
				<Grid item xs={4} md={4}>
					<PermissionsWrapper
						scopes={[SCOPES.canNotifyInvestorUsersOnUploadedDocuments]}
					>
						<>
							<ToggleButton
								aria-labelledby="notify-investors-check-label"
								value="check"
								selected={notifyInvestors}
								color={'primary'}
								onChange={() => {
									dispatch(onSetNotifyInvestorUsers(!notifyInvestors));
								}}
							>
								{notifyInvestors ? (
									<Check sx={{ fontSize: 10 }} />
								) : (
									<div style={{ height: 10, width: 10 }} />
								)}
							</ToggleButton>
							<NotificationText id="notify-investors-check-label">
								Notify Investors
							</NotificationText>
						</>
					</PermissionsWrapper>
				</Grid>
				<Grid item xs={4} md={4} sx={{ mt: '-13px' }}>
					<PermissionsWrapper
						scopes={[SCOPES.canNotifyInvestorUsersOnUploadedDocuments]}
					>
						<NotificationDelaySelector show={notifyInvestors} />
					</PermissionsWrapper>
				</Grid>
				<Grid item xs={4} md={4} sx={{ mt: '-13px' }}>
					<PermissionsWrapper
						scopes={[SCOPES.canNotifyInvestorUsersOnUploadedDocuments]}
					>
						<EmailTemplateSelector
							show={notifyInvestors}
							defaultValue={DefaultTemplates.DOCUMENTS}
						/>
					</PermissionsWrapper>
				</Grid>
			</Grid>
		</PaperBox>
	);
};
