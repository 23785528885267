import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Config } from '../../../app/types/config.types';

export const configApi = createApi({
	reducerPath: 'configApi',
	baseQuery: fetchBaseQuery(), // no base URL because we want to refer to the relative path
	endpoints: (build) => ({
		getConfig: build.query<Config, void>({
			query: () => '/config.json',
		}),
	}),
});

export const { useGetConfigQuery: useConfig } = configApi;
