import {
	Box,
	Dialog,
	DialogTitle,
	DialogActions,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material';
import { TextField } from '@mui/material';
import { FC, useState } from 'react';
import { useCreateFundMutation } from 'api/redux/services/fundApi';
import { useGetTemplatesQuery } from 'api/redux/services/capitalActivityApi';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { BrandedButton } from 'common/components/BrandedButton';

interface ICreateFundProps {
	open: boolean;
	onClose: () => void;
}

export const CreateFund: FC<ICreateFundProps> = ({ open, onClose }) => {
	const { data: templates = [] } = useGetTemplatesQuery();
	const [createFund] = useCreateFundMutation();
	const [fundName, setFundName] = useState('');
	const [templateId, setTemplateId] = useState<number | undefined>(
		templates[0]?.id,
	);
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;
	const submitDisabled = !fundName || fundName === '' || !templateId;

	const handleSubmit = async () => {
		await createFund({
			name: fundName,
			templateId: templateId,
			organizationId: currentSponsor.id,
		});
		onClose();
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Create Fund</DialogTitle>
			<Box sx={{ pl: 3, pr: 3, pb: 3 }}>
				<TextField
					label="Fund Name"
					variant="outlined"
					fullWidth
					inputProps={{
						style: {
							borderBottom: 'none',
							paddingLeft: 20,
						},
					}}
					value={fundName}
					onChange={(e) => setFundName(e.target.value)}
					autoFocus
				/>
			</Box>
			<Box sx={{ pl: 3, pr: 3, pb: 3 }}>
				<FormControl sx={{ width: '100%' }} variant="outlined">
					<InputLabel id="template-label">Template</InputLabel>
					<Select
						labelId="template-label"
						id="template-dropdown"
						value={templateId}
						label="Select Template"
						onChange={(e) => setTemplateId(e.target.value as number)}
					>
						{templates.map((tpl) => (
							<MenuItem value={tpl.id} key={tpl.id}>
								{tpl.templateId}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<DialogActions>
				<BrandedButton onClick={onClose}>Cancel</BrandedButton>
				<BrandedButton
					variant="contained"
					onClick={handleSubmit}
					disabled={submitDisabled}
				>
					Create
				</BrandedButton>
			</DialogActions>
			{submitDisabled && (
				<Box textAlign={'center'}>
					<small>Please complete the name and the template</small>
				</Box>
			)}
		</Dialog>
	);
};
