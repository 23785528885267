import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	RadioProps,
} from '@mui/material';
import { FC } from 'react';
import { ExportModes, IExportModeProps } from './types';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { styled } from '@mui/system';

const BpIcon = styled('span')(() => ({
	borderRadius: '50%',
}));

const BpCheckedIcon = styled(BpIcon)({
	'&::before': {
		display: 'block',
	},
});

const BpRadio = (props: RadioProps) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	return (
		<Radio
			disableRipple
			checkedIcon={
				<BpCheckedIcon
					sx={{
						'&::after': {
							backgroundColor: `${sponsorColor} !important`,
							border: `2px solid ${sponsorColor} !important`,
						},
					}}
				/>
			}
			icon={<BpIcon />}
			{...props}
		/>
	);
};

const FormTitle = styled(FormLabel)({
	textAlign: 'center',
	fontSize: 24,
	marginBottom: '1rem',
});

export const ExportMode: FC<IExportModeProps> = ({ value, handleChange }) => {
	return (
		<Box sx={{ display: 'flex', justifyContent: 'center' }}>
			<FormControl>
				<FormTitle id="export-modes-label">Select Export Mode</FormTitle>
				<RadioGroup
					defaultValue={ExportModes.BY_RELATIONSHIP}
					aria-labelledby="export-modes-label"
					name="export-modes-label-rgroup"
					value={value}
					onChange={(e) => handleChange(e.target.value as ExportModes)}
					row
				>
					<FormControlLabel
						value={ExportModes.BY_RELATIONSHIP}
						label={ExportModes.BY_RELATIONSHIP}
						control={<BpRadio />}
					/>
					<FormControlLabel
						value={ExportModes.BY_USER}
						label={ExportModes.BY_USER}
						control={<BpRadio />}
					/>
				</RadioGroup>
			</FormControl>
		</Box>
	);
};
