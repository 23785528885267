import { FC } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { IconBtn } from 'common/components/IconBtn';
import { ESortSource, ISortProps } from './types';
import { IAscDesc, ISortStrategy } from 'api/redux/types';
import { documentsState } from 'api/redux/DocumentsReducer';
import { useSelector } from 'react-redux';
import { usersState } from 'api/redux/UsersReducer';

export const Sort: FC<ISortProps> = ({ column, onSort, sortSource }) => {
	const { isSorted, id } = column;
	const { sortStrategy: docsSortStrategy } = useSelector(documentsState);
	const { sortStrategy: usersSortStrategy } = useSelector(usersState);
	let sortStrategy: ISortStrategy[] = [];
	switch (sortSource) {
		case ESortSource.DOCUMENTS:
			sortStrategy = docsSortStrategy;
			break;
		case ESortSource.USERS:
			sortStrategy = usersSortStrategy;
			break;
	}

	const strategy: ISortStrategy | undefined = sortStrategy.find(
		(s: ISortStrategy) => s.column === column.id,
	);
	const ascdesc: IAscDesc =
		strategy?.ascdesc === IAscDesc.ASC ? IAscDesc.DESC : IAscDesc.ASC;

	const SortedDescending: FC = () => {
		return (
			<span>
				<IconBtn
					tooltip="Sort Descending"
					height="0px"
					customColor={true}
					onClick={() => {
						if (!onSort) return;

						onSort(id, IAscDesc.DESC);
					}}
				>
					<ArrowDropDownIcon fontSize="small" />
				</IconBtn>
			</span>
		);
	};

	const SortedAscending: FC = () => {
		return (
			<span>
				<IconBtn
					tooltip="Sort Ascending"
					height="0px"
					customColor={true}
					onClick={() => {
						if (!onSort) return;

						onSort(id, IAscDesc.ASC);
					}}
				>
					<ArrowDropUpIcon fontSize="small" />
				</IconBtn>
			</span>
		);
	};

	if (!isSorted) return <></>;
	if (ascdesc === IAscDesc.DESC) return <SortedDescending />;
	if (ascdesc === IAscDesc.ASC) return <SortedAscending />;

	return <></>;
};
