import { FC } from 'react';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

const TemplateButton = styled(Button)(() => ({
	fontSize: '1rem',
	textTransform: 'none',
	color: 'white !important',
	margin: '5px 5px 0px 0px',
	'&:hover, &.Mui-focusVisible': {
		color: '#0092D8 !important',
	},
}));

const CSVLinkBox = styled(CSVLink)(() => ({
	color: '#FFFFFF',
}));

export const BulkUploadUserTemplate: FC = () => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	const headers = [
		{ label: 'User Name', key: 'details.UN' },
		{ label: 'User Email', key: 'details.UE' },
		{ label: 'Role', key: 'details.R' },
		{ label: 'INV ID', key: 'details.IID' },
	];
	const data = [
		{
			details: {
				UN: '',
				UE: '',
				R: '',
				IID: '',
			},
		},
	];
	const csvReport = {
		data,
		headers,
		filename: 'BulkUploadUserTemplate.csv',
	};

	return (
		<Box>
			<TemplateButton
				variant="outlined"
				sx={{
					backgroundColor: `${sponsorColor}`,
				}}
			>
				<CSVLinkBox {...csvReport}>Download Template</CSVLinkBox>
			</TemplateButton>
		</Box>
	);
};
