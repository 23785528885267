import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	FormGroup,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	TextField,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { InvestorNote } from 'app/types/investor.note.type';
import moment from 'moment';
import {
	useCreateInvestorNoteMutation,
	useDeleteInvestorNoteMutation,
	useGetNotesByInvestorIdQuery,
	useUpdateInvestorNoteMutation,
} from 'api/redux/services/investors.notes.service';
import { ConfirmationModal } from 'common/components/UsersTable/user-permissions/ConfirmationModal';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { PopUpAlert } from 'common/components/PopUpAlert';
import PermissionsWrapper, {
	hasPermission,
} from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { constants } from 'common/helpers/Constants';
import { BrandedButton } from 'common/components/BrandedButton';

interface InvestorNotesProps {
	investorId: number;
}

interface ISingleNote {
	investorId: number;
	note: InvestorNote;
	afterDelete: () => void;
	onEditClick: () => void;
}

interface IAddNoteRowProps {
	investorId: number;
	afterCreate: () => void;
}

interface IEditNoteProps {
	investorId: number;
	noteId: number;
	isOpen: boolean;
	handleClose: () => void;
	originalText: string;
	afterUpdate: () => void;
}

const EditNote: FC<IEditNoteProps> = ({
	investorId,
	noteId,
	isOpen,
	handleClose,
	originalText,
	afterUpdate,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const [text, setText] = useState(originalText);
	const [updateNote, { isError, isLoading, isSuccess }] =
		useUpdateInvestorNoteMutation();

	const handleEdit = () => {
		updateNote({ investorId, noteId, text });
		afterUpdate();
	};

	if (!hasPermission({ scopes: [SCOPES.canEditInvestorNotes] })) return <></>;

	return (
		<>
			<Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
				<DialogTitle>Edit Investor Note</DialogTitle>
				<DialogContent>
					<FormGroup>
						<FormControlLabel
							control={
								<TextField
									id="edit-investor-note-control"
									label="Note text"
									multiline
									autoFocus
									variant="outlined"
									value={text}
									onChange={(e) => setText(e.target.value)}
									sx={{ resize: 'both', width: '100%' }}
								/>
							}
							label=""
						/>
					</FormGroup>
				</DialogContent>
				<DialogActions>
					<BrandedButton onClick={handleClose}>Cancel</BrandedButton>
					<BrandedButton
						variant="contained"
						onClick={handleEdit}
						disabled={isLoading}
					>
						{isLoading ? 'Please wait...' : 'Update'}
					</BrandedButton>
				</DialogActions>
			</Dialog>
			<PopUpAlert
				show={isError}
				onClose={() => {}}
				severity={'error'}
				message={`There's been an error. Please try again later or contact support (${constants.SUPPORT_EMAIL})`}
			/>
			<PopUpAlert
				show={isSuccess}
				onClose={() => {}}
				severity={'success'}
				message={'Note correctly updated'}
			/>
		</>
	);
};

const AddNoteRow: FC<IAddNoteRowProps> = ({ investorId, afterCreate }) => {
	const [text, setText] = useState('');
	const [createNote, isSuccess] = useCreateInvestorNoteMutation();

	useEffect(() => {
		if (isSuccess) {
			setText('');
			afterCreate();
		}
	}, [afterCreate, isSuccess]);

	if (!hasPermission({ scopes: [SCOPES.canCreateInvestorNotes] })) return <></>;

	return (
		<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
			<TextField
				id="create-investor-note-control"
				label=""
				placeholder="Add your note here"
				multiline
				autoFocus
				variant="standard"
				value={text}
				onChange={(e) => setText(e.target.value)}
				sx={{ resize: 'both', width: '100%' }}
			/>
			<ListItemIcon
				onClick={() => {
					createNote({ investorId, text });
				}}
			>
				<Add sx={{ cursor: 'pointer' }} />
			</ListItemIcon>
		</ListItem>
	);
};

const SingleNote: FC<ISingleNote> = ({
	investorId,
	note,
	afterDelete,
	onEditClick,
}) => {
	const [deleteNote, isSuccess] = useDeleteInvestorNoteMutation();
	const [needConfirmation, setNeedConfirmation] = useState(false);

	useEffect(() => {
		if (isSuccess) afterDelete();
	}, [afterDelete, isSuccess]);

	return (
		<>
			<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
				<ListItemText
					primary={note.text}
					secondary={moment(note.createdAt).fromNow()}
					sx={{ marginRight: '1rem', wordWrap: 'break-word' }}
				/>
				<PermissionsWrapper scopes={[SCOPES.canEditInvestorNotes]}>
					<ListItemIcon>
						<Edit
							sx={{ cursor: 'pointer' }}
							onClick={() => {
								onEditClick();
							}}
						/>
					</ListItemIcon>
				</PermissionsWrapper>
				<PermissionsWrapper scopes={[SCOPES.canDeleteInvestorNotes]}>
					<ListItemIcon onClick={() => setNeedConfirmation(true)}>
						<Delete sx={{ cursor: 'pointer' }} />
					</ListItemIcon>
				</PermissionsWrapper>
			</ListItem>
			<ConfirmationModal
				isOpen={needConfirmation}
				onCancel={() => setNeedConfirmation(false)}
				onAccept={() => {
					deleteNote({ investorId, noteId: note.id });
					setNeedConfirmation(false);
				}}
				title={'Confirm delete note'}
				cancelText="Cancel"
				saveText="Confirm"
				message={'Please confirm you want to delete the current note'}
			/>
		</>
	);
};

export const InvestorNotes: FC<InvestorNotesProps> = ({ investorId }) => {
	const { data: investorNotes, refetch } =
		useGetNotesByInvestorIdQuery(investorId);
	const [isEditing, setIsEditing] = useState(false);
	const [editNote, setEditNote] = useState<InvestorNote | null>(null);

	if (!investorId || investorId === 0) return <></>;

	return (
		<Box>
			<Typography variant="h4">Investor Notes</Typography>
			<List dense={true}>
				<PermissionsWrapper scopes={[SCOPES.canCreateInvestorNotes]}>
					<AddNoteRow investorId={investorId} afterCreate={refetch} />
				</PermissionsWrapper>
				{investorNotes &&
					investorNotes.map((note) => {
						return (
							<Box key={note.id}>
								<SingleNote
									note={note}
									investorId={investorId}
									afterDelete={refetch}
									onEditClick={() => {
										setIsEditing(true);
										setEditNote(note);
									}}
								/>
								<Divider />
							</Box>
						);
					})}
			</List>
			{isEditing && editNote && (
				<PermissionsWrapper scopes={[SCOPES.canEditInvestorNotes]}>
					<EditNote
						isOpen={isEditing}
						investorId={investorId}
						noteId={editNote.id}
						originalText={editNote.text}
						handleClose={() => {
							setIsEditing(false);
							setEditNote(null);
						}}
						afterUpdate={() => {
							setIsEditing(false);
							setEditNote(null);
							refetch();
						}}
					/>
				</PermissionsWrapper>
			)}
		</Box>
	);
};
