import { FC, useCallback, useState } from 'react';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import OrganizationMembers from './OrganizationMembers';
import { Box } from '@mui/material';
import {
	ContentSection,
	RootContainer,
	SideBarContainer,
} from 'modules/styles';
import AdminSidebar from 'modules/admin/AdminSideBar';
import { ExportUsersCSV } from 'common/components/UsersTable/users-table-list/export-users-csv/ExportUsersCSV';
import CreateAnnouncement from 'modules/admin/CreateAnnouncement';
import { EmailTemplateTableList } from 'common/components/EmailTemplates/EmailTemplatesList';

export enum SponsorMenuOptions {
	Communications = 'Communications',
	ExportData = 'Export Data', // wrap inside SCOPES.canDownloadUsers
	Announcements = 'Announcements', // SCOPES.canCreateAnnouncement
	SponsorUsers = 'Sponsor Users',
}

const OrganizationView: FC = () => {
	const [currentTab, setCurrentTab] = useState(SponsorMenuOptions.SponsorUsers);

	const renderTabComponent = useCallback(() => {
		switch (currentTab) {
			case SponsorMenuOptions.Communications:
				return <EmailTemplateTableList />;
			case SponsorMenuOptions.ExportData:
				return <ExportUsersCSV />;
			case 'Announcements':
				return <CreateAnnouncement />;
			case 'Sponsor Users':
				return <OrganizationMembers />;
			default:
				return <OrganizationMembers />;
		}
	}, [currentTab]);

	return (
		<MainSidebarContainer>
			<RootContainer maxWidth={false}>
				<SideBarContainer maxWidth={false}>
					<AdminSidebar
						onClick={setCurrentTab}
						options={Object.values(SponsorMenuOptions)}
						title="Investor Management"
					/>
				</SideBarContainer>
				<ContentSection maxWidth={false}>
					<Box>{renderTabComponent()}</Box>
				</ContentSection>
			</RootContainer>
		</MainSidebarContainer>
	);
};

export default OrganizationView;
