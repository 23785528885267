import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import {
	useLazyGetAnnouncementsForMultipleSponsorsQuery,
	useUpdateAnnouncementMutation,
	useDeleteAnnouncementMutation,
} from 'api/redux/services/announcementsApi';
import { Box, Button, Container, Typography } from '@mui/material';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { Announcement } from 'app/types/Announcements.types';
import { EditAnnouncementModal } from './EditAnnouncementModal';
import { Delete, Edit } from '@mui/icons-material';
import { styled } from '@mui/system';

const Root = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	paddingRight: 0,
	paddingLeft: 0,
	maxWidth: '100%',
	height: '100%',
	overflow: 'auto',
});

const EditButton = styled(Button)({
	float: 'right',
	margin: 0,
	padding: 0,
});

const DeleteButton = styled(Button)({
	float: 'right',
	margin: 0,
	padding: 0,
});

const ScrollGrid = styled(Container)({
	display: 'flex',
	flexDirection: 'column',
	marginTop: '24px',
});

export const Announcements: FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const [getAnnouncementsForMultipleSponsors, announcements] =
		useLazyGetAnnouncementsForMultipleSponsorsQuery();
	const [updateAnnouncement] = useUpdateAnnouncementMutation();
	const [deleteAnnouncement, refetch] = useDeleteAnnouncementMutation();
	const [currentAnnouncement, setCurrentAnnouncement] =
		useState<Announcement>();
	const [announcementsList, setAnnouncementsList] = useState<Announcement[]>(
		[],
	);
	const [editModalOpen, setEditModalOpen] = useState(false);

	useEffect(() => {
		if (currentSponsor.id === 0) return;

		getAnnouncementsForMultipleSponsors([currentSponsor.id]);
	}, [
		getAnnouncementsForMultipleSponsors,
		editModalOpen,
		currentSponsor,
		refetch,
	]);

	useEffect(() => {
		if (!announcements.data) return;

		setAnnouncementsList(announcements.data);
	}, [announcements.data]);

	const handleEditModalOpen = (announcement) => {
		setEditModalOpen(true);
		setCurrentAnnouncement(announcement);
	};

	const handleEditModalClose = () => {
		setEditModalOpen(false);
	};

	const handleEditModalSave = async (text) => {
		if (!currentAnnouncement?.id) return;

		await updateAnnouncement({ id: currentAnnouncement?.id, text });

		setEditModalOpen(false);
	};

	return (
		<Root>
			<Typography variant="h4">Announcements</Typography>
			<ScrollGrid>
				{announcementsList.length === 0 && (
					<Typography>You do not currently have any announcements.</Typography>
				)}
				{announcementsList.map((announcement, index) => {
					return (
						<Box
							key={index}
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginBottom: '12px',
							}}
						>
							<Typography
								key={index}
								style={{
									whiteSpace: 'pre-line',
									width: '80%',
									overflowWrap: 'break-word',
									fontSize: '1rem',
								}}
							>
								{announcement.text}
							</Typography>
							<Box>
								<PermissionsWrapper scopes={[SCOPES.canEditAnnouncement]}>
									<EditButton onClick={() => handleEditModalOpen(announcement)}>
										<Edit style={{ color: `${sponsorColor}` }} />
									</EditButton>
								</PermissionsWrapper>
								<PermissionsWrapper scopes={[SCOPES.canDeleteAnnouncement]}>
									<DeleteButton
										onClick={() =>
											deleteAnnouncement({
												announcementId: announcement.id,
											})
										}
									>
										<Delete style={{ color: `${sponsorColor}` }} />
									</DeleteButton>
								</PermissionsWrapper>
							</Box>
						</Box>
					);
				})}
			</ScrollGrid>
			{editModalOpen && currentAnnouncement && (
				<EditAnnouncementModal
					open={editModalOpen}
					announcement={currentAnnouncement}
					handleEditModalSave={handleEditModalSave}
					handleEditModalClose={handleEditModalClose}
				/>
			)}
		</Root>
	);
};

export default Announcements;
