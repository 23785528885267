import { FC, useState } from 'react';
import { IUpdateWaterMarkPDFProps } from './types';
import { Paper } from '@mui/material';
import { LabelBox } from './LabelBox';
import { EditBox } from './EditBox';

export const UpdateWaterMarkPDF: FC<IUpdateWaterMarkPDFProps> = ({
	sponsor,
	refetchOrg,
}) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);

	return (
		<Paper>
			<LabelBox
				show={!isEditing}
				sponsor={sponsor}
				onEditClick={() => setIsEditing(true)}
			/>
			<EditBox
				show={isEditing}
				sponsor={sponsor}
				refetchOrg={refetchOrg}
				onCancelClick={() => setIsEditing(false)}
			/>
		</Paper>
	);
};
