import { KeyboardEvent } from 'react';

export const handleEnterPressForDialogs = (
	e: KeyboardEvent<HTMLDivElement>,
	submitMethod: () => void,
): void => {
	if (e.key === 'Enter') submitMethod();
};

export default handleEnterPressForDialogs;
