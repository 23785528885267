import { selectDropdown } from 'api/redux/DropdownReducer';
import { useGetLightLogoQuery } from 'api/redux/services/organizations.service';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ERoutes } from 'App';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

const LinkBox = styled(NavLink)(() => ({
	color: '#F1F2F9',
	margin: '0 1em',
	fontSize: '16px',
	minWidth: '50px',
	display: 'flex',
}));

const ImgBox = styled('img')(() => ({
	maxWidth: '300px',
	maxHeight: '45px',
	minWidth: '50px',
}));

export const SponsorLogo: FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;
	const { data: logo } = useGetLightLogoQuery(currentSponsor.id);

	if (!logo) return <Box />;

	return (
		<LinkBox to={ERoutes.Dashboard}>
			<ImgBox src={logo?.url} />
		</LinkBox>
	);
};
