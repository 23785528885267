/*
 * Any changes made to this file also need to be made in the backend
 */

import { Extension } from '@tiptap/core';

export type StyleOptions = {
	types: string[];
};

declare module '@tiptap/core' {
	interface Commands<ReturnType> {
		style: {
			setStyle: (style: string) => ReturnType;
			unsetStyle: () => ReturnType;
		};
	}
}

export const Style = Extension.create<StyleOptions>({
	name: 'style',

	addOptions() {
		return {
			types: ['textStyle'],
		};
	},

	addGlobalAttributes() {
		return [
			{
				types: this.options.types,
				attributes: {
					style: {
						default: null,
						parseHTML: (element) => element.style.all?.replace(/['"]+/g, ''),
						renderHTML: (attributes) => {
							if (!attributes.style) {
								return {};
							}

							return {
								style: `${attributes.style}`,
							};
						},
					},
				},
			},
		];
	},
});
