import { FC } from 'react';
import { IColumn } from '../types';
import { Box } from '@mui/material';
import { ColumnLabels, ColumnSubLabel, TableCell, TableRow } from './styles';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import {
	getInvestorColumns,
	getFundColumns,
	getReportingPeriod,
} from './utils';
import { TableHead } from '@mui/material';
import {
	ISecondHeaderRowProps,
	ITableHeadersProps,
	TemplateType,
	TemplateTypesToColSpan,
} from './types';

const FirstHeaderRow: FC<ITableHeadersProps> = ({
	capitalActivityTemplate,
	reportingPeriod,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const columns = [
		...getInvestorColumns(capitalActivityTemplate),
		...getFundColumns(capitalActivityTemplate),
	];
	const reportingDate = reportingPeriod?.replace('Period ending ', '');
	reportingPeriod = getReportingPeriod(reportingDate ? reportingDate : '');

	return (
		<TableRow
			style={{
				backgroundColor: `${sponsorColor}`,
			}}
		>
			<TableCell></TableCell>
			{columns.map(
				(column: IColumn, i) =>
					column.title && (
						<TableCell
							key={i}
							style={{
								width:
									capitalActivityTemplate.templateId === TemplateType.NO_ITD
										? '25rem'
										: '35rem',
								padding: '10px 0px 10px 0px',
							}}
						>
							<ColumnHeader
								column={column}
								reportingDate={reportingDate}
								reportingPeriod={reportingPeriod}
							></ColumnHeader>
						</TableCell>
					),
			)}
		</TableRow>
	);
};

const ColumnHeader = ({ column, reportingDate, reportingPeriod }) => {
	const isInception = column.title[1].text.includes('inception');
	const labelText = isInception ? `Inception - ` : reportingPeriod + ' - ';

	return (
		<Box>
			<ColumnLabels key={`${column.title[1].text}-2`}>{labelText}</ColumnLabels>
			<ColumnLabels key={`${column.title[1].text}-2`}>
				{reportingDate}
			</ColumnLabels>
		</Box>
	);
};
const SecondHeaderRow: FC<ISecondHeaderRowProps> = ({
	capitalActivityTemplate,
}) => {
	return (
		<TableRow className="cas-row">
			<TableCell className="cas-cell"></TableCell>
			<TableCell
				colSpan={TemplateTypesToColSpan[capitalActivityTemplate.templateId]}
				className="cas-cell"
			>
				<ColumnSubLabel>Investor Level</ColumnSubLabel>
			</TableCell>
			<TableCell
				colSpan={TemplateTypesToColSpan[capitalActivityTemplate.templateId]}
				className="cas-cell"
			>
				<ColumnSubLabel>Fund Level</ColumnSubLabel>
			</TableCell>
		</TableRow>
	);
};

export const CASTableHeaders: FC<ITableHeadersProps> = ({
	capitalActivityTemplate,
	reportingPeriod,
}) => {
	return (
		<TableHead>
			<FirstHeaderRow
				capitalActivityTemplate={capitalActivityTemplate}
				reportingPeriod={reportingPeriod}
			/>
			<SecondHeaderRow capitalActivityTemplate={capitalActivityTemplate} />
		</TableHead>
	);
};
