import {
	Button,
	Box,
	Typography,
	LinearProgress,
	styled,
	TextField,
	IconButton,
	Paper,
} from '@mui/material';
import {
	useGetOrganizationQuery,
	useUpdateHeaderColorMutation,
} from 'api/redux/services/organizations.service';
import { FC, useEffect, useState } from 'react';
import { ISponsor } from 'app/types/sponsor.types';
import { defaultColor } from 'api/redux/siteColorReducer';
import { Edit } from '@mui/icons-material';

const Wrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-end',
	gap: '1em',
});

const Container = styled('div')({
	marginLeft: 'auto',
	marginRight: 'auto',
	marginTop: 15,
	marginBottom: 15,
	padding: 15,
});

const ButtonContainer = styled('div')({
	marginTop: 15,
	marginRight: 55,
	display: 'flex',
	justifyContent: 'flex-end',
});

const Progress = styled(Box)({
	width: '80%',
	marginLeft: 'auto',
	marginRight: 'auto',
});

const Uneditable = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-end',
	justifyContent: 'space-between',
});

const ColorBoxStyled = styled(Box)({
	width: 30,
	height: 30,
	border: '2px solid black',
	padding: 2,
	backgroundColor: 'white',
	flexShrink: 0,
});

interface UpdateHeaderColorProps {
	sponsor: ISponsor;
}

export const UpdateHeaderColor: FC<UpdateHeaderColorProps> = ({ sponsor }) => {
	const [isEditing, setIsEditing] = useState(false);
	const [color, setColor] = useState(sponsor.headerColor ?? '');
	const [updateHeaderColor, { data: updatedColor, isLoading }] =
		useUpdateHeaderColorMutation();
	const { refetch } = useGetOrganizationQuery(sponsor.id);

	useEffect(() => {
		refetch();
	}, [updatedColor, refetch]);

	async function handleSubmit() {
		await updateHeaderColor({ data: color || undefined, sponsor: sponsor });
		setIsEditing(false);
	}

	function toggleEdit() {
		const willBeEditing = !isEditing;
		setIsEditing(willBeEditing);
		if (!willBeEditing) {
			setColor(sponsor.headerColor ?? '');
		}
	}

	function ColorBox() {
		return (
			<ColorBoxStyled onClick={toggleEdit}>
				<div
					style={{
						width: '100%',
						height: '100%',
						backgroundColor: color || defaultColor,
					}}
				></div>
			</ColorBoxStyled>
		);
	}

	return (
		<Paper>
			{isEditing ? (
				<Container>
					<Progress>
						{isLoading && <LinearProgress color="secondary" />}
					</Progress>

					<div>
						<Wrapper>
							<Typography variant={'h4'} style={{ whiteSpace: 'nowrap' }}>
								Header Color
							</Typography>
							<ColorBox />
							<TextField
								label="Color"
								variant="outlined"
								fullWidth
								inputProps={{
									style: {
										borderBottom: 'none',
										paddingLeft: 20,
									},
								}}
								value={color}
								onChange={(event) => setColor(event.target.value)}
							/>
							<IconButton onClick={toggleEdit}>
								<Edit />
							</IconButton>
						</Wrapper>

						<ButtonContainer>
							<Button variant="contained" onClick={handleSubmit}>
								Save
							</Button>
						</ButtonContainer>
					</div>
				</Container>
			) : (
				<Container>
					<Uneditable>
						<Wrapper>
							<Typography variant={'h4'}>Header Color</Typography>
							<ColorBox />
							<span>{sponsor.headerColor}</span>
						</Wrapper>
						<IconButton size={'small'} onClick={() => setIsEditing(!isEditing)}>
							<Edit />
						</IconButton>
					</Uneditable>
				</Container>
			)}
		</Paper>
	);
};

export default UpdateHeaderColor;
