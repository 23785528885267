import { PERMISSIONS } from './Permissions';
import { useGrants } from './use-grants/useGrants';

type TPermissionsWrapperParams = {
	children: JSX.Element;
	scopes: string[];
	checkAllSponsorRoles?: boolean;
};

type THasPermissionParams = {
	scopes: string[];
	checkAllSponsorRoles?: boolean;
};

export const hasPermission = ({ scopes }: THasPermissionParams): boolean => {
	const { currentRoles: roles } = useGrants();

	if (!roles) return false;
	const permissions: string[] = [];
	roles.forEach((role) => permissions.push(...PERMISSIONS[role]));

	const scopesMap = {};
	scopes.forEach((scope) => {
		scopesMap[scope] = true;
	});

	return permissions.some((permission) => scopesMap[permission]);
};

const PermissionsWrapper = ({
	children,
	scopes,
}: TPermissionsWrapperParams): JSX.Element => {
	if (!hasPermission({ scopes })) return <></>;

	return <>{children}</>;
};

export default PermissionsWrapper;
