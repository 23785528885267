import { Box, Container, Toolbar, styled } from '@mui/material';
import { FC } from 'react';
import { OvalButton } from '../../common/components/OvalButton';

interface IAdminSidebar {
	onClick: any;
	options: string[];
	title: string;
}

const RootToolbar = styled(Toolbar)(() => ({
	width: '100%',
	display: 'flex',
	backgroundColor: '#d4d9ec',
	paddingLeft: '0px !important',
	paddingRight: '0px !important',
	maxWidth: '100%',
}));

const TitleBox = styled(Box)(() => ({
	paddingRight: 15,
	marginLeft: 5,
	fontSize: 18,
	marginBottom: 10,
}));

const ContentWrapper = styled(Container)(() => ({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: 0,
	alignItems: 'end',
	marginRight: 0,
	paddingLeft: '0px !important',
	paddingRight: '0px !important',
	paddingTop: 10,
	height: '100vh',
}));

const AdminSidebar: FC<IAdminSidebar> = ({ onClick, options, title }) => {
	return (
		<RootToolbar>
			<ContentWrapper>
				<TitleBox>{title}</TitleBox>
				<Box>
					{options.map((option, i) => (
						<OvalButton
							onClick={() => onClick(option)}
							label={option}
							id={i}
							selectedDocumentType={{
								id: 0,
								name: '',
							}}
							key={i}
						/>
					))}
				</Box>
			</ContentWrapper>
		</RootToolbar>
	);
};

export default AdminSidebar;
