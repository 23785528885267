import { FC } from 'react';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { Box, CircularProgress } from '@mui/material';
import { BrandedButton } from 'common/components/BrandedButton';
import { styled } from '@mui/system';

interface ICancelOrSaveProps {
	changesMade: boolean;
	handleSave: () => void;
	saving: boolean;
}

const SaveButton = styled(BrandedButton)(() => ({
	textTransform: 'inherit',
	width: 115,
	fontWeight: 'bold',
	fontSize: 14,
	margin: 2,
}));

const ButtonsBox = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-end',
}));

export const SaveTabButton: FC<ICancelOrSaveProps> = ({
	changesMade,
	handleSave,
	saving,
}) => {
	return (
		<PermissionsWrapper scopes={[SCOPES.canEditInvestorPermissions]}>
			<ButtonsBox>
				<SaveButton
					disabled={!changesMade}
					onClick={handleSave}
					variant="outlined"
					sx={{
						color: '#FFFFFF',
						'&.Mui-disabled': {
							backgroundColor: '#c3cfd9',
							color: 'white',
						},
					}}
				>
					{saving ? <CircularProgress size={20} /> : 'Save'}
				</SaveButton>
			</ButtonsBox>
		</PermissionsWrapper>
	);
};
