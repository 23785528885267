import { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	TextField,
	Box,
} from '@mui/material';
import { Sponsor } from 'app/types/ui/organization.types';
import handleEnterPressForDialogs from 'common/helperFunctions/ui/handleEnterPressForDialogs';
import { BrandedButton } from 'common/components/BrandedButton';

interface IDeleteSponsorModalProps {
	open: boolean;
	sponsor: Sponsor;
	handleSubmit: () => void;
	handleClose: () => void;
	isLoading: boolean;
}

//using react.FC here for consistency
//I really don't think this is needed, may even cause problems
//see: https://github.com/facebook/create-react-app/pull/8177
//need to touch base with rest of team on this one
export const DeleteSponsorModal: React.FC<IDeleteSponsorModalProps> = ({
	open,
	sponsor,
	handleSubmit,
	handleClose,
	isLoading = false,
}) => {
	const [name, setName] = useState<string>('');
	const [confirmed, setConfirmed] = useState<boolean>(false);
	const [incorrectEntry, setIncorrectEntry] = useState<boolean>(false);

	const handleConfirmed = () => {
		setConfirmed(true);
	};

	const checkNameAndHandleClose = async () => {
		if (name === sponsor.name) {
			await handleSubmit();
		} else {
			setIncorrectEntry(true);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				<strong>{'Confirm Deletion'}</strong>
			</DialogTitle>
			{sponsor && (
				<>
					<DialogContent sx={{ pt: 0, pb: 2 }}>
						<DialogContentText id="alert-dialog-description">
							Are you sure you want to permanently delete this sponsor
							<strong>{' ' + sponsor.name}</strong>?
							<br />
							<i>This action cannot be undone.</i>
						</DialogContentText>
					</DialogContent>
					{!confirmed && (
						<DialogActions>
							<BrandedButton onClick={handleClose}>Cancel</BrandedButton>
							<BrandedButton variant="contained" onClick={handleConfirmed}>
								Confirm
							</BrandedButton>
						</DialogActions>
					)}
					{confirmed && (
						<>
							<DialogContent sx={{ pt: 0, pb: 2 }}>
								<DialogContentText id="alert-dialog-description">
									Please type the name of this sponsor to confirm this deletion
								</DialogContentText>
							</DialogContent>
							{confirmed && incorrectEntry && (
								<DialogContent sx={{ pt: 0, pb: 2 }}>
									<DialogContentText id="alert-dialog-description">
										Sorry, that entry was incorrect! Please try again
									</DialogContentText>
								</DialogContent>
							)}
							<Box sx={{ pl: 3, pr: 3 }}>
								<TextField
									InputProps={{
										disableUnderline: true,
									}}
									autoFocus
									error={incorrectEntry}
									onKeyPress={(e) =>
										handleEnterPressForDialogs(e, checkNameAndHandleClose)
									}
									id="confirmed-secret-field"
									label={`Enter the name of your sponsor`}
									fullWidth
									variant="standard"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Box>
							<DialogActions sx={{ pb: 2, pt: 2 }}>
								<BrandedButton onClick={handleClose}>Cancel</BrandedButton>
								<BrandedButton
									type="submit"
									variant="contained"
									onClick={checkNameAndHandleClose}
									disabled={isLoading}
								>
									{isLoading ? 'Please wait...' : 'Delete'}
								</BrandedButton>
							</DialogActions>
						</>
					)}
				</>
			)}
			{!sponsor && (
				<>
					<DialogContent sx={{ pt: 0, pb: 2 }}>
						<DialogContentText id="alert-dialog-description">
							There was an error getting your sponsor and it can&apos;t be
							deleted at this time.
							<br />
							Please try again later
						</DialogContentText>
					</DialogContent>
				</>
			)}
		</Dialog>
	);
};
