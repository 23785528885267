import { ReactElement } from 'react';

export enum CASValues {
	NetOperating = 'Net Operating',
	RealizedInvestmentGain = 'Realized Investment Gain',
	UnrealizedInvestmentGain = 'Unrealized Investment Gain',
	ClosingCapitalAccount = 'Closing Capital Account',
}

export interface ICASSummaryProps {
	value: number;
	children: ReactElement;
}

export enum ECASTemplates {
	INCEPTION = 'PE - Inception',
	NO_INCEPTION = 'PE - No Inception',
	INCEPTION_WITH_T_S = 'PE - Inception with T&S',
	NO_INCEPTION_WITH_T_S = 'PE - No Inception with T&S',
	INCEPTION_WITH_CONTRIBUTIONS_RECEIVABLE = 'PE - Inception with Contributions Receivable',
	NO_INCEPTION_WITH_CONTRIBUTIONS_RECEIVABLE = 'PE - No Inception with Contributions Receivable',
}
