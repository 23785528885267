import { FC } from 'react';
import { ILabelBoxProps } from './types';
import { Box, Typography } from '@mui/material';
import { Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { styled } from '@mui/system';

const ContainerBox = styled(Box)(() => ({
	marginLeft: 'auto',
	marginRight: 'auto',
	marginTop: 15,
	marginBottom: 15,
	padding: 15,
}));

const UneditableBox = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-end',
	justifyContent: 'space-between',
}));

const Wrapper = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-end',
	gap: '1em',
}));

export const LabelBox: FC<ILabelBoxProps> = ({
	show,
	sponsor,
	onEditClick,
}) => {
	if (!show) return <></>;

	return (
		<ContainerBox>
			<UneditableBox>
				<Wrapper>
					<Typography variant={'h4'}>Watermark PDF&apos;s</Typography>
				</Wrapper>
				<Wrapper>
					<FormControlLabel
						disabled
						label={'Investor and Fund Documents'}
						id="investor-n-fund-docs-label"
						style={{
							padding: '10px 0px 0px 5px',
						}}
						control={
							<Checkbox
								checked={sponsor.watermarkPdf || false}
								aria-labelledby="investor-n-fund-docs-label"
							/>
						}
					/>
				</Wrapper>
				<Wrapper>
					<FormControlLabel
						disabled
						label={'Capital Account Statements'}
						id="cas-label"
						style={{
							padding: '10px 0px 0px 5px',
						}}
						control={
							<Checkbox
								checked={sponsor.watermarkCasPdf || false}
								aria-labelledby="cas-label"
							/>
						}
					/>
				</Wrapper>
				<IconButton size={'small'} onClick={onEditClick}>
					<Edit />
				</IconButton>
			</UneditableBox>
		</ContainerBox>
	);
};
