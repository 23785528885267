import { Container } from '@mui/material';
import { styled } from '@mui/system';

export const ContentSection = styled(Container)(() => ({
	alignItems: 'center',
	height: '100%',
	overflow: 'auto',
	padding: 30,
	position: 'relative',
}));

export const SideBarContainer = styled(Container)(() => ({
	width: '20.5rem',
	marginLeft: 0,
	marginRight: 0,
	paddingLeft: '0px !important',
	paddingRight: '0px !important',
	height: '100%',
	position: 'sticky',
}));

export const RootContainer = styled(Container)(() => ({
	display: 'flex',
	paddingLeft: '0px !important',
	paddingRight: '0px !important',
	marginRight: 0,
	marginTop: 0,
	maxWidth: '100vw',
	overflow: 'hidden',
	height: 'calc(100vh - 80px)',
}));
