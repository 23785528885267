import { Button } from '@mui/material';
import { DocumentType } from 'app/types/documentTypes.types';
import { MouseEventHandler } from 'react';
import { FC } from 'react';
import { styled } from '@mui/system';

interface IOvalButtonProps {
	label: string;
	onClick: MouseEventHandler<HTMLButtonElement>;
	selectedDocumentType: DocumentType;
	id: number;
}

const ButtonWrapper = styled(Button)(() => ({
	color: 'black',
	width: 230,
	fontSize: 16,
	justifyContent: 'right',
	flex: '1 0 auto',
	height: 62,
	textTransform: 'none',
	borderBottomLeftRadius: '25px 25px',
	borderTopLeftRadius: '25px 25px',
	'&:focus': {
		background: '#989898 !important',
	},
}));

const ButtonOval = styled('span')({
	width: 10,
	height: 40,
	borderBottomLeftRadius: '25px 25px',
	borderTopLeftRadius: '25px 25px',
	position: 'relative',
	left: 25,
});

const SpanWidth = styled('span')({
	width: 230,
	textAlign: 'right',
});

export const OvalButton: FC<IOvalButtonProps> = ({
	label,
	onClick,
	id,
	selectedDocumentType,
}) => {
	return (
		<ButtonWrapper
			sx={
				selectedDocumentType.name === label
					? { background: '#989898 !important' }
					: {
							'&:focus': {
								background: '#989898 !important',
							},
					  }
			}
			onClick={onClick}
			title={label}
		>
			<SpanWidth
				style={
					selectedDocumentType.name === label ? { textAlign: 'right' } : {}
				}
				id={id.toString()}
			>
				{label}
			</SpanWidth>
			<ButtonOval id={id.toString()} style={{ display: 'block' }} />
		</ButtonWrapper>
	);
};
