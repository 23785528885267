import { FC } from 'react';
import { Clear, Search } from '@mui/icons-material';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const SearchbarContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	paddingBottom: '5px',
	width: '300px !important',
	margin: '0 10px 0 0 !important',
}));

const SearchBarIcon = styled(Search)(() => ({
	cursor: 'pointer',
	marginRight: '10px',
}));

const CancelIcon = styled(Clear)(() => ({
	cursor: 'pointer',
	marginRight: '10px',
}));

const SearchBarInput = styled('input')(() => ({
	border: 'none',
	borderBottom: 'none !important',
	outline: 'none',
	margin: '0 30px 0 0 !important',
	flex: 1,
}));

interface ISearchBarProps {
	value: any;
	onChange: (val: string) => void;
	onCancelSearch: () => void;
}

export const SearchBar: FC<ISearchBarProps> = ({
	value,
	onChange,
	onCancelSearch,
}) => {
	return (
		<SearchbarContainer>
			<SearchBarInput
				type="text"
				value={value}
				onChange={(e) => {
					onChange(e.target.value);
				}}
				placeholder="Search..."
			/>
			{value ? <CancelIcon onClick={onCancelSearch} /> : <SearchBarIcon />}
		</SearchbarContainer>
	);
};
