import { ERoutes } from 'App';
import { useLocation } from 'react-router-dom';
import { FC, ReactElement, useEffect } from 'react';
import { SponsorDropdown } from './SponsorDropdown';
import { FundDropdown } from './FundDropdown';
import { InvestorDropdown } from './InvestorDropdown';
import { matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { FlexDirection } from '../types';
import { useUpdateLastLoginMutation } from 'api/redux/services/userApi';
import { useAuth0 } from '@auth0/auth0-react';
import { ROLES } from 'common/helpers/permissions/Roles';
import { styled } from '@mui/system';
import { AllUsersButton } from './AllUsersButton';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';
import { Box } from '@mui/material';

const DropdownsBox = styled(Box)({
	display: 'flex',
	flexDirection: FlexDirection.ROW,
	flexWrap: 'wrap',
	justifyContent: 'right',
});

export const DropDowns: FC = (): ReactElement => {
	const { pathname } = useLocation();
	const grants = useSelector(selectDropdown);
	const { currentRoles } = useGrants();
	const { availableSponsors, currentInvestor, currentFund, currentSponsor } =
		grants.grants;
	const { isAuthenticated, user } = useAuth0();
	const isSpecificDocumentPage = matchPath(pathname, ERoutes.SpecificDocument);
	const isBulkUploadUsersPage = matchPath(pathname, ERoutes.BulkUploadUsers);
	const isInvestorViewPage = matchPath(pathname, ERoutes.InvestorsView);
	const isOrganizationViewPage = matchPath(pathname, ERoutes.OrganizationView);
	const [updateLastLogin] = useUpdateLastLoginMutation();

	useEffect(() => {
		if (
			!isAuthenticated ||
			!user?.sub ||
			!currentRoles ||
			currentRoles.includes(ROLES.SUPER_ADMIN) ||
			!currentSponsor.id ||
			currentSponsor.id === 0
		)
			return;

		updateLastLogin({
			sponsorId: currentSponsor.id,
			fundId: currentFund.id,
			investorId: currentInvestor.id,
		});
	}, [
		currentFund.id,
		currentInvestor.id,
		currentSponsor.id,
		isAuthenticated,
		updateLastLogin,
		user?.sub,
	]);

	if (
		pathname.includes('admin') ||
		pathname.includes('Super Admin') ||
		!availableSponsors.length ||
		isSpecificDocumentPage ||
		isBulkUploadUsersPage ||
		isOrganizationViewPage
	)
		return <></>;

	if (isInvestorViewPage) {
		return (
			<DropdownsBox>
				<SponsorDropdown />
				<AllUsersButton />
				<InvestorDropdown />
			</DropdownsBox>
		);
	}

	return (
		<DropdownsBox>
			<SponsorDropdown />
			<FundDropdown />
			<InvestorDropdown />
		</DropdownsBox>
	);
};
