import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Badge,
	Box,
	Button,
	Grid,
	IconButton,
	List,
	ListItem,
	Typography,
} from '@mui/material';
import {
	selectNavbarTablet,
	onNavbarTablet,
} from 'api/redux/NavBarStateReducer';
import {
	IMenuButtonProps,
	standardMenuId,
	LENGTH_TO_HIDE_MY_ACCOUNT,
} from './types';
import { KeyboardArrowDown, More } from '@mui/icons-material';
import { styled } from '@mui/system';

const FlexBox = styled(Box)(() => ({
	display: 'flex',
}));

const TextButton = styled(Button)(() => ({
	textTransform: 'none',
}));

const NameListItem = styled(ListItem)(() => ({
	paddingTop: '2px !important',
	paddingBottom: '2px !important',
	textTransform: 'none',
}));

export const MenuButton: FC<IMenuButtonProps> = ({
	windowWidth,
	handleMenuOpen,
}) => {
	const dispatch = useDispatch();
	const tablet = useSelector(selectNavbarTablet);

	useEffect(() => {
		const isTablet = windowWidth < LENGTH_TO_HIDE_MY_ACCOUNT;
		dispatch(onNavbarTablet(isTablet));
	}, [dispatch, windowWidth]);

	if (tablet) {
		return (
			<FlexBox>
				<IconButton
					aria-label="show more"
					aria-controls={standardMenuId}
					aria-haspopup="true"
					onClick={handleMenuOpen}
					color="inherit"
				>
					<More style={{ color: '#FFFFFF' }} />
				</IconButton>
			</FlexBox>
		);
	}
	return (
		<>
			<FlexBox>
				<Grid container alignItems="center">
					<TextButton onClick={handleMenuOpen}>
						<Grid item>
							<List>
								<NameListItem>
									<Typography variant="h5" style={{ color: '#f1f2f9' }}>
										My Account
									</Typography>
								</NameListItem>
							</List>
						</Grid>
						<Grid item>
							<Badge color="secondary">
								<KeyboardArrowDown
									fontSize="large"
									style={{ color: '#f1f2f9' }}
								/>
							</Badge>
						</Grid>
					</TextButton>
				</Grid>
			</FlexBox>
		</>
	);
};
