export const mobileMenuId = 'primary-search-account-menu-mobile';
export const standardMenuId = 'primary-search-account-menu';

export interface IMenuProps {
	anchorEl: Element | (() => Element) | null | undefined;
	// anchorEl: Element | ((element: Element) => Element) | null | undefined;
	handleClose: () => void;
	isOpen: boolean;
	logout: () => void;
}

export interface IMenuButtonProps {
	windowWidth: number;
	handleMenuOpen: (event: React.BaseSyntheticEvent) => void;
}

export interface IDropDownsProps {
	dropdownsFlexDirection?: FlexDirection;
}

export enum FlexDirection {
	ROW = 'row',
	COLUMN = 'column',
}

export const DROPDOWNS_TOTAL_LENGTH = 900;
export const LENGTH_TO_HIDE_MY_ACCOUNT = 1200;
