import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Box,
	Dialog,
	DialogActions,
	DialogTitle,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import {
	useCreateInvestorMutation,
	useGetInvestorsBySponsorQuery,
	useSaveInvestorMutation,
} from 'api/redux/services/investors.service';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import {
	IEditInvestorModalProps,
	IExistingInvestorWarningProps,
} from './types';
import { useLocation } from 'react-router-dom';
import { InvestorAutoComplete } from 'modules/superadmin/dialogs/InvestorAutoComplete';
import { Alert } from '@mui/material';
import { validateInvestorId, validateName, validateNameType } from './utils';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { InvestorContactInfo } from './InvestorContactInfo';
import { ViewInvestorInfoButton } from './ViewInvestorInfoButton';
import { ERoutes } from 'App';
import { BrandedButton } from 'common/components/BrandedButton';
import { styled } from '@mui/system';

const ControlsBox = styled(Box)(() => ({
	marginTop: '2rem',
}));

const ExistingInvestorWarning: FC<IExistingInvestorWarningProps> = ({
	show,
}) => {
	if (!show) return <></>;

	return (
		<Alert severity="warning" sx={{ marginBottom: 2, marginTop: 2 }}>
			This investor exists in other funds. Changes made here will be reflected
			in all funds.
		</Alert>
	);
};

export const EditInvestorModal: FC<IEditInvestorModalProps> = ({
	isOpen,
	investor,
	onClose,
	afterSave,
	editContactInfo,
}) => {
	const grants = useSelector(selectDropdown);
	const { currentSponsor, currentFund } = grants.grants;
	const { pathname } = useLocation();
	const isInvestorPage = [ERoutes.InvestorsView].includes(pathname as ERoutes);
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const [name, setName] = useState<string>(investor?.name || '');
	const [nameError, setNameError] = useState<boolean>(false);
	const [investorId, setInvestorId] = useState<string>(
		investor?.investorId || '',
	);
	const [investorIdError, setInvestorIdError] = useState<boolean>(false);
	const [phone, setPhone] = useState<string>(investor?.phone || '');
	const [email, setEmail] = useState<string>(investor?.email || '');
	const [addressLine1, setAddressLine1] = useState<string>(
		investor?.addressLine1 || '',
	);
	const [addressLine2, setAddressLine2] = useState<string>(
		investor?.addressLine2 || '',
	);
	const [city, setCity] = useState<string>(investor?.city || '');

	const [selectedCountry, setSelectedCountry] = useState(
		investor?.country || '',
	);
	const [selectedState, setSelectedState] = useState(investor?.state || '');
	const [zipcode, setZipcode] = useState<string>(investor?.zipcode || '');
	const [saveInvestor] = useSaveInvestorMutation();
	const [createInvestor] = useCreateInvestorMutation();
	const { data: investors } = useGetInvestorsBySponsorQuery(currentSponsor.id);
	const [existingInvestorSelected, setExistingInvestorSelected] =
		useState(false);
	const isEditView = investor?.id !== undefined;

	const handleClickSave = async () => {
		await createOrUpdateInvestor();
		afterSave();
	};

	const handleUpdateInvestor = async () => {
		if (!investor) return;

		await saveInvestor({
			id: investor.id,
			name,
			investorId: investorId,
			phone,
			email,
			addressLine1,
			addressLine2,
			city,
			country: selectedCountry,
			state: selectedState,
			zipcode: zipcode === '' ? undefined : zipcode,
		});
	};

	const handleCreateInvestor = async () => {
		await createInvestor({
			name,
			phone,
			email,
			addressLine1,
			addressLine2,
			city,
			country: selectedCountry,
			state: selectedState,
			zipcode: zipcode === '' ? undefined : zipcode,
			fundId: currentFund.id,
			investorId: investorId,
		});
	};

	const createOrUpdateInvestor = async () => {
		if (isEditView) {
			await handleUpdateInvestor();
			return;
		}

		await handleCreateInvestor();
	};

	const handleChangeName = (e) => {
		if (e?.name) {
			setName(e.name);
			setInvestorId(e.investorId);
			return;
		}

		setName(e.target?.value || validateNameType(e));
	};

	const validateExists = () => {
		if (
			investors?.some(
				(inv) => inv.name === name || inv.investorId === investorId,
			)
		) {
			setExistingInvestorSelected(true);
			return;
		}
		setExistingInvestorSelected(false);
	};

	useEffect(() => {
		validateName({
			name,
			setNameError,
			investor: investor,
			investors,
			investorId,
		});
		validateInvestorId({
			investorId,
			setInvestorIdError,
			investor,
			investors,
			name,
			setInvestorId,
		});
		validateExists();
	}, [name, investorId, investors, investor?.id]);

	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>
				<Grid container spacing={2} style={{ alignContent: 'center' }}>
					<Grid item xs={10}>
						<Typography variant="h4">Investor Information</Typography>
					</Grid>
					<Grid item xs={2} style={{ textAlign: 'end' }}>
						<ViewInvestorInfoButton
							show={!editContactInfo && !isInvestorPage}
							investorId={investor?.id}
							onClick={onClose}
						/>
					</Grid>
				</Grid>
			</DialogTitle>
			<Box sx={{ pl: 3, pr: 3, pb: 3 }}>
				{isInvestorPage ? (
					<></>
				) : (
					<Grid container spacing={2} component={ControlsBox}>
						<Grid item xs={12}>
							{investor?.id ? (
								<TextField
									autoFocus={investor?.id === undefined}
									error={nameError}
									helperText={
										nameError ? 'Name already exists in this sponsor' : ''
									}
									label="Investor Name"
									variant="outlined"
									fullWidth
									inputProps={{
										style: {
											borderBottom: 'none',
											paddingLeft: 20,
										},
									}}
									value={name || ''}
									onChange={handleChangeName}
									hidden={true}
								/>
							) : (
								<InvestorAutoComplete
									investors={investors}
									handleChange={handleChangeName}
									error={nameError}
									helperText={
										nameError ? 'Name already exists in this sponsor' : ''
									}
								/>
							)}
						</Grid>
						<Grid item xs={6}>
							<TextField
								error={investorIdError}
								helperText={
									investorIdError
										? 'Investor Id already exists in this sponsor'
										: ''
								}
								label="Investor Id"
								variant="outlined"
								fullWidth
								inputProps={{
									style: {
										borderBottom: 'none',
										paddingLeft: 20,
									},
								}}
								value={investorId || ''}
								onChange={(e) => setInvestorId(e.target.value)}
							/>
						</Grid>
					</Grid>
				)}
				<ExistingInvestorWarning
					show={
						investor?.funds?.length !== undefined && investor?.funds?.length > 1
					}
				/>
				<InvestorContactInfo
					show={
						isInvestorPage ||
						(!isInvestorPage && !existingInvestorSelected) ||
						(editContactInfo && !existingInvestorSelected)
					}
					phone={phone}
					setPhone={setPhone}
					email={email}
					setEmail={setEmail}
					addressLine1={addressLine1}
					setAddressLine1={setAddressLine1}
					addressLine2={addressLine2}
					setAddressLine2={setAddressLine2}
					city={city}
					setCity={setCity}
					selectedState={selectedState}
					setSelectedState={setSelectedState}
					selectedCountry={selectedCountry}
					setSelectedCountry={setSelectedCountry}
					zipcode={zipcode}
					setZipcode={setZipcode}
				/>
			</Box>
			<DialogActions>
				<BrandedButton onClick={onClose} width="10rem">
					Cancel
				</BrandedButton>
				<BrandedButton
					onClick={handleClickSave}
					width="10rem"
					variant="outlined"
					disabled={!name || nameError || investorIdError}
				>
					Save
				</BrandedButton>
			</DialogActions>
		</Dialog>
	);
};
