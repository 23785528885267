import { SCOPES } from '../Scopes';

export const InvestorAdminPermissions: SCOPES[] = [
	/** Side Menu Scopes */
	SCOPES.canSeeInvestorTab,
	SCOPES.canSeeInvestorUsersOption,
	SCOPES.canSeeReportsMenuOption,

	/** Investor scopes */
	SCOPES.canDeleteInvestor,
	SCOPES.canEditInvestorPermissions,
	SCOPES.canSeeInvestorPermissionsColumn,
	SCOPES.canSeeInvestorLastLoginColumn,
	SCOPES.canSeeInvestorInfo,
	SCOPES.canEditInvestorInfo,

	/** User scopes */
	SCOPES.canCreateUsers,
	SCOPES.canDeleteUsers,
];
