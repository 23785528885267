import { styled } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

export default forwardRef((props: any, ref) => {
	const [selectedIndex, setSelectedIndex] = useState(0);

	const selectItem = (index) => {
		const item = props.items[index];

		if (item) {
			props.command({ id: item });
		}
	};

	const upHandler = () => {
		setSelectedIndex(
			(selectedIndex + props.items.length - 1) % props.items.length,
		);
	};

	const downHandler = () => {
		setSelectedIndex((selectedIndex + 1) % props.items.length);
	};

	const enterHandler = () => {
		selectItem(selectedIndex);
	};

	useEffect(() => setSelectedIndex(0), [props.items]);

	useImperativeHandle(ref, () => ({
		onKeyDown: ({ event }) => {
			if (event.key === 'ArrowUp') {
				upHandler();
				return true;
			}

			if (event.key === 'ArrowDown') {
				downHandler();
				return true;
			}

			if (event.key === 'Enter' || event.key === 'Tab') {
				enterHandler();
				return true;
			}

			return false;
		},
	}));

	const DropdownMenu = styled('div')(() => ({
		background: 'white',
		border: '2px solid darkgray',
		borderRadius: '0.7rem',
		boxShadow: 'var(--shadow)',
		display: 'flex',
		flexDirection: 'column',
		gap: '0.1rem',
		overflow: 'auto',
		padding: '0.4rem',
		position: 'relative',
	}));

	const MenuButton = styled('button')(() => ({
		alignItems: 'center',
		backgroundColor: 'buttonface',
		border: '1px solid var(--gray-1)',
		borderRadius: '0.2rem',
		display: 'flex',
		gap: '0.25rem',
		textAlign: 'left',
		width: '100%',
		'&:hover, &:hover.is-selected': {
			background: 'var(--gray-3)',
		},
		'&.is-selected': {
			background: 'var(--gray-2)',
		},
	}));

	return (
		<DropdownMenu>
			{props.items.length ? (
				props.items.map((item, index) => (
					<MenuButton
						className={index === selectedIndex ? 'is-selected' : ''}
						key={index}
						onClick={() => selectItem(index)}
					>
						{item}
					</MenuButton>
				))
			) : (
				<div className="item">No result</div>
			)}
		</DropdownMenu>
	);
});
