import { createSlice, Slice } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface IRedirectURLState {
	redirectURL?: string;
}

const initialState: IRedirectURLState = {
	redirectURL: undefined,
};

export const redirectURLSlice: Slice = createSlice({
	name: 'redirectURL',
	initialState,
	reducers: {
		onSetRedirectURL: (state, action) => {
			state.redirectURL = action.payload;
		},
		onRemoveRedirectURL: (state) => {
			state.redirectURL = undefined;
		},
	},
});

export const { onSetRedirectURL, onRemoveRedirectURL } =
	redirectURLSlice.actions;

export const redirectURLState = ({ redirectURL }: RootState): string =>
	redirectURL.redirectURL;

export default redirectURLSlice.reducer;
