import { FC } from 'react';
import { Alert, Box } from '@mui/material';
import { IErrorMessageProps } from './types';

export const ErrorMessage: FC<IErrorMessageProps> = ({ error, setError }) => {
	if (!error) return <></>;

	return (
		<Box sx={{ pl: 3, pr: 3, pb: 3 }}>
			<Alert variant="filled" onClose={() => setError('')} severity="error">
				{error}
			</Alert>
		</Box>
	);
};
