import { FC, useEffect } from 'react';
import './app.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navbar } from './common/components/navbar/Navbar';
import { Dashboard } from './modules/myLpx/Dashboard';
import { Documents } from './modules/documents/Documents';
import { Reports } from './modules/reports/ui/Reports';
import { InvestorView } from 'modules/investor-view/InvestorView';
import AuthWrapper from 'modules/login/auth0/authWrapper';
import { Auth0Provider } from '@auth0/auth0-react';
import { useConfig } from 'api/redux/services/config';
import { OrganizationPage } from 'modules/superadmin/OrganizationPage';
import { FundPage } from 'modules/superadmin/FundPage';
import { AdminPage } from 'modules/admin/Admin';
import InactivityWrapper from 'modules/login/auth0/inactivity';
import HelpPage from 'modules/settings/HelpPage';
import ProtectedRoute from 'modules/ProtectedRoute';
import PrivateRoute from 'modules/PrivateRoute';
import SpecificDocument from 'modules/documents/SpecificDocument';
import { ROLES } from 'common/helpers/permissions/Roles';
import GrantsProtectedRoute from 'modules/GrantsProtectedRoute';
import OrganizationView from 'modules/organization-view/OrganizationView';
import { BulkUploadUsers } from 'modules/bulk-upload-users/BulkUploadUsers';
import { CasReports } from 'modules/reports/cas-reports/CasReports';
import { ScreenLoggerWrapper } from 'common/components/ScreenLoggerWrapper';

export enum ERoutes {
	Dashboard = '/',
	SpecificDocument = '/documents/:documentId',
	Documents = '/documents',
	Reports = '/reports',
	CasReports = '/cas-reports',
	Help = '/help',
	OrganizationView = '/organization-view',
	InvestorsView = '/investors-view',
	BulkUploadUsers = '/organizations/bulk-upload-users',
	Fund = '/fund',
	Admin = '/admin',
	OrganizationPage = '/superadmin/sponsors',
	FundPage = '/superadmin/sponsors/funds',
	BulkUpload = '/superadmin/sponsors/:organizationId/funds/:fundId/bulkUpload',
}

const App: FC = () => {
	const { data: config, isLoading, isError, error } = useConfig();
	const auth0OrgId =
		new URLSearchParams(window.location.search).get('auth0OrgId') || undefined;
	const orgId =
		new URLSearchParams(window.location.search).get('orgId') || undefined;

	useEffect(() => {
		if (orgId) {
			sessionStorage.setItem('initialSponsorId', orgId);
		}
	}, [orgId]);

	if (isError)
		return <>Error fetching configuration: {JSON.stringify(error)}</>;
	if (isLoading || !config) return <>Loading...</>;

	const organizationLpRoles = [
		ROLES.SUPER_ADMIN,
		ROLES.SPONSOR_ADMIN,
		ROLES.SPONSOR_USER,
	];
	const investorViewRoles = [
		ROLES.SUPER_ADMIN,
		ROLES.SPONSOR_ADMIN,
		ROLES.SPONSOR_USER,
		ROLES.INVESTOR_ADMIN,
	];
	const adminPageRoles = [ROLES.SUPER_ADMIN, ROLES.SPONSOR_ADMIN];

	return (
		<Auth0Provider
			domain={config.auth0.domain}
			clientId={config.auth0.clientId}
			redirectUri={config.auth0.redirectUri}
			audience={config.auth0.audience}
			organization={auth0OrgId}
		>
			<AuthWrapper>
				<BrowserRouter>
					<ScreenLoggerWrapper />
					<InactivityWrapper />
					<Navbar />
					<Routes>
						<Route path={ERoutes.Dashboard} element={<Dashboard />} />
						<Route
							path={ERoutes.SpecificDocument}
							element={
								<PrivateRoute>
									<SpecificDocument />
								</PrivateRoute>
							}
						/>
						<Route
							path={ERoutes.Documents}
							element={
								<PrivateRoute>
									<Documents />
								</PrivateRoute>
							}
						/>
						<Route path={ERoutes.CasReports} element={<CasReports />} />
						<Route path={ERoutes.Reports} element={<Reports />} />
						<Route path={ERoutes.Help} element={<HelpPage />} />

						{/* LP view */}
						<Route
							path={ERoutes.OrganizationView}
							element={
								<ProtectedRoute
									roles={organizationLpRoles}
									errorMessage="You do not have permission to view this sponsor."
								>
									<OrganizationView />
								</ProtectedRoute>
							}
						/>
						<Route
							path={ERoutes.InvestorsView}
							element={
								<ProtectedRoute
									roles={investorViewRoles}
									errorMessage="You do not have permission to view this investor."
								>
									<InvestorView />
								</ProtectedRoute>
							}
						/>
						<Route
							path={ERoutes.BulkUploadUsers}
							element={
								<ProtectedRoute
									roles={[ROLES.SUPER_ADMIN, ROLES.SPONSOR_ADMIN]}
								>
									<BulkUploadUsers />
								</ProtectedRoute>
							}
						/>
						<Route
							path={ERoutes.Admin}
							element={
								<ProtectedRoute
									roles={[ROLES.SUPER_ADMIN, ROLES.SPONSOR_ADMIN]}
									errorMessage="You do not have administrator access for this sponsor."
								>
									<AdminPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path={ERoutes.OrganizationPage}
							element={
								<GrantsProtectedRoute roles={adminPageRoles}>
									<OrganizationPage />
								</GrantsProtectedRoute>
							}
						/>
						<Route
							path={ERoutes.FundPage}
							element={
								<GrantsProtectedRoute roles={adminPageRoles}>
									<FundPage />
								</GrantsProtectedRoute>
							}
						/>
					</Routes>
				</BrowserRouter>
			</AuthWrapper>
		</Auth0Provider>
	);
};

export default App;
