import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { BrandedButton } from 'common/components/BrandedButton';
import { FC } from 'react';

interface IDateDialog {
	open: boolean;
	handleClose: () => void;
	handleAccept: () => void;
}

export const FundLevelDialog: FC<IDateDialog> = ({
	open,
	handleClose,
	handleAccept,
}) => {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="fund-dialog-title"
			aria-describedby="fund-dialog-description"
		>
			<DialogTitle id="fund-dialog-title">Are You Sure?</DialogTitle>
			<DialogContent>
				<DialogContentText id="fund-dialog-description">
					You selected Fund Level, are you sure you want this upload to be
					viewable by all investors in this fund?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<BrandedButton onClick={handleClose} variant="outlined">
					Cancel
				</BrandedButton>
				<BrandedButton onClick={handleAccept} variant="contained">
					Confirm
				</BrandedButton>
			</DialogActions>
		</Dialog>
	);
};
