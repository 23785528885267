import { useState, useEffect, SetStateAction, FC } from 'react';
import { Box, Container, Grid, Typography, Card } from '@mui/material';
import { Link } from 'react-router-dom';
import numberFormat from 'common/helpers/Math';
import { selectDropdown } from 'api/redux/DropdownReducer';
import {
	selectSponsorPrimaryColor,
	defaultColor,
} from 'api/redux/siteColorReducer';
import { useSelector } from 'react-redux';
import { IActivitySummaryProps } from './DashboardTypes';
import ICapitalActivity, {
	ICapitalActivityData,
} from 'modules/reports/CapitalAccountStatement/capital-activity.types';
import { CASValues, ICASSummaryProps } from './types';
import { VisibilityOutlined } from '@mui/icons-material';
import { styled } from '@mui/system';

const ActivitySummaryBox = styled(Box)({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
});

const HeaderContainer = styled(Box)({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	flexGrow: 1,
	padding: 0,
});

const ViewMoreDetailsContainer = styled(Box)({
	padding: 0,
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-end',
});

const ViewMoreDetailsItems = styled(Box)({
	padding: 0,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
});

const ViewMoreDetailsIcon = styled(VisibilityOutlined)({
	color: defaultColor,
});

const ViewMoreDetailsText = styled(Typography)({
	color: defaultColor,
});

const SummaryGrid = styled(Grid)({
	flexGrow: 19,
});

const SummaryCard = styled(Card)({
	margin: 5,
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
});

const CapitalActivityHeader = styled(Typography)({
	fontSize: '1rem',
	fontWeight: 500,
	color: '#1F1F1F',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

const CapitalActivitySubHeader = styled(Typography)({
	fontSize: '1rem',
	fontWeight: 500,
	color: '#1F1F1F',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginBottom: '1rem',
});

const CapitalActivityContent = styled(Typography)(({ theme }) => ({
	fontSize: '1.6rem',
	fontWeight: 400,
	color: theme.palette.primary.main,
	display: 'flex',
	justifyContent: 'center',
}));

const PeriodEndingText = styled(Typography)({
	fontSize: '1rem',
	fontWeight: 400,
	color: '#1F1F1F',
	display: 'flex',
});

export const ActivitySummary: FC<IActivitySummaryProps> = ({
	capitalActivityData,
}) => {
	const grants = useSelector(selectDropdown);
	const { currentInvestor, currentFund, currentSponsor } = grants.grants;
	const [netUnrealized, setNetUnrealized] = useState<number>(0);
	const [operatingCosts, setOperatingCosts] = useState<number>(0);
	const [realizedCosts, setRealizedCosts] = useState<number>(0);
	const [capitalValue, setCapitalValue] = useState<number>(0);
	const [reportingPeriod, setReportingPeriod] = useState<string>('');

	const setCASValue = (
		casData: ICapitalActivity,
		casValue: CASValues,
		setData: (value: SetStateAction<number>) => void,
		checkYTD = true,
	) => {
		const attrData: ICapitalActivityData[] = casData.attributeData.filter(
			(attribute) =>
				attribute.name.includes(casValue) &&
				(!checkYTD || (checkYTD && attribute.name.includes('(YTD)'))) &&
				attribute.value !== '',
		);

		const value: number = attrData
			.map((entry) => parseInt(entry.value))
			.reduce((prev, next) => prev + next, 0);

		setData(value);
	};

	const printValue = (value: number): string => {
		return Math.abs(value) !== 0 ? numberFormat(value) : '-';
	};

	useEffect(() => {
		if (
			currentInvestor.id === 0 ||
			currentFund.id === 0 ||
			currentSponsor.id === 0 ||
			!capitalActivityData ||
			capitalActivityData.length === 0
		) {
			setNetUnrealized(0);
			setOperatingCosts(0);
			setRealizedCosts(0);
			setCapitalValue(0);
		}

		const [casData]: ICapitalActivity[] = capitalActivityData;
		if (!casData) return;

		setReportingPeriod(casData.reportingPeriod);
		setCASValue(casData, CASValues.NetOperating, setOperatingCosts);
		setCASValue(casData, CASValues.RealizedInvestmentGain, setRealizedCosts);
		setCASValue(casData, CASValues.UnrealizedInvestmentGain, setNetUnrealized);
		setCASValue(
			casData,
			CASValues.ClosingCapitalAccount,
			setCapitalValue,
			false,
		);
	}, [capitalActivityData, currentFund.id, currentInvestor.id, currentSponsor]);

	const ViewFullStatement: FC = () => {
		const sponsorColor = useSelector(selectSponsorPrimaryColor);

		return (
			<ViewMoreDetailsContainer>
				<Link to="/reports">
					<ViewMoreDetailsItems>
						<ViewMoreDetailsIcon sx={{ color: `${sponsorColor}` }} />
						<ViewMoreDetailsText
							align="center"
							variant="h6"
							style={{ color: `${sponsorColor}`, padding: 5 }}
						>
							View Capital Account Statements
						</ViewMoreDetailsText>
					</ViewMoreDetailsItems>
				</Link>
			</ViewMoreDetailsContainer>
		);
	};

	const NetOperatingIncomeLabel: FC = () => {
		return (
			<>
				<CapitalActivityHeader align="center">
					Net Operating
				</CapitalActivityHeader>
				<CapitalActivitySubHeader align="center">
					Income
				</CapitalActivitySubHeader>
			</>
		);
	};

	const NetUnrealizedGainLabel: FC = () => {
		return (
			<>
				<CapitalActivityHeader align="center">
					Net Unrealized
				</CapitalActivityHeader>
				<CapitalActivitySubHeader align="center">
					Gain/Loss
				</CapitalActivitySubHeader>
			</>
		);
	};

	const NetRealizedInvestmentLabel: FC = () => {
		return (
			<>
				<CapitalActivityHeader align="center">
					Net Realized
				</CapitalActivityHeader>
				<CapitalActivitySubHeader align="center">
					Gain/Loss
				</CapitalActivitySubHeader>
			</>
		);
	};

	const CapitalAccountValueLabel: FC = () => {
		return (
			<>
				<CapitalActivityHeader align="center">
					Capital Account
				</CapitalActivityHeader>
				<CapitalActivitySubHeader align="center">
					Value
				</CapitalActivitySubHeader>
			</>
		);
	};

	const CASSummaryCard: FC<ICASSummaryProps> = ({ value, children }) => {
		const sponsorColor = useSelector(selectSponsorPrimaryColor);

		return (
			<SummaryCard elevation={0}>
				{children}
				<CapitalActivityContent
					style={{ color: `${sponsorColor}` }}
					align="center"
					variant="h6"
				>
					{printValue(value)}
				</CapitalActivityContent>
			</SummaryCard>
		);
	};

	const Title: FC = () => {
		return (
			<HeaderContainer>
				<Grid container direction={'row'}>
					<Grid item xs={6} md={6}>
						<Typography variant="h4">Capital Account Summary</Typography>
					</Grid>
					<Grid item xs={6} md={6}>
						<ViewFullStatement />
					</Grid>
				</Grid>
				<PeriodEndingText>
					{capitalActivityData &&
					capitalActivityData.length > 0 &&
					reportingPeriod !== ''
						? `For the ${reportingPeriod}`
						: '-'}
				</PeriodEndingText>
			</HeaderContainer>
		);
	};

	const SummaryCards: FC = () => {
		return (
			<SummaryGrid container>
				<Grid item xs={3}>
					<CASSummaryCard value={operatingCosts}>
						<NetOperatingIncomeLabel />
					</CASSummaryCard>
				</Grid>
				<Grid item xs={3}>
					<CASSummaryCard value={netUnrealized}>
						<NetUnrealizedGainLabel />
					</CASSummaryCard>
				</Grid>
				<Grid item xs={3}>
					<CASSummaryCard value={realizedCosts}>
						<NetRealizedInvestmentLabel />
					</CASSummaryCard>
				</Grid>
				<Grid item xs={3}>
					<CASSummaryCard value={capitalValue}>
						<CapitalAccountValueLabel />
					</CASSummaryCard>
				</Grid>
			</SummaryGrid>
		);
	};

	return (
		<ActivitySummaryBox>
			<Title />
			<SummaryCards />
		</ActivitySummaryBox>
	);
};

export default ActivitySummary;
