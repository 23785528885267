import { InputLabel, Paper } from '@mui/material';
import { styled } from '@mui/system';

export const NotificationText = styled(InputLabel)(() => ({
	margin: 10,
	display: 'inline !important',
}));

export const PaperBox = styled(Paper)(() => ({
	height: 'max-content',
	width: '100%',
	marginTop: 16,
	marginBottom: 32,
	borderRadius: 5,
	padding: 16,
}));
