import { Checkbox } from '@mui/material';
import { selectDropdown } from 'api/redux/DropdownReducer';
import {
	IPermissionsMutationResponse,
	useLazyGetInvestorFundsQuery,
	useSaveInvestorUserFundsPermissionsMutation,
} from 'api/redux/services/investors.service';
import { UserInvestor } from 'app/types/user-investor.types';
import { hasPermission } from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useSelector } from 'react-redux';
import { ConfirmationModal } from './ConfirmationModal';
import { SaveTabButton } from './SaveTabButton';
import { FormWrapper, InputWrapper, LabelBox, LabelWrapper } from './styles';
import { SelectAllOption } from 'common/components/SelectAllOption';

interface IFundsTabProps {
	userId: number;
	investorId: number;
	userInvestorPermissions: UserInvestor;
	setPermissions: Dispatch<SetStateAction<UserInvestor | undefined>>;
	changesMade: boolean;
	setChangesMade: Dispatch<SetStateAction<boolean>>;
	showConfirmation: boolean;
	handleClose: () => void;
	handleAfterSave: () => void;
}

export const FundsTab: FC<IFundsTabProps> = ({
	investorId,
	userId,
	userInvestorPermissions,
	setPermissions,
	changesMade,
	setChangesMade,
	showConfirmation,
	handleClose,
	handleAfterSave,
}) => {
	const grants = useSelector(selectDropdown);
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const { currentSponsor } = grants.grants;
	const [fetchFunds, funds] = useLazyGetInvestorFundsQuery();
	const [saveUserInvestorFunds, response] =
		useSaveInvestorUserFundsPermissionsMutation();
	const [selectedFundIds, setSelectedFundIds] = useState<number[]>([]);

	useEffect(() => {
		if (currentSponsor.id == 0 || !investorId) return;

		fetchFunds({
			investorId: investorId,
			sponsorId: currentSponsor.id,
		});
	}, [currentSponsor.id, fetchFunds, investorId]);

	useEffect(() => {
		setSelectedFundIds(userInvestorPermissions?.funds?.map((t) => t.id) || []);
	}, [userInvestorPermissions]);

	const canEditInvestorPermissions = hasPermission({
		scopes: [SCOPES.canEditInvestorPermissions],
	});

	const handleSave = async () => {
		const newPermissions: IPermissionsMutationResponse =
			await saveUserInvestorFunds({
				userId,
				investorId,
				fundIds: selectedFundIds.join(','),
				sponsorId: currentSponsor.id,
			});

		if ('data' in newPermissions) {
			setPermissions(newPermissions.data);
		}

		handleAfterSave();
	};

	const handleChange = (fundId: number) => {
		setChangesMade(true);
		if (selectedFundIds.includes(fundId)) {
			setSelectedFundIds(selectedFundIds.filter((id) => id !== fundId));
			return;
		}

		setSelectedFundIds([...selectedFundIds, fundId]);
	};

	return (
		<>
			<InputWrapper elevation={0}>
				<FormWrapper>
					{funds.data && (
						<SelectAllOption
							selectedIds={selectedFundIds}
							availableIds={funds.data.map((f) => f.id)}
							setSelectedIds={setSelectedFundIds}
							onChange={() => setChangesMade(true)}
						/>
					)}
					{funds.data &&
						funds.data.map((fund, i) => {
							return (
								<LabelWrapper
									key={i}
									style={{ fontSize: '12px' }}
									control={
										<Checkbox
											size="small"
											disabled={!canEditInvestorPermissions}
											onChange={() => handleChange(fund.id)}
											checked={selectedFundIds.includes(fund.id) || false}
											style={{
												color: sponsorColor,
												padding: 3,
												marginLeft: 5,
											}}
										/>
									}
									label={<LabelBox>{fund.name}</LabelBox>}
								></LabelWrapper>
							);
						})}
				</FormWrapper>
				<ConfirmationModal
					isOpen={showConfirmation}
					onAccept={handleSave}
					onCancel={handleClose}
					cancelText="Discard"
				/>
			</InputWrapper>
			<SaveTabButton
				changesMade={changesMade}
				handleSave={handleSave}
				saving={response.isLoading}
			/>
		</>
	);
};
