import { createSlice, Slice } from '@reduxjs/toolkit';
import { RootState } from './store';

export const defaultColor = '#113560';

export interface ISiteColorState {
	primaryColor: string;
}

const initialState: ISiteColorState = {
	primaryColor: defaultColor,
};

export const siteColorSlice: Slice = createSlice({
	name: 'siteColor',
	initialState,
	reducers: {
		onChangeSponsorPrimaryColor: (state, action) => {
			state.primaryColor = action.payload || defaultColor;
		},
	},
});

export const { onChangeSponsorPrimaryColor } = siteColorSlice.actions;

export const selectSponsorPrimaryColor = ({ siteColor }: RootState): string =>
	siteColor.primaryColor;

export default siteColorSlice.reducer;
