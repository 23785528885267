import { FC } from 'react';
import { IExportButtonProps } from './types';
import { Box, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { BrandedButton } from 'common/components/BrandedButton';

const ExportButtonBox = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'flex-end',
	marginTop: '2rem',
	gap: '5px',
}));

export const ExportButton: FC<IExportButtonProps> = ({
	exportDisabled,
	onExportClick,
}) => {
	return (
		<ExportButtonBox>
			<Tooltip
				title={
					exportDisabled
						? 'Please select at least 1 role, column and fund to start exporting'
						: ''
				}
			>
				<span>
					<BrandedButton
						disabled={exportDisabled}
						onClick={onExportClick}
						variant="outlined"
					>
						Export
					</BrandedButton>
				</span>
			</Tooltip>
		</ExportButtonBox>
	);
};
