import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { UsersTable } from 'common/components/UsersTable/UsersTable';
import { useLazyGetInvestorUsersQuery } from 'api/redux/services/investors.service';
import { onSetUsers, usersState } from 'api/redux/UsersReducer';
import { styled } from '@mui/system';
import { useLazyGetOrganizationUsersQuery } from 'api/redux/services/organizations.service';
import { RoleLevels } from 'common/helpers/permissions/Roles';

const InvestorMemberPaper = styled(Paper)(() => ({
	width: 'min(100%, 1300px)',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: '#FFFFFF',
	marginLeft: 'auto',
	marginRight: 'auto',
	borderRadius: '5px',
	marginTop: '8px',
}));

export const InvestorMembers: FC = () => {
	const dispatch = useDispatch();
	const { grants, showAllUsers } = useSelector(selectDropdown);
	const [getInvestorUsers, investorUsers] = useLazyGetInvestorUsersQuery();
	const [getOrganizationUsers, organizationUsers] =
		useLazyGetOrganizationUsersQuery();
	const { users, sortStrategy } = useSelector(usersState);
	const [page, setPage] = useState<number>(0);
	const [pages, setPages] = useState<number>(1);
	const [loading, setLoading] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>('');
	const { currentInvestor, currentSponsor } = grants;

	const fetchInvestorUsers = () => {
		getInvestorUsers({
			investorId: currentInvestor.id,
			sponsorId: currentSponsor.id,
			page,
			searchString,
			sortStrategy: sortStrategy,
		});
	};

	const fetchAllUsers = () => {
		getOrganizationUsers({
			sponsorId: currentSponsor.id,
			page,
			searchString,
			sortStrategy: sortStrategy,
			roleLevel: RoleLevels.INVESTOR,
		});
	};

	useEffect(() => {
		if (currentInvestor.id !== 0 && !showAllUsers) {
			setLoading(true);
			fetchInvestorUsers();
			return;
		}

		if (showAllUsers) {
			setLoading(true);
			fetchAllUsers();
			return;
		}

		dispatch(onSetUsers([]));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentSponsor.id,
		currentInvestor.id,
		searchString,
		page,
		sortStrategy,
		showAllUsers,
	]);

	useEffect(() => {
		if (investorUsers.data) {
			const { rows: fetchedUsers, pages } = investorUsers.data;
			dispatch(onSetUsers(fetchedUsers));
			setPages(pages);
		}
	}, [dispatch, investorUsers]);

	useEffect(() => {
		if (organizationUsers.data) {
			const { rows: fetchedUsers, pages } = organizationUsers.data;
			dispatch(onSetUsers(fetchedUsers));
			setPages(pages);
		}
	}, [dispatch, organizationUsers]);

	useEffect(() => {
		const { isError, isSuccess } = investorUsers;
		const { isError: isErrorOrg, isSuccess: isSuccessOrg } = organizationUsers;

		if (isError || isSuccess || isErrorOrg || isSuccessOrg) setLoading(false);
	}, [investorUsers, organizationUsers]);

	if (!users) return <></>;

	return (
		<InvestorMemberPaper elevation={0}>
			<UsersTable
				refetchUsers={showAllUsers ? fetchAllUsers : fetchInvestorUsers}
				setPage={setPage}
				pages={pages}
				loading={loading}
				searchString={searchString}
				setSearchString={setSearchString}
			/>
		</InvestorMemberPaper>
	);
};
