import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useIdleTimer } from 'react-idle-timer';
import { useCookies } from 'react-cookie';
import { getCookie } from 'common/helpers/Cookies';

export const InactivityWrapper: React.FC = () => {
	const { logout, user } = useAuth0();
	const tenMins = 600000;

	const [, , removeCookies] = useCookies([
		'fundId',
		'sponsorId',
		'investorId',
		'subdomain',
	]);

	const inactiveLogout = () => {
		if (user) {
			const url = new URL(window.location.origin);
			const subdomain = getCookie('subdomain');
			if (subdomain) {
				url.searchParams.append('subdomain', subdomain);
			}

			logout({ returnTo: url.toString() });
			removeCookies('fundId', { path: '/' });
			removeCookies('sponsorId', { path: '/' });
			removeCookies('investorId', { path: '/' });
			removeCookies('subdomain', { path: '/' });
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { getRemainingTime, getLastActiveTime } = useIdleTimer({
		timeout: tenMins,
		onIdle: inactiveLogout,
		debounce: 500,
		crossTab: true,
	});

	return <></>;
};

export default InactivityWrapper;
