import { FC } from 'react';
import { SelectInvestor } from './SelectInvestor';
import { UserRow } from './user-row/UserRow';
import { TitleBar } from './TitleBar';
import { Grid } from '@mui/material';
import { IUserPermissionsHeaderProps } from './types';

export const UserPermissionsHeader: FC<IUserPermissionsHeaderProps> = ({
	user,
	investors,
	selectedInvestorId,
	setSelectedInvestorId,
	handleClose,
	onEditUserClick,
	handleReload,
	onClose,
}) => {
	return (
		<Grid container direction="column" spacing={1}>
			<Grid item>
				<TitleBar onClose={handleClose} />
			</Grid>
			<Grid item>
				<UserRow
					onClose={(cancel) => onClose(cancel)}
					user={user}
					selectedInvestorId={selectedInvestorId}
					handleReload={handleReload}
				/>
			</Grid>
			<Grid item>
				<SelectInvestor
					investors={investors}
					selectedInvestorId={selectedInvestorId}
					setSelectedInvestorId={setSelectedInvestorId}
					onEditUserClick={onEditUserClick}
					onClose={() => handleClose()}
				/>
			</Grid>
		</Grid>
	);
};
