import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ISponsor } from 'app/types/sponsor.types';
import { Dispatch, SetStateAction } from 'react';

export interface IUploadLogoProps {
	sponsor: ISponsor;
}

interface IFormData {
	formData: FormData;
}

export interface IUploadLogoCardProps {
	sponsor: ISponsor;
	logoType: ELogoTypes;
	uploadMethod: ({ formData }: IFormData) => void;
	uploadError: FetchBaseQueryError | SerializedError | undefined;
}

export enum ELogoTypes {
	LIGHT = 'light',
	DARK = 'dark',
}

export interface ILogoPreviewProps {
	sponsorId: number;
	visible: boolean;
	logoType: ELogoTypes;
}

export interface IDropAreaProps {
	visible: boolean;
	uploadMethod: ({ formData }: IFormData) => void;
	sponsor: ISponsor;
	setIsUploading: Dispatch<SetStateAction<boolean>>;
	setUploadFinished: Dispatch<SetStateAction<boolean>>;
	setIsEditing: Dispatch<SetStateAction<boolean>>;
}

export const NO_IMAGE_URL =
	'https://st4.depositphotos.com/14953852/22772/v/600/depositphotos_227725120-stock-illustration-image-available-icon-flat-vector.jpg';
