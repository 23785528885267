import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { IMenuProps, standardMenuId } from './types';
import { Menu, MenuItem } from '@mui/material';

export const StandardMenu: FC<IMenuProps> = ({
	anchorEl,
	handleClose,
	isOpen,
	logout,
}) => {
	return (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			id={standardMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={isOpen}
			onClose={handleClose}
		>
			<MenuItem
				onClick={handleClose}
				component={NavLink}
				sx={{ width: 150 }}
				to="/help"
			>
				Help
			</MenuItem>
			<MenuItem onClick={logout}>Log Out</MenuItem>
		</Menu>
	);
};
