import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './common/theme/theme';
import store from './api/redux/store';
import App from './App';
import { CookiesProvider } from 'react-cookie';

const rootElement = document.getElementById('root');
if (rootElement === null) throw 'Root element not found';

ReactDOM.createRoot(rootElement).render(
	<CookiesProvider>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<App />
			</ThemeProvider>
		</Provider>
	</CookiesProvider>,
);
