import { Grid } from '@mui/material';
import {
	ILogo,
	useLazyGetDarkLogoQuery,
	useLazyGetLightLogoQuery,
} from 'api/redux/services/organizations.service';
import { FC, useEffect, useState } from 'react';
import { ELogoTypes, ILogoPreviewProps, NO_IMAGE_URL } from './types';
import { styled } from '@mui/system';

const ImgBox = styled('img')(() => ({
	maxWidth: '150px',
	maxHeight: '75x',
}));

export const LogoPreview: FC<ILogoPreviewProps> = ({
	sponsorId,
	visible,
	logoType,
}) => {
	const [getLightLogo, lightLogo] = useLazyGetLightLogoQuery();
	const [getDarkLogo, darkLogo] = useLazyGetDarkLogoQuery();
	const [logo, setLogo] = useState<ILogo | undefined>(undefined);

	useEffect(() => {
		if (sponsorId === 0) return;

		if (logoType === ELogoTypes.LIGHT) {
			getLightLogo(sponsorId);
			return;
		}

		getDarkLogo(sponsorId);
	}, [sponsorId, getLightLogo, logoType, getDarkLogo, visible]);

	useEffect(() => {
		if (!lightLogo.data) return;

		setLogo(lightLogo.data);
	}, [lightLogo.data]);

	useEffect(() => {
		if (!darkLogo.data) return;

		setLogo(darkLogo.data);
	}, [darkLogo.data]);

	if (!visible) return <></>;

	return (
		<Grid container spacing={2} justifyContent="center">
			<Grid item>
				<ImgBox src={logo?.url || NO_IMAGE_URL} />
			</Grid>
		</Grid>
	);
};
