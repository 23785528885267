import { FC } from 'react';
import { Button, styled } from '@mui/material';
import { ROLES } from 'common/helpers/permissions/Roles';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { onShowAllUsers, selectDropdown } from 'api/redux/DropdownReducer';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';

const ButtonBox = styled(Button)({
	color: 'white',
	border: '1px solid white',
	margin: 5,
	width: '200px',
	fontSize: '12px',
	'&:hover': {
		border: '1px solid white',
		fontWeight: 'bold',
		backgroundColor: '#FFFF0030 !important',
	},
	textTransform: 'none',
});

export const AllUsersButton: FC = () => {
	const { currentRoles } = useGrants();
	const dispatch = useDispatch();
	const { showAllUsers } = useSelector(selectDropdown);

	return (
		<ButtonBox
			variant="outlined"
			size="small"
			sx={{
				'&.MuiButton-outlined': {
					backgroundColor: showAllUsers ? '#FFFF0030' : 'transparent',
					border: showAllUsers ? '2px solid white' : '1px solid white',
				},
			}}
			disabled={currentRoles.includes(ROLES.INVESTOR_ADMIN)}
			onClick={() => dispatch(onShowAllUsers(true))}
		>
			All Users
		</ButtonBox>
	);
};
