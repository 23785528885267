import { FC, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { Typography } from '@mui/material';
import Dropzone from 'react-dropzone';
import { useProcessBulkUsersInviteCsvMutation } from 'api/redux/services/userApi';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { ErrorMessage } from 'common/components/UsersTable/add-user-modal/ErrorMessage';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { BulkUploadUserTemplate } from './BulkUploadUserTemplate';
import { UserRows } from './UserRows';
import { IconBtn } from 'common/components/IconBtn';
import { PersonAddAlt1 } from '@mui/icons-material';
import { ROLES } from 'common/helpers/permissions/Roles';
import { ICSVUser } from './types';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';

const BulkUploadConatiner = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	width: 'min(100%, 1350px) !important',
	margin: '7px 2px 5px 2px',
}));

const UploadCardPaper = styled(Paper)(() => ({
	padding: '5px',
	borderRadius: 5,
	overflow: 'auto',
	border: '1px solid #D0D7DE',
	width: '100%',
	minHeight: '35vh',
}));

const InviteCard = styled(Paper)(() => ({
	marginTop: '5px',
}));

const HeaderBox = styled(Box)(() => ({
	height: '10%',
	display: 'flex',
	justifyContent: 'space-between',
}));

const HeaderTypography = styled(Typography)(() => ({
	paddingLeft: '5px',
	color: 'black',
}));

const DropZoneContainer = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	margin: '20px',
	height: '80%',
	border: '2px dashed black',
}));

const DropZoneTypograhy = styled(Typography)(() => ({
	marginTop: '25%',
}));

export const BulkUploadUsers: FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;
	const [csvUploadError, setCsvUploadError] = useState<string>('');
	const [processBulkUsersInviteCsv, csvResult] =
		useProcessBulkUsersInviteCsvMutation();
	const [users, setUsers] = useState<ICSVUser[]>([]);

	const onUpdateUser = (updatedVersion: ICSVUser) => {
		const newUsers = users.map((u) =>
			u.id === updatedVersion.id ? updatedVersion : u,
		);

		setUsers([...newUsers]);
	};

	const onDeleteUser = (userId: number) => {
		const newUsers = users.filter((u) => u.id !== userId);

		setUsers([...newUsers]);
	};

	const onAddUser = () => {
		const newUser = (): ICSVUser => {
			const userIds = users.map((u) => u.id);
			const maxId = Math.max(...userIds);

			return {
				id: maxId + 1,
				name: '',
				email: '',
				role: ROLES.INVESTOR_USER,
				errors: [],
			};
		};

		setUsers([...users, newUser()]);
	};

	const processFile = async (acceptedFile) => {
		const formData = new FormData();
		formData.append('file', acceptedFile[0]);
		await processBulkUsersInviteCsv({
			formData,
			sponsorId: currentSponsor.id,
		})
			.unwrap()
			.then((response) =>
				setUsers(
					response
						.filter(
							(csvUser) =>
								![
									ROLES.SUPER_ADMIN,
									ROLES.SPONSOR_ADMIN,
									ROLES.SPONSOR_USER,
								].includes(csvUser.role as ROLES),
						)
						.map((e, i) => {
							return { ...e, id: i };
						}),
				),
			);
	};

	const Header: FC = () => {
		return (
			<>
				{csvUploadError !== '' && (
					<ErrorMessage setError={setCsvUploadError} error={csvUploadError} />
				)}
				<UploadCardPaper elevation={0}>
					<HeaderBox>
						<HeaderTypography variant="h3">Bulk Upload Users</HeaderTypography>
						<BulkUploadUserTemplate />
					</HeaderBox>
					<DropZoneContainer>
						{csvResult.isLoading ? (
							<LoadingSpinner />
						) : (
							<Dropzone
								accept={{ 'text/csv': ['.csv'] }}
								maxFiles={1}
								onDropRejected={() =>
									setCsvUploadError(
										'There was an error uploading the CSV document',
									)
								}
								onDrop={processFile}
							>
								{({ getRootProps, getInputProps }) => (
									<div {...getRootProps()} className="dropzone">
										<input {...getInputProps()} />
										<DropZoneTypograhy
											variant="h5"
											style={{ marginTop: '25%' }}
										>
											Click here to browse files or drag-and-drop the populated
											CSV template here.
										</DropZoneTypograhy>
									</div>
								)}
							</Dropzone>
						)}
					</DropZoneContainer>
				</UploadCardPaper>
			</>
		);
	};

	const Body: FC = () => {
		return (
			<InviteCard elevation={0}>
				<UserRows
					sponsorId={currentSponsor.id}
					users={users}
					onDelete={onDeleteUser}
					onUpdate={onUpdateUser}
				/>
				<Box>
					<IconBtn
						tooltip={'Add a user at the bottom of the list'}
						onClick={() => onAddUser()}
						customColor={true}
					>
						<PersonAddAlt1 />
					</IconBtn>
				</Box>
			</InviteCard>
		);
	};

	return (
		<MainSidebarContainer>
			<BulkUploadConatiner>
				<Header />
				<Body />
			</BulkUploadConatiner>
		</MainSidebarContainer>
	);
};
